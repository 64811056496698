import { Component, OnInit } from '@angular/core';
import {DocumentUtilitiesService} from '../../../services/document-utilities.service'
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  termData:any
  constructor(private document: DocumentUtilitiesService) { }

  ngOnInit() {
    this.document.listTermaAboutUs({type:'Terms'}).subscribe(res => {
      console.log(res)
      this.termData = res.data.html
       })
  }

}
