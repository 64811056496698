import { Component, OnInit, Input } from '@angular/core';
import { CasesService } from '../../../services/cases.service'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { noWhitespaceValidator } from '../../../config/sharedServices/customeValidation'
import { environment } from '../../../../environments/environment'
import swal from 'sweetalert';
import { jsPDF } from "jspdf";
import moment from 'moment';

declare var $: any;
@Component({
  selector: "app-collection-table",
  templateUrl: "./collection-table.component.html",
  styleUrls: ["./collection-table.component.scss"],
})
export class CollectionTableComponent implements OnInit {
  @Input() id: any;
  VALUEFORDOWNLOAD: number;
  Voucherdetail: number;
  @Input() userRole: any;
  baseUrl = environment.baseUrl;
  addCollectionFeeForm: any;
  editCollectionFeeForm: any;
  firmData: any;
  collectionFeeData = [];
  fileToUpload: any = "";
  isSubmitted: boolean;
  clientid: any;
  caseFolderName: any;
  caseData: any;
  AllclientId: any = [];
  clientList: any = [];
  collectionRecieptData: any;
  isCheque: boolean;
  isEditCollection: boolean = true;
  printChequeDetail: boolean = false; 
  collectionId: any;
  totalCollectionFee: any;
  totalPendingCollection: any;
  isDeleteCollection: boolean = false;
  constructor(private _cases: CasesService, private fb: FormBuilder) {
    this.isSubmitted = false;
    this.isCheque = false;
  }

  ngOnInit() {
    if (this.userRole == "Tier-1" || this.userRole == "Tier-2") {
      this.isEditCollection = false;
    }
    if (this.userRole == "Admin" || this.userRole == "Sub-Admin") {
      this.isDeleteCollection = true;
    }
    this.viewCaseDetail();
    this.loadCollectionTable();
    this.CollectionForm();
    this.editCollectionForm();
  }
  onChangePayment(value) {
    console.log(value);
    if (value == "Cheque") {
      this.isCheque = true;
    } else {
      this.isCheque = false;
    }
    this.setValidators();
  }

  getCollectionRecieptData(value) {
    console.log(value);
    for (let i = 0; i < this.collectionFeeData.length; i++) {
      if (value == i) {
        this.collectionRecieptData = this.collectionFeeData[i];
      }
    }
    if (this.collectionRecieptData.paymentMode == "Cheque") {
      this.printChequeDetail = true;
    } else {
      this.printChequeDetail = false;
    }
    console.log(this.collectionRecieptData);
    this.VALUEFORDOWNLOAD = value;
    this.Voucherdetail = value;
  }

  DownloadPDF(value) {
    console.log("PDF");
    const doc = new jsPDF();
    let data = this.collectionFeeData[value];
    let recivedate = data.receiveDate;
    let newDate = moment.utc(recivedate).format("DD/MM/YY");
    console.log("converted date", newDate);
    let recivefrom = data.receivedBy.companyName;
    let reciveamount = "" + data.receivedAmount;
    let pendingamount = "" + data.pendingCollectionFees;
    let paymethod = data.paymentMode;
    let reciveby = this.firmData.firmName;
    let reason = data.description;
    let chequedate = data.chequeDate;

    let chequeno = "" + data.chequeNumber;
    let drawingacnumber = "" + data.accountNumber;
    let bankname = data.bankName;

    doc.text("Collection Fee Details", 80, 10);

    doc.text("Firm Name ::", 10, 20);
    doc.text(reciveby, 50, 20);

    doc.text("Date ::", 10, 40);
    doc.text(newDate, 30, 40);

    doc.text("Received From ::", 10, 60);
    doc.text(recivefrom, 60, 60);

    doc.text("Amount ::", 10, 80);
    doc.text(reciveamount, 40, 80);

    doc.text("Balance ( Pending ) ::", 10, 100);
    doc.text(pendingamount, 70, 100);

    doc.text("Payment Method ::", 10, 120);
    doc.text(paymethod, 60, 120);

    doc.text("Reason ::", 10, 140);
    doc.text(reason, 40, 140);

    doc.text("Signature ::", 10, 160);

    // doc.line(80, 80, 60, 25);

    if (data.chequeDate) {
      doc.text("Cheque Date ::", 10, 180);
      doc.text(chequedate, 50, 180);
    }

    if (data.chequeNumber) {
      doc.text("Cheque No. ::", 10, 200);
      doc.text(chequeno ? chequeno : "", 50, 200);
    }

    if (data.accountNumber != "" && data.accountNumber != undefined) {
      doc.text("Drawing A/c No. ::", 10, 220);
      doc.text(drawingacnumber ? drawingacnumber : "", 60, 220);
    }

    if (data.bankName) {
      doc.text("Bank Name ::", 10, 240);
      doc.text(bankname ? bankname : "", 65, 240);
    }

    doc.save("collection.pdf");
  }

  generateCollectionReciept() {
    this.DownloadPDF(this.VALUEFORDOWNLOAD);
    // let obj = {
    //   date: this.collectionRecieptData.receiveDate,
    //   from: this.collectionRecieptData.receivedBy.companyName
    //     ? this.collectionRecieptData.receivedBy.companyName
    //     : this.collectionRecieptData.receivedBy.personName,
    //   paid: this.collectionRecieptData.receivedAmount,
    //   pendingamount: this.collectionRecieptData.pendingCollectionFees,
    //   paymentmethod: this.collectionRecieptData.paymentMode,
    //   type: "Collection",
    //   reason: this.collectionRecieptData.description,
    //   chequeDate: this.collectionRecieptData.chequeDate,
    //   chequeNo: this.collectionRecieptData.chequeNumber,
    //   drawingAccount: this.collectionRecieptData.accountNumber,
    //   drawnBankName: this.collectionRecieptData.bankName,
    // };
    // console.log(obj);
    // this._cases.generateReceipt(obj).subscribe((res) => {
    //   console.log(this.baseUrl);
    //   setTimeout(() => window.open(this.baseUrl + res.data), 1000);
    // });
  }

  generateVOUCHERPDF(value) {
    console.log("PDF");
    const doc = new jsPDF();
       let data = this.collectionFeeData[value];
       let recivedate = data.receiveDate;
       let newDate = moment.utc(recivedate).format("DD/MM/YY");
       console.log("converted date", newDate);
       let recivefrom = data.receivedBy.companyName;
       let reciveamount = "" + data.receivedAmount;
       let pendingamount = "" + data.pendingCollectionFees;
       let paymethod = data.paymentMode;
       let reciveby = this.firmData.firmName;
       let reason = data.description;

           let chequedate = data.chequeDate;

           let chequeno = "" + data.chequeNumber;
           let drawingacnumber = "" + data.accountNumber;
           let bankname = data.bankName;


        doc.text("Voucher Details", 80, 10);

        doc.text("Firm Name ::", 10, 20);
        doc.text(reciveby, 50, 20);

        doc.text("Date ::", 10, 40);
        doc.text(newDate, 30, 40);

        doc.text("Received From ::", 10, 60);
        doc.text(recivefrom, 60, 60);

        doc.text("Amount ::", 10, 80);
        doc.text(reciveamount, 40, 80);

        doc.text("Balance ( Pending ) ::", 10, 100);
        doc.text(pendingamount, 70, 100);

        doc.text("Payment Method ::", 10, 120);
        doc.text(paymethod, 60, 120);

        doc.text("Reason ::", 10, 140);
        doc.text(reason, 40, 140);

        doc.text("Signature ::", 10, 160);


    if (data.chequeDate) {
      doc.text("Cheque Date ::", 10, 180);
      doc.text(chequedate, 50, 180);
    }

    if (data.chequeNumber) {
      doc.text("Cheque No. ::", 10, 200);
      doc.text(chequeno ? chequeno : "", 50, 200);
    }

    if (data.accountNumber) {
      doc.text("Drawing A/c No. ::", 10, 220);
      doc.text(drawingacnumber ? drawingacnumber : "", 60, 220);
    }

    if (data.bankName) {
      doc.text("Bank Name ::", 10, 240);
      doc.text(bankname ? bankname : "", 65, 240);
    }

    doc.save("Voucher.pdf");
  }
  generateCollectionVoucher() {
    this.generateVOUCHERPDF(this.Voucherdetail);

    // let obj = {
    //   date: this.collectionRecieptData.receiveDate,
    //   from: this.collectionRecieptData.receivedBy.companyName
    //     ? this.collectionRecieptData.receivedBy.companyName
    //     : this.collectionRecieptData.receivedBy.personName,
    //   paid: this.collectionRecieptData.receivedAmount,
    //   pendingamount: this.collectionRecieptData.pendingCollectionFees,
    //   paymentmethod: this.collectionRecieptData.paymentMode,
    //   type: "Collection",
    //   reason: this.collectionRecieptData.description,
    //   chequeDate: this.collectionRecieptData.chequeDate,
    //   chequeNo: this.collectionRecieptData.chequeNumber,
    //   drawingAccount: this.collectionRecieptData.accountNumber,
    //   drawnBankName: this.collectionRecieptData.bankName,
    // };
    // this._cases.generatePaymentVoucher(obj).subscribe((res) => {
    //   console.log(res);
    //   window.open(this.baseUrl + res.data);
    // });
  }
  viewCaseDetail() {
    this._cases.viewCaseDetail({ caseId: this.id }).subscribe((res) => {
      this.caseData = res.data;
      this.AllclientId = res.data.clientId;
      // for(let i=0; i< res.data.clientName.length; i++){
      //   this.clientList.push(res.data.clientName[i].name)
      // }
      this.clientList = res.data.clientName;

      // this.AllCounterId = res.data.counterPartyId
      // this.counterPartyList = res.data.counterPartyName
      this.caseFolderName = res.data2.caseFolder;
    });
  }
  loadCollectionTable() {
    this._cases.listCollectionFee({ caseId: this.id }).subscribe((res) => {
      console.log(res, "collection data");
      if (res.data) {
        this.firmData = res.data.firm;
        this.collectionFeeData = res.data.collectionFee;
        this.totalCollectionFee = res.data.totalCollectionFee;
        let totalPaid = this.collectionFeeData.reduce(function (cnt, o) {
          return cnt + o.receivedAmount;
        }, 0);
        this.totalPendingCollection = this.totalCollectionFee - totalPaid;
      }
    });
  }

  delete(id) {
    console.log(id);
    this.collectionId = id;
  }
  deleteCollection() {
    this._cases
      .deleteCollectionFee({ caseId: this.id, id: this.collectionId })
      .subscribe(
        (res) => {
          console.log(res);
          this.loadCollectionTable();
          swal(res.data, "", "success");
        },
        (error) => {
          swal("Error!", error.error.message, "error");
          console.log(error.error.message);
        }
      );
  }
  onChangeClint(value) {
    for (let i = 0; i < this.AllclientId.length; i++) {
      if (value == i) {
        console.log(this.AllclientId[i]);
        this.clientid = this.AllclientId[i];
      }
    }
  }
  setValidators() {
    const institutionControl = this.addCollectionFeeForm.get("paymentMode");
    if (institutionControl.value === "Cheque") {
      this.addCollectionFeeForm
        .get("chequeNumber")
        .setValidators([Validators.required]);
      this.addCollectionFeeForm
        .get("chequeDate")
        .setValidators([Validators.required]);
      this.addCollectionFeeForm
        .get("bankName")
        .setValidators([Validators.required]);
      this.addCollectionFeeForm
        .get("accountNumber")
        .setValidators([Validators.required]);
    } else {
      this.addCollectionFeeForm.get("chequeNumber").setValidators("");
      this.addCollectionFeeForm.get("chequeDate").setValidators("");
      this.addCollectionFeeForm.get("bankName").setValidators("");
      this.addCollectionFeeForm.get("accountNumber").setValidators("");
    }
    this.addCollectionFeeForm.get("chequeNumber").updateValueAndValidity();
    this.addCollectionFeeForm.get("chequeDate").updateValueAndValidity();
    this.addCollectionFeeForm.get("bankName").updateValueAndValidity();
    this.addCollectionFeeForm.get("accountNumber").updateValueAndValidity();
  }
  getCollectionForEdit(value, id) {
    this.collectionId = id;
    for (let i = 0; i < this.collectionFeeData.length; i++) {
      if (value == i) {
        this.collectionRecieptData = this.collectionFeeData[i];
      }
    }
    let clientName = this.collectionRecieptData.receivedBy.companyName
      ? this.collectionRecieptData.receivedBy.companyName
      : this.collectionRecieptData.receivedBy.personName;

    let index = this.clientList.findIndex((x) => x.name === clientName);
    this.clientid = this.collectionRecieptData.receivedBy._id;
    if (this.collectionRecieptData.paymentMode == "Cheque") {
      this.isCheque = true;
      this.editCollectionFeeForm.patchValue({
        receiveDate: this.formatDate(this.collectionRecieptData.receiveDate),
        receivedBy: index,
        paymentMode: this.collectionRecieptData.paymentMode,
        description: this.collectionRecieptData.description,
        chequeNumber: this.collectionRecieptData.chequeNumber,
        chequeDate: this.formatDate(this.collectionRecieptData.chequeDate),
        bankName: this.collectionRecieptData.bankName,
        accountNumber: this.collectionRecieptData.accountNumber,
      });
    } else {
      this.isCheque = false;
      this.editCollectionFeeForm.patchValue({
        receiveDate: this.formatDate(this.collectionRecieptData.receiveDate),
        receivedBy: index,
        paymentMode: this.collectionRecieptData.paymentMode,
        description: this.collectionRecieptData.description,
      });
    }
  }
  editCollectionForm() {
    this.editCollectionFeeForm = this.fb.group({
      receiveDate: ["", [Validators.required]],
      receivedBy: ["", [Validators.required]],
      paymentMode: ["", [Validators.required]],
      // files:[''],
      description: ["", noWhitespaceValidator],
      chequeNumber: [""],
      chequeDate: [""],
      bankName: [""],
      accountNumber: [""],
    });
  }
  saveEditCollectionFee() {
    this.isSubmitted = true;
    let obj = {};
    if (this.editCollectionFeeForm.value.paymentMode == "Cheque") {
      obj = {
        receiveDate: this.formatDate(
          this.editCollectionFeeForm.value.receiveDate
        ),
        receivedBy: this.clientid,
        paymentMode: this.editCollectionFeeForm.value.paymentMode,
        description: this.editCollectionFeeForm.value.description,
        chequeNumber: this.editCollectionFeeForm.value.chequeNumber,
        chequeDate: this.formatDate(
          this.editCollectionFeeForm.value.chequeDate
        ),
        bankName: this.editCollectionFeeForm.value.bankName,
        accountNumber: this.editCollectionFeeForm.value.accountNumber,
        id: this.collectionId,
      };
    } else {
      obj = {
        receiveDate: this.formatDate(
          this.editCollectionFeeForm.value.receiveDate
        ),
        receivedBy: this.clientid,
        paymentMode: this.editCollectionFeeForm.value.paymentMode,
        description: this.editCollectionFeeForm.value.description,
        chequeNumber: "",
        chequeDate: "",
        bankName: "",
        accountNumber: "",
        id: this.collectionId,
      };
    }
    console.log(obj);
    if (this.editCollectionFeeForm.status == "VALID") {
      this._cases.editCollectionFee(obj).subscribe(
        (res) => {
          console.log(res);
          this.loadCollectionTable();
          this.cancelCollectionFee();
          $("#editCollectionModal").modal("hide");
          swal(res.data, "", "success");
        },
        (error) => {
          $("#editCollectionModal").modal("hide");
          swal("Error!", error.error.message, "error");
          console.log(error.error.message);
        }
      );
    }
  }
  CollectionForm() {
    this.addCollectionFeeForm = this.fb.group({
      receiveDate: ["", [Validators.required]],
      receivedAmount: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[0-9]+([.][0-9]+)?$/),
          Validators.min(0.01),
        ],
      ],
      paymentMode: ["", [Validators.required]],
      files: [""],
      receivedBy: ["", [Validators.required]],
      description: ["", noWhitespaceValidator],
      chequeNumber: [""],
      chequeDate: [""],
      bankName: [""],
      accountNumber: [""],
    });
  }
  UploadFileForCollection(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  addValue(a, b) {
    return a + b;
  }
  viewAttachment(value) {
    console.log("attachment viewed", value);
    console.log(this.collectionFeeData, 'collection fee data');
    
    let img = this.collectionFeeData[value].attachment;
    if (img != undefined) {
      window.open(environment.baseUrl + img);
    } else {
      swal("Error", "No attahment avaliable", "error");
    }
  }

  addCollectionFee() {
    this.isSubmitted = true;
    console.log(this.addCollectionFeeForm.value);
    console.log(this.clientid);
    console.log(this.id);
    console.log(this.caseFolderName);
    const formdata = new FormData();
    formdata.append(
      "receiveDate",
      this.formatDate(this.addCollectionFeeForm.value.receiveDate)
    );
    formdata.append(
      "receivedAmount",
      this.addCollectionFeeForm.value.receivedAmount
    );
    formdata.append("paymentMode", this.addCollectionFeeForm.value.paymentMode);
    formdata.append("files", this.fileToUpload);
    formdata.append("description", this.addCollectionFeeForm.value.description);
    formdata.append("receivedBy", this.clientid);
    formdata.append("caseId", this.id);
    formdata.append(
      "chequeNumber",
      this.addCollectionFeeForm.value.chequeNumber
    );
    formdata.append(
      "chequeDate",
      this.formatDate(this.addCollectionFeeForm.value.chequeDate)
    );
    formdata.append("bankName", this.addCollectionFeeForm.value.bankName);
    formdata.append(
      "accountNumber",
      this.addCollectionFeeForm.value.accountNumber
    );
    console.log(formdata);
    if (this.addCollectionFeeForm.status == "VALID") {
      console.log("Hit Api");
      this._cases
        .updateCollectionFee(
          formdata,
          this.caseFolderName,
          "totalJudgementAmountDocs",
          this.id
        )
        .subscribe(
          (res) => {
            console.log(res);
            this.loadCollectionTable();
            this.cancelCollectionFee();
            $("#collectionModal").modal("hide");
          },
          (error) => {
            swal("Error!", error.error.message, "error");
            console.log(error.error.message);
          }
        );
    }
  }

  private formatDate(date) {
    if (date) {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    } else {
    }
  }
  cancelCollectionFee() {
    this.addCollectionFeeForm.reset();

    this.fileToUpload = "";
    this.isSubmitted = false;
    this.isCheque = false;
  }
}
