import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../config/sharedServices/shared.service';
import {CasesService} from '../../../services/cases.service'
import {AuthService} from '../../../services/auth.service'
import {ContactsService} from '../../../services/contacts.service'
declare var $: any;

@Component({
  selector: 'app-inner-sidebar',
  templateUrl: './inner-sidebar.component.html',
  styleUrls: ['./inner-sidebar.component.scss']
})
export class InnerSidebarComponent implements OnInit {
  @Input() item:string
  FOLDERNAME:any
  CONTACTFOLDERNAME:any
  isClickable:boolean = true
 ttt = ":bhjkj"
  constructor(private _shared: SharedService,private _cases: CasesService,private authService: AuthService,
    private _contact: ContactsService) {
   
     }

  ngOnInit() {
  
    this.authService.getUser().subscribe(res => {
          if(res){
            if(res.role.name == 'Tier-1'){
              this.isClickable = false
            }
           }
        })
     this.authService.setUser();
 }

  dashboard() {
    this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
   
    if(this.FOLDERNAME){
      this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
         console.log(res)
        this._shared.deleteDataFromStorage('DirName')
           window.location.href = "/lawyerIt/dashboard"
           
       },error => {
         console.log(error)
       })
    }
    this.CONTACTFOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
    if(this.CONTACTFOLDERNAME){
      this._contact.removeFolderFromDocs({folderName:this.CONTACTFOLDERNAME}).subscribe(res => {
         console.log(res)
        this._shared.deleteDataFromStorage('ContactDirName')
           window.location.href = "/lawyerIt/dashboard"
           
       },error => {
         console.log(error)
       })
    }
    else{
      window.location.href = "/lawyerIt/dashboard"
    }
  }
  cases() {
  
    this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
    if(this.FOLDERNAME){
      console.log('A')
       this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
        this._shared.deleteDataFromStorage('DirName')
           window.location.href = "/lawyerIt/cases"
          
       })
    }
    this.CONTACTFOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
    if(this.CONTACTFOLDERNAME){
      console.log('B')
      this._contact.removeFolderFromDocs({folderName:this.CONTACTFOLDERNAME}).subscribe(res => {
         console.log(res)
        this._shared.deleteDataFromStorage('ContactDirName')
           window.location.href = "/lawyerIt/cases"
           
       },error => {
         console.log(error)
       })
    }
    else{
      console.log('C')
      window.location.href = "/lawyerIt/cases"
    }
  }
  documents() {
    if(this.isClickable){
      this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
      if(this.FOLDERNAME){
        console.log('isFolder delete')
         this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
          this._shared.deleteDataFromStorage('DirName')
             window.location.href = "/lawyerIt/documents"
             
         })
      }
      this.CONTACTFOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
      if(this.CONTACTFOLDERNAME){
        this._contact.removeFolderFromDocs({folderName:this.CONTACTFOLDERNAME}).subscribe(res => {
           console.log(res)
          this._shared.deleteDataFromStorage('ContactDirName')
             window.location.href = "/lawyerIt/documents"
             
         },error => {
           console.log(error)
         })
      }
      else{
        window.location.href = "/lawyerIt/documents"
      }
    }

  }
  utilities() {
    this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
    if(this.FOLDERNAME){
       this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
        this._shared.deleteDataFromStorage('DirName')
           window.location.href = "/lawyerIt/utilities"
          
       })
    }
    this.CONTACTFOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
    if(this.CONTACTFOLDERNAME){
      this._contact.removeFolderFromDocs({folderName:this.CONTACTFOLDERNAME}).subscribe(res => {
         console.log(res)
        this._shared.deleteDataFromStorage('ContactDirName')
           window.location.href = "/lawyerIt/utilities"
           
       },error => {
         console.log(error)
       })
    }
    else{
      window.location.href = "/lawyerIt/utilities"
    }
  }
  contacts() {
    this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
    if(this.FOLDERNAME){
       this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
        this._shared.deleteDataFromStorage('DirName')
           window.location.href = "/lawyerIt/contacts"
          
       })
    }
    this.CONTACTFOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
    if(this.CONTACTFOLDERNAME){
      this._contact.removeFolderFromDocs({folderName:this.CONTACTFOLDERNAME}).subscribe(res => {
         console.log(res)
        this._shared.deleteDataFromStorage('ContactDirName')
           window.location.href = "/lawyerIt/contacts"
           
       },error => {
         console.log(error)
       })
    }
    else{
      window.location.href = "/lawyerIt/contacts"
    }
  }
  helpcenter() {
    this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
    if(this.FOLDERNAME){
       this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
        this._shared.deleteDataFromStorage('DirName')
           window.location.href = "/lawyerIt/help_center"
        
       })
    }
    this.CONTACTFOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
    if(this.CONTACTFOLDERNAME){
      this._contact.removeFolderFromDocs({folderName:this.CONTACTFOLDERNAME}).subscribe(res => {
         console.log(res)
        this._shared.deleteDataFromStorage('ContactDirName')
           window.location.href = "/lawyerIt/help_center"
           
       },error => {
         console.log(error)
       })
    }
    else{
      window.location.href = "/lawyerIt/help_center"
    }
  }

  ngAfterViewInit(){
      var test = localStorage.getItem("isToggled")
      if(test){
            if(test == 'true'){
              $("body").toggleClass("sidebar-toggled")
              $(".sidebar").toggleClass("toggled") 
            }
      }
   
      $(document).ready(function ($) {
        $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
         
          $("body").toggleClass("sidebar-toggled")
            $(".sidebar").toggleClass("toggled") 
            $(".sidebar").hasClass("toggled")&& $(".sidebar .collapse").collapse("hide");
            if($(".sidebar").hasClass("toggled")){
              localStorage.setItem("isToggled","true")
            }
            else{
              localStorage.setItem("isToggled","false")
            }
           
        });
    
    })

  }
}