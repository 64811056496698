import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ApiService } from '../config/sharedServices/api.service';
import { IapiBaseObject } from '../config/iModels/IApiBaseObject';
import { API_KEYS } from '../config/apiKeys/api-keys-constants';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _api: ApiService) { }

  downloadEventPdf(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DOWNLOADEVENTPDF,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  caseTypeStatistics() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CASETYPESTATICS,

    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterCaseStatistics(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERCASESTATICS,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  caseStatistics() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CASESTATICS,

    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterAttorneyStatics(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERATTORNEYSTATICS,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterAttorneyStaticsPayment(data, mode) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERATTORNEYSTATICSPayment.replace(":paymentMode", mode),
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  listAttorneyStatics() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ATTORNEYFEESTATICS,
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterStatistics(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERCOLLECTIONSTATICS,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterStatisticsPayment(data, mode) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERCOLLECTIONSTATICSPayment.replace(":paymentMode", mode),
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  listStatistics() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.COLLECTIONFEESTATICS,
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getHighestAmount(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.HIGHESTAMOUNT.replace(":size", pageSize).replace(":pageNo", pageIndex),
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterEvent(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTEREVENT,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  listEvent(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EVENTLIST,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getNotficationCount() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.unReadNotification,
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getNotificationList() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.notificationList
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  notificationStatus(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.notificationStatusChange,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getEventDetail(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EVENTDETAIL,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  deleteEvent(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETEEVENT,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  editEvent(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITEVENT,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addEvent(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDEVENT,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
