import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service'
import { AuthService } from '../../../services/auth.service'
import { SharedService } from '../../../config/sharedServices/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { replaceEmoji, removeSpaces, noWhitespaceValidator } from '../../../config/sharedServices/customeValidation';
declare var $: any;
import * as io from 'socket.io-client';
@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  userRegisterForm: any;
  loginForm: any
  forgotForm: any
  isSubmitted: boolean;
  isSuccess: boolean = false
  isShowLoginError: boolean;
  isShowForgotError: boolean;
  errMsg: any
  isEmailSend: boolean
  isResend: boolean
  middleScreen: string = 'home'
  socket;
  fieldTextType: boolean;
  constructor(private fb: FormBuilder, private userService: UserService, private authService: AuthService,
    private _shared: SharedService, private router: Router,) {
    this.isSubmitted = false
    this.isShowLoginError = false
    this.isShowForgotError = false
    this.errMsg = ''
    this.isEmailSend = false
    this.isResend = false
  }


  ngOnInit() {
    this.registerForm()
    this.loginFormBuilder()
    this.autoFilled()
    this.forgotFromBuilder()
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  getPage(value) {
    if (value == 'home') {
      this.middleScreen = 'home'
    }
    else if (value == 'features') {
      this.middleScreen = 'features'
    }
    else if (value == 'about') {
      this.middleScreen = 'about'
    }
    else if (value == 'Terms') {
      this.middleScreen = 'Terms'
    }
  }
  test() {
    this.isSubmitted = false
    this.isShowLoginError = false
    this.isShowForgotError = false
    this.autoFilled()
  }
  registerForm(): void {
    this.isSubmitted = false;
    this.userRegisterForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      firmName: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(/^[0-9]*$/)]],
      firmSize: ['', [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]*$/)]],
    });
  }
  submitRegisterForm() {
    this.isSubmitted = true;
    console.log(this.userRegisterForm)
    console.log(this.userRegisterForm.status)
    if (this.userRegisterForm.status === 'VALID') {
      this.userService.registerUser(this.userRegisterForm.value).subscribe(res => {

        this.isSubmitted = false
        this.isSuccess = true
        this.userRegisterForm.reset()
      }, error => {
        this.isSubmitted = false;
        console.log(error.error.message)
      })
    }
  }

  loginFormBuilder(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, replaceEmoji, removeSpaces, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, replaceEmoji, removeSpaces]],
      rememberMe: [''],
      ip: ['']
    });
  }

  submitLoginForm() {

    this.isSubmitted = true;
    if (this.loginForm.status === 'VALID') {
      this.authService.getIP().subscribe(res => {
        this.loginForm.value.ip = res
        this.authService.login(this.loginForm.value).subscribe(res => {
          console.log(res)

          if (this.loginForm.value.rememberMe) {
            this._shared.setDataInStorage('rememberMe', 'yes');
            this._shared.setDataInStorage('Password', this.loginForm.value.password);
            this._shared.setDataInStorage('Email', this.loginForm.value.email);
          }
          else {
            this._shared.deleteDataFromStorage('rememberMe')
            this._shared.deleteDataFromStorage('Password')
            this._shared.deleteDataFromStorage('Email')
          }
          this.isSubmitted = false;
          this.router.navigate(["/lawyerIt/dashboard"]);
          // window.location.href = "/lawyerIt/dashboard"
        }, error => {
          this.isSubmitted = false;
          this.isShowLoginError = true
          this.errMsg = error.error.message
          //   setTimeout(() => {
          //    this.isShowLoginError = false;
          //  }, 5000);
        });
      })

    }
  }
  autoFilled() {
    let usercredential = this._shared.getDataFromStorage('rememberMe');
    let userEmail = this._shared.getDataFromStorage('Email');
    let userPassword = this._shared.getDataFromStorage('Password');
    if (usercredential == 'yes') {
      this.loginForm.patchValue({
        email: userEmail,
        password: userPassword,
        rememberMe: usercredential
      })
    }
  }
  forgotFromBuilder(): void {
    this.forgotForm = this.fb.group({
      email: ['', Validators.required],
    });
  }
  submitForgotForm() {
    if (!this.isSubmitted) {
      this.isSubmitted = true;
      if (this.forgotForm.status === 'VALID') {
        this.userService.forgotPassword(this.forgotForm.value).subscribe(res => {
          console.log(res)
          this.isSubmitted = false;
          this.isEmailSend = true
          // this.forgotForm.reset()
          setTimeout(() => {
            this.isResend = true
            this.isEmailSend = false;
          }, 1000);
        }, error => {
          this.isSubmitted = false;
          this.isShowForgotError = true
          this.errMsg = error.error.message
          //   setTimeout(() => {
          //    this.isShowForgotError = false;
          //  }, 3000);
        });
      }
    }

  }
  ngAfterViewInit() {

    $(document).ready(function ($) {
      console.log("kolkkp;");
      var $form_modal = $(".cd-user-modal"),
        $form_login = $form_modal.find("#cd-login"),
        $form_signup = $form_modal.find("#cd-signup"),
        $form_forgot_password = $form_modal.find("#cd-reset-password"),
        $form_modal_tab = $(".cd-switcher"),
        $tab_login = $form_modal_tab.children("li").eq(0).children("a"),
        $tab_signup = $form_modal_tab.children("li").eq(1).children("a"),
        $forgot_password_link = $form_login.find(".cd-form-bottom-message a"),
        $back_to_login_link = $form_forgot_password.find(
          ".cd-form-bottom-message a"
        ),
        $main_nav = $(".user_btns");

      //open modal
      $main_nav.on("click", function (event) {
        if ($(event.target).is($main_nav)) {
          // on mobile open the submenu
          $(this).children("ul").toggleClass("is-visible");
        } else {
          // on mobile close submenu
          $main_nav.children("ul").removeClass("is-visible");
          //show modal layer
          $form_modal.addClass("is-visible");
          //show the selected form
          $(event.target).is(".cd-signup") ? signup_selected() : login_selected();
          $("body").addClass("body_overflow");
          console.log("addedworking");
        }
      });

      //close modal
      $(".cd-user-modal").on("click", function (event) {
        if (
          $(event.target).is($form_modal) ||
          $(event.target).is(".cd-close-form") ||
          $(event.target).is(".cd-success-form")
        ) {
          $form_modal.removeClass("is-visible");
          $("body").removeClass("body_overflow");
          console.log("removworking");
        }
      });

      //switch from a tab to another
      $form_modal_tab.on("click", function (event) {
        event.preventDefault();
        $(event.target).is($tab_login) ? login_selected() : signup_selected();
      });

      //show forgot-password form
      $forgot_password_link.on("click", function (event) {
        event.preventDefault();
        forgot_password_selected();
      });

      $back_to_login_link.on("click", function (event) {
        event.preventDefault();
        login_selected();
      });

      function login_selected() {
        $form_login.addClass("is-selected");
        $form_signup.removeClass("is-selected");
        $form_forgot_password.removeClass("is-selected");
        $tab_login.addClass("selected");
        $tab_signup.removeClass("selected");
        $(".cd-switcher").removeClass("d-none");
        // $(".cd-switcher li").addClass("asd");
        $(".cd-switcher li").eq(1).removeClass("d-none");
        $(".cd-switcher li").eq(0).addClass("d-none");
      }

      function signup_selected() {
        $form_login.removeClass("is-selected");
        $form_signup.addClass("is-selected");
        $form_forgot_password.removeClass("is-selected");
        $tab_login.removeClass("selected");
        $tab_signup.addClass("selected");
        $(".cd-switcher").removeClass("d-none");
        // $(".cd-switcher li").removeClass("d-none");
        $(".cd-switcher li").eq(1).addClass("d-none");
        $(".cd-switcher li").eq(0).removeClass("d-none");
      }

      function forgot_password_selected() {
        $form_login.removeClass("is-selected");
        $form_signup.removeClass("is-selected");
        $form_forgot_password.addClass("is-selected");
        $(".cd-switcher").addClass("d-none");
        $(".cd-switcher li").removeClass("d-none");
      }
    })
  }
}
