import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CasesService} from '../../../services/cases.service'

@Component({
  selector: 'app-view-case',
  templateUrl: './view-case.component.html',
  styleUrls: ['./view-case.component.scss']
})
export class ViewCaseComponent implements OnInit {
  tab:any= 'procedure'
  params:any
  caseData:any
  caseFolderName:any;
  role:any
  userDetail:any
   constructor(private navParam:ActivatedRoute,private _cases: CasesService,) { 
    this.params = this.navParam.snapshot.params.caseId;
   
   }

  ngOnInit() {
    this.viewCaseDetail()
    this.loadProcedures()
    }
    ngAfterViewInit() {
      setTimeout(() => {
        // this.viewCalendar()
      },500)
    }
   loginUserData(data){
      this.role =  data.role.name
      this.userDetail = data
      }
   loadProcedures(){
      this.tab = 'procedure'
   }
   showPublicNotes(){
    this.tab = 'notes' 
   }
   showDocuments(){
     if(this.role == 'Tier-1'){

     }else{
         this.tab = 'document'
     }
     
   }
   viewCalendar(){
      this.tab = 'calendar' 
   }
   viewCollectionTable(){
    this.tab = 'collection' 
   }
   viewAttorneyTable(){
    this.tab = 'attorney'
   }
   viewRelatedCases(){
    this.tab = 'relatedCase' 
   }
   viewCaseDetail(){
    this._cases.viewCaseDetail({ caseId: this.params }).subscribe((res) => {
      console.log(res);
      this.caseData = res.data;
      this.caseFolderName = res.data2.caseFolder;
      console.log("CASEDATAYE HAI ", res.data);
    });
  }


}
