import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {PasswordValidation} from '../../../config/sharedServices/customeValidation';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service'
import swal from 'sweetalert';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm:any;
  isSubmitted:boolean
  params:any
  isSuccess:any
  constructor(private fb: FormBuilder,private route: ActivatedRoute,private userService:UserService,
    private router: Router) {
      this.isSuccess = false
    this.isSubmitted = false
    this.params = this.route.snapshot.params.id;

   }

  ngOnInit() {
    this.resetFormBuilder()
  }
resetFormBuilder(): void {
    this.resetForm = this.fb.group({
    password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)]],
    confpassword: ['']
  },{validators: PasswordValidation.MatchPassword});
}

submitForm(){
  this.isSubmitted =true;
  console.log(this.resetForm.value)
  let obj = {
    token:this.params,
    password:this.resetForm.value.password
  }
  if(this.resetForm.status === 'VALID') {
   this.userService.resetPassword(obj).subscribe(res => {
       this.isSuccess = true
       this.isSubmitted = false;
       setTimeout(() => {  window.location.href = "/"},2000)
     
      //  this.router.navigate(['']);
   }, error => {
    swal("Error!", error.error.message, "error");
   });
 }
 }
}
