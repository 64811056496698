import { Component, Input, OnInit } from '@angular/core';
import {CasesService} from '../../../services/cases.service'
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import {noWhitespaceValidator} from '../../../config/sharedServices/customeValidation'
import {DocumentUtilitiesService} from '../../../services/document-utilities.service'
import {AuthService} from '../../../services/auth.service'
import swal from 'sweetalert';
import moment from 'moment'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: "app-procedure",
  templateUrl: "./procedure.component.html",
  styleUrls: ["./procedure.component.scss"],
})
export class ProcedureComponent implements OnInit {
  @Input() id: any;
  pName: any;
  addProcedureForm;
  procedureListData: any = [];
  length: number;
  pageSize: number;
  pageIndex: number;
  fileToUpload: any = "";
  isSubmitted: boolean;
  startMoment;
  endMoment;
  weeksInBetween = 0;
  daysInBetween = 0;
  weekDays = 0;
  caseFolderName: any;
  procedureName: any;
  procedureId: any;
  currentTimeZone: any;
  isAddProcedure: boolean = false;

  constructor(
    private Route: Router,
    private _cases: CasesService,
    private fb: FormBuilder,
    private authService: AuthService,
    private document: DocumentUtilitiesService
  ) {
    this.pageSize = 5;
    this.pageIndex = 1;
    this.isSubmitted = false;
    this.getUserDetail();
  }

  ngOnInit() {
    this.procedureForm();
    this.viewCaseDetail();
    this.loadProcedures();
  }
  getUserDetail() {
    this.authService.getUser().subscribe((res) => {
      console.log(res);
      if (res) {
        this.currentTimeZone = res.timeZone;
        console.log(this.currentTimeZone);
      }
    });
  }
  viewCaseDetail() {
    this._cases.viewCaseDetail({ caseId: this.id }).subscribe((res) => {
      this.caseFolderName = res.data2.caseFolder;
    });
  }
  loadProcedures() {
    this._cases.listProcedure({ caseId: this.id }).subscribe((res) => {
      this.procedureListData = res.data.findListData;
      
      console.log("pname hai ye", this.pName);
      console.log(this.procedureListData);
      this.length = res.data.coundData.coundData;
    });
  }
  handlePageChange(event) {
    this.pageIndex = event;
    this.loadProcedures();
  }
  procedureForm() {
    this.addProcedureForm = this.fb.group({
      procedureName: ["", [Validators.required, noWhitespaceValidator]],
      description: ["", []],
      addDateTime: ["", [Validators.required]],
      dueDate: ["", [Validators.required]],
      deadline: [""],
      // numDays: ['',[Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.min(1)]],
      isWeekendCount: [false],
      // File:['']
    });
  }
  getProcedureNameId(name, id) {
    console.log(name, id);
    this.procedureName = name;
    this.procedureId = id;
    document.getElementById("fileInput").click();
  }
  UploadFileForProcedure(files: FileList) {
    console.log(files.item(0));
    this.fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append("id", this.procedureId);
    formData.append("files", this.fileToUpload);
    console.log("image", this.fileToUpload);
    this._cases
      .updateProcedure(
        formData,
        this.caseFolderName,
        "procedures",
        this.procedureName
      )
      .subscribe(
        (res) => {
          console.log(res);
          this.loadProcedures();
          swal("File Uploaded in Procedure Folder Successfully", "", "success");
        },
        (error) => {
          console.log(this.procedureName, 'procedure name');
          console.log(this.caseFolderName, 'case folder name');
          
          swal("Error!", error.error.message, "error");
          //  alert(error.error.message)
        }
      );
  }
  convertTZ(date, timezone = "") {
    if (timezone == this.currentTimeZone) {
      return date;
    } else {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: this.currentTimeZone }
        )
      );
    }
  }

  downloadProcedure(procedureName) {
    console.log("procedure download");
    this._cases
      .downloadProcedure({
        caseFolder: this.caseFolderName,
        procedureName: procedureName,
      })
      .subscribe((res) => {
        console.log(res);
        console.log("hahaa", res.data);

        window.open(environment.baseUrl + "/" + res.data);
      });
  }
  formatDate(date) {
    // date = this.convertTZ(date,this.currentTimeZone)
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  getTime(str) {
    // str = this.convertTZ(str,this.currentTimeZone)
    var date = new Date(str);
    var hh = date.getHours();
    var mm = date.getMinutes();
    var HH = hh < 10 ? "0" + hh : hh;
    var MM = mm < 10 ? "0" + mm : mm;

    var curr_time = HH + ":" + MM;
    return curr_time;
  }
  addProcedure() {
    this.isSubmitted = true;

    let STARTDATE = this.formatDate(this.addProcedureForm.value.addDateTime);
    let STARTTIME = this.getTime(this.addProcedureForm.value.addDateTime);
    let ENDDATE = this.formatDate(this.addProcedureForm.value.dueDate);
    let ENDTIME = this.getTime(this.addProcedureForm.value.dueDate);

    let startDate = new Date(this.addProcedureForm.value.addDateTime).getTime();
    let endDate = new Date(this.addProcedureForm.value.dueDate).getTime();

    if (
      this.addProcedureForm.value.addDateTime &&
      this.addProcedureForm.value.dueDate &&
      startDate > endDate
    ) {
      alert("End Date can't before start date");
    } else {
      this.startMoment = moment(STARTDATE).startOf("day");
      this.endMoment = moment(ENDDATE).startOf("day");
      console.log(this.startMoment, this.endMoment);
      this.daysInBetween = this.endMoment.diff(this.startMoment, "days") + 1;
      this.weeksInBetween = this.endMoment.diff(this.startMoment, "weeks");
      this.weekDays = this.daysInBetween - this.weeksInBetween * 2;
      console.log(this.daysInBetween, this.weeksInBetween, this.weekDays);
      console.log(this.startMoment.day(), this.endMoment.day());

      if (
        (this.startMoment.day() === 5 && this.endMoment.day() === 6) ||
        this.startMoment.day() > this.endMoment.day() ||
        (this.startMoment.day() === 5 && this.endMoment.day() === 6)
      ) {
        console.log(this.weekDays);
        this.weekDays -= 2;
        console.log(this.weekDays);
      } else if (
        this.startMoment.day() <= this.endMoment.day() &&
        (this.startMoment.day() === 5 ||
          this.startMoment.day() === 6 ||
          this.endMoment.day() === 5 ||
          this.endMoment.day() === 6)
      ) {
        console.log(this.weekDays);
        this.weekDays--;
      }

      // console.log(this.addProcedureForm.value)
      const formdata = new FormData();
      formdata.append("addDateTime", STARTDATE);
      formdata.append("times", STARTTIME);
      formdata.append("dueDate", this.addProcedureForm.value.dueDate);
      formdata.append(
        "procedureName",
        this.addProcedureForm.value.procedureName
      );
      formdata.append("description", this.addProcedureForm.value.description);
      formdata.append("caseId", this.id);
      formdata.append("timezone", this.currentTimeZone);
      if (this.addProcedureForm.value.isWeekendCount) {
        console.log(this.daysInBetween);
        formdata.append("deadline", this.daysInBetween.toString());
      } else {
        console.log(this.weekDays);
        formdata.append("deadline", this.weekDays.toString());
      }
      if (this.addProcedureForm.status == "VALID") {
        this._cases
          .addProcedure(formdata, this.caseFolderName, "procedures")
          .subscribe((res) => {
            console.log(res);
            this.loadProcedures();
            this.hideProcedure();
            this.cancelProcedure();

            // this.isAddProcedure = false;
            //  this.Route.navigate(["lawyerIt/viewCase/this.id"]);
            // this.hideProcedure();
          });
      }
    }
  }

  refresh(): void {
    window.location.reload();
  }
  showprocedure() {
    this.isAddProcedure = true;
  }
  hideProcedure() {
    this.isAddProcedure = false;
  }
  cancelProcedure() {
    this.addProcedureForm.reset();
    this.fileToUpload = "";
    this.isSubmitted = false;
  }
}
