import { Component, OnInit,Input } from '@angular/core';
import {CasesService} from '../../../services/cases.service'
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import {noWhitespaceValidator} from '../../../config/sharedServices/customeValidation'
import {environment} from '../../../../environments/environment'
import swal from 'sweetalert';
import jsPDF from 'jspdf';
import moment from 'moment';
declare var $: any;
@Component({
  selector: "app-attorney-table",
  templateUrl: "./attorney-table.component.html",
  styleUrls: ["./attorney-table.component.scss"],
})
export class AttorneyTableComponent implements OnInit {
  baseUrl = environment.baseUrl;
  @Input() id: any;
  @Input() userRole: any;
  firmData: any;
  addAttorneyFeeForm: any;
  editAttorneyForm: any;
  attorneyFeeData: any = [];
  fileToUpload: any = "";
  isSubmitted: boolean;
  clientid: any;
  caseData: any;
  AllclientId: any = [];
  caseFolderName: any;
  clientList: any;
  attorneyRecieptData: any;
  isCheque: boolean;
  isEditAttorney: boolean = true;
  printChequeDetail: boolean = false;
  attorneyId: any;
  totalAttorneyFee: any;
  totalPendingAttorney: any;
  isDeleteCollection: boolean = false;
  recieptDataNumber: number;
  Voucherdetail: number;
  constructor(private _cases: CasesService, private fb: FormBuilder) {
    this.isSubmitted = false;
    this.isCheque = false;
  }

  ngOnInit() {
    if (this.userRole == "Tier-1" || this.userRole == "Tier-2") {
      this.isEditAttorney = false;
    }
    if (this.userRole == "Admin" || this.userRole == "Sub-Admin") {
      this.isDeleteCollection = true;
    }
    this.viewCaseDetail();
    this.loadAttorneyTable();
    this.attorneyForm();
    this.editAttorneyFee();
  }
  onChangePayment(value) {
    console.log(value);
    if (value == "Cheque") {
      this.isCheque = true;
    } else {
      this.isCheque = false;
    }
    this.setValidators();
  }
  viewCaseDetail() {
    this._cases.viewCaseDetail({ caseId: this.id }).subscribe((res) => {
      console.log(res);
      this.caseData = res.data;
      this.AllclientId = res.data.clientId;
      this.clientList = res.data.clientName;
      // this.AllCounterId = res.data.counterPartyId
      // this.counterPartyList = res.data.counterPartyName

      this.caseFolderName = res.data2.caseFolder;
    });
  }
  loadAttorneyTable() {
    this._cases.listAttorneyFee({ caseId: this.id }).subscribe((res) => {
      console.log(res);
      if (res.data) {
        this.firmData = res.data.firm;
        this.attorneyFeeData = res.data.attorneyFee;
        this.totalAttorneyFee = res.data.totalAttorneyFees;
        let totalPaid = this.attorneyFeeData.reduce(function (cnt, o) {
          return cnt + o.receivedAmount;
        }, 0);
        this.totalPendingAttorney = this.totalAttorneyFee - totalPaid;
      }
    });
  }
  delete(id) {
    console.log(id);
    this.attorneyId = id;
  }
  deleteAttorney() {
    this._cases
      .deleteAttorneyFee({ caseId: this.id, id: this.attorneyId })
      .subscribe(
        (res) => {
          console.log(res);
          this.loadAttorneyTable();
          swal(res.data, "", "success");
        },
        (error) => {
          swal("Error!", error.error.message, "error");
          console.log(error.error.message);
        }
      );
  }
  getAttorneyRecieptData(value) {
    this.recieptDataNumber = value;
      this.Voucherdetail = value;
    for (let i = 0; i < this.attorneyFeeData.length; i++) {
      if (value == i) {
        this.attorneyRecieptData = this.attorneyFeeData[i];
      }
    }
    if (this.attorneyRecieptData.paymentMode == "Cheque") {
      this.printChequeDetail = true;
    } else {
      this.printChequeDetail = false;
    }
    console.log(this.attorneyRecieptData);
  }

  reciptGenerateFront(value) {
    console.log(value);

    const doc = new jsPDF();
    let data = this.attorneyFeeData[value];
    let recivedate = data.receiveDate;
    let newDate = moment.utc(recivedate).format("DD/MM/YY");
    console.log("converted date", newDate);
    let recivefrom = data.receivedBy.companyName;
    let reciveamount = "" + data.receivedAmount;
    let pendingamount = "" + this.totalPendingAttorney;
    let paymethod = data.paymentMode;
    let reciveby = this.firmData.firmName;
    let reason = data.description;

    let chequedate = data.chequeDate;

    let chequeno = "" + data.chequeNumber;
    let drawingacnumber = "" + data.accountNumber;
    let bankname = data.bankName;

    doc.text("Receipt Details", 80, 10);

    doc.text("Firm Name ::", 10, 20);
    doc.text(reciveby, 50, 20);

    doc.text("Date ::", 10, 40);
    doc.text(newDate, 30, 40);

    doc.text("Received From ::", 10, 60);
    doc.text(recivefrom, 60, 60);

    doc.text("Amount ::", 10, 80);
    doc.text(reciveamount, 40, 80);

    doc.text("Balance ( Pending ) ::", 10, 100);
    doc.text(pendingamount, 70, 100);

    doc.text("Payment Method ::", 10, 120);
    doc.text(paymethod, 60, 120);

    doc.text("Reason ::", 10, 140);
    doc.text(reason, 40, 140);

    doc.text("Signature ::", 10, 160);

    if (data.chequeDate) {
      doc.text("Cheque Date ::", 10, 180);
      doc.text(chequedate, 50, 180);
    }

    if (data.chequeNumber) {
      doc.text("Cheque No. ::", 10, 200);
      doc.text(chequeno ? chequeno : "", 50, 200);
    }

    if (data.accountNumber) {
      doc.text("Drawing A/c No. ::", 10, 220);
      doc.text(drawingacnumber ? drawingacnumber : "", 60, 220);
    }

    if (data.bankName) {
      doc.text("Bank Name ::", 10, 240);
      doc.text(bankname ? bankname : "", 65, 240);
    }

    doc.save("Reciept.pdf");
  }

  generateAttorneyReciept() {
    this.reciptGenerateFront(this.recieptDataNumber);
    // let obj = {
    //   date: this.attorneyRecieptData.receiveDate,
    //   from: this.attorneyRecieptData.receivedBy.companyName
    //     ? this.attorneyRecieptData.receivedBy.companyName
    //     : this.attorneyRecieptData.receivedBy.personName,
    //   paid: this.attorneyRecieptData.receivedAmount,
    //   pendingamount: this.attorneyRecieptData.pendingAttorneyFees,
    //   paymentmethod: this.attorneyRecieptData.paymentMode,
    //   type: "Attorney",
    //   reason: this.attorneyRecieptData.description,
    //   chequeDate: this.attorneyRecieptData.chequeDate,
    //   chequeNo: this.attorneyRecieptData.chequeNumber,
    //   drawingAccount: this.attorneyRecieptData.accountNumber,
    //   drawnBankName: this.attorneyRecieptData.bankName,
    // };
    // this._cases.generateReceipt(obj).subscribe((res) => {
    //   console.log(res);
    //   // window.open(this.baseUrl + res.data);
    //   setTimeout(() => window.open(this.baseUrl + res.data), 1000);
    // });
  }

  generateInvoicepdf(value) {
    console.log(value);

    const doc = new jsPDF();
    let data = this.attorneyFeeData[value];
    let recivedate = data.receiveDate;
    let newDate = moment.utc(recivedate).format("DD/MM/YY");
    console.log("converted date", newDate);
    let invoiceNumber = ""+(value+1);
    let Attention = data.receivedBy.companyName;
    let reciveamount = "" + data.receivedAmount;
    let pendingamount = ""+ this.totalPendingAttorney;
    let TotalAmount = ""+this.totalAttorneyFee;
    let IBANNumber = ""+data.receivedBy.IBAN_Number;
   let name = this.firmData.firmName;
    doc.text("Invoice Details", 80, 10);

    doc.text("Invoice Number ::", 10, 30);
    doc.text(invoiceNumber, 60, 30);

    doc.text("Date ::", 10, 50);
    doc.text(newDate, 30, 50);

    doc.text("Attention ::", 10, 70);
    doc.text(Attention, 40, 70);

    doc.text("Reciver Amount ::", 10, 90);
    doc.text(reciveamount, 60, 90);

    if (pendingamount != undefined || pendingamount != null || pendingamount != "") {
      doc.text("Balance ( Pending ) ::", 10, 110);
      doc.text(pendingamount, 70, 110);
    }
    doc.text("Total Amount ::", 10, 130);
    doc.text(TotalAmount, 60, 130);

    doc.text("IBAN Number ::", 10, 150);
    doc.text(IBANNumber, 60, 150);

   doc.text("Name ::",10,170)
   doc.text(name, 40, 170);

    doc.save("Invoice.pdf");
  }

  generateAttorneyInvoice() {
    this.generateInvoicepdf(this.Voucherdetail)
    // let obj = {
    //   date: this.attorneyRecieptData.receiveDate,
    //   from: this.attorneyRecieptData.receivedBy.companyName
    //     ? this.attorneyRecieptData.receivedBy.companyName
    //     : this.attorneyRecieptData.receivedBy.personName,
    //   paid: this.attorneyRecieptData.receivedAmount,
    //   pendingamount: this.attorneyRecieptData.pendingAttorneyFees,
    //   amount: this.caseData.attorneyFee,
    // };
    // this._cases.generateInvoice(obj).subscribe((res) => {
    //   console.log(res);
    //   window.open(this.baseUrl + "/" + res.data);
    // });
  }
  generateAttorneyVoucher() {
      const doc = new jsPDF();
    let obj = {
      date: this.attorneyRecieptData.receiveDate,
      from: this.attorneyRecieptData.receivedBy.companyName
        ? this.attorneyRecieptData.receivedBy.companyName
        : this.attorneyRecieptData.receivedBy.personName,
      paid: ""+this.attorneyRecieptData.receivedAmount,
      pendingamount: ""+this.totalPendingAttorney,
      paymentmethod: this.attorneyRecieptData.paymentMode,
      type: "Attorney",
      reason: this.attorneyRecieptData.description,
      chequeDate: this.attorneyRecieptData.chequeDate,
      chequeNo: this.attorneyRecieptData.chequeNumber,
      drawingAccount: this.attorneyRecieptData.accountNumber,
      drawnBankName: this.attorneyRecieptData.bankName,
    };

     let newDate = moment.utc(obj.date).format("DD/MM/YY");
     console.log("converted date", newDate);

       let newDate1 = moment.utc(obj.chequeDate).format("DD/MM/YY");
       console.log("converted date", newDate1);

     
    // console.log(obj);
    // this._cases.generatePaymentVoucher(obj).subscribe((res) => {
    //   console.log(res);
    //   window.open(this.baseUrl + res.data);
    // });

       doc.text("Voucher Details", 80, 10);

       doc.text("Date ::", 10, 30);


       doc.text(newDate, 30, 30);

       doc.text("Received From ::", 10, 50);
       doc.text(obj.from, 60, 50);

       doc.text("Amount ::", 10, 70);
       doc.text(obj.paid, 40, 70);

       doc.text("Balance ( Pending ) ::", 10, 90);
       doc.text(obj.pendingamount, 70, 90);

       doc.text("Payment Method ::", 10, 110);
       doc.text(obj.paymentmethod, 60, 110);

       doc.text("Reason ::", 10, 130);
       doc.text(obj.reason, 40, 130);

       doc.text("Signature ::", 10, 150);

       if (obj.chequeDate) {
         doc.text("Cheque Date ::", 10, 170);
         doc.text(newDate1, 50, 170);
       }

       if (obj.chequeNo) {
         doc.text("Cheque No. ::", 10, 190);
         doc.text(obj.chequeNo ? obj.chequeNo : "", 50, 190);
       }

       if (obj.drawingAccount) {
         doc.text("Drawing A/c No. ::", 10, 210);
         doc.text(obj.drawingAccount ? obj.drawingAccount : "", 60, 210);
       }

       if (obj.drawnBankName) {
         doc.text("Bank Name ::", 10, 230);
         doc.text(obj.drawnBankName ? obj.drawnBankName : "", 50, 230);
       }

       doc.save("Voucher.pdf");
  }

  viewAttorneyFee(value) {
    let img = this.attorneyFeeData[value].attachment;
    console.log(value, "attorneyfee viewed");

    window.open(environment.baseUrl + img);
  }
  onChangeClint(value) {
    for (let i = 0; i < this.AllclientId.length; i++) {
      if (value == i) {
        console.log(this.AllclientId[i]);
        this.clientid = this.AllclientId[i];
      }
    }
  }
  addValue(a, b) {
    return a + b;
  }
  setValidators() {
    const institutionControl = this.addAttorneyFeeForm.get("paymentMode");
    if (institutionControl.value === "Cheque") {
      this.addAttorneyFeeForm
        .get("chequeNumber")
        .setValidators([Validators.required]);
      this.addAttorneyFeeForm
        .get("chequeDate")
        .setValidators([Validators.required]);
      this.addAttorneyFeeForm
        .get("bankName")
        .setValidators([Validators.required]);
      this.addAttorneyFeeForm
        .get("accountNumber")
        .setValidators([Validators.required]);
    } else {
      this.addAttorneyFeeForm.get("chequeNumber").setValidators("");
      this.addAttorneyFeeForm.get("chequeDate").setValidators("");
      this.addAttorneyFeeForm.get("bankName").setValidators("");
      this.addAttorneyFeeForm.get("accountNumber").setValidators("");
    }
    this.addAttorneyFeeForm.get("chequeNumber").updateValueAndValidity();
    this.addAttorneyFeeForm.get("chequeDate").updateValueAndValidity();
    this.addAttorneyFeeForm.get("bankName").updateValueAndValidity();
    this.addAttorneyFeeForm.get("accountNumber").updateValueAndValidity();
  }

  getAttorneyForEdit(value, id) {
    this.attorneyId = id;
    for (let i = 0; i < this.attorneyFeeData.length; i++) {
      if (value == i) {
        this.attorneyRecieptData = this.attorneyFeeData[i];
      }
    }
    let clientName = this.attorneyRecieptData.receivedBy.companyName
      ? this.attorneyRecieptData.receivedBy.companyName
      : this.attorneyRecieptData.receivedBy.personName;

    let index = this.clientList.findIndex((x) => x.name === clientName);
    this.clientid = this.attorneyRecieptData.receivedBy._id;
    if (this.attorneyRecieptData.paymentMode == "Cheque") {
      this.isCheque = true;
      this.editAttorneyForm.patchValue({
        receiveDate: this.formatDate(this.attorneyRecieptData.receiveDate),
        receivedBy: index,
        paymentMode: this.attorneyRecieptData.paymentMode,
        description: this.attorneyRecieptData.description,
        chequeNumber: this.attorneyRecieptData.chequeNumber,
        chequeDate: this.formatDate(this.attorneyRecieptData.chequeDate),
        bankName: this.attorneyRecieptData.bankName,
        accountNumber: this.attorneyRecieptData.accountNumber,
      });
    } else {
      this.isCheque = false;
      this.editAttorneyForm.patchValue({
        receiveDate: this.formatDate(this.attorneyRecieptData.receiveDate),
        receivedBy: index,
        paymentMode: this.attorneyRecieptData.paymentMode,
        description: this.attorneyRecieptData.description,
      });
    }
  }
  editAttorneyFee() {
    this.editAttorneyForm = this.fb.group({
      receiveDate: ["", [Validators.required]],
      receivedBy: ["", [Validators.required]],
      paymentMode: ["", [Validators.required]],
      // files:[''],
      description: ["", noWhitespaceValidator],
      chequeNumber: [""],
      chequeDate: [""],
      bankName: [""],
      accountNumber: [""],
    });
  }
  saveEditAttorneyFee() {
    this.isSubmitted = true;
    let obj = {};
    if (this.editAttorneyForm.value.paymentMode == "Cheque") {
      obj = {
        receiveDate: this.formatDate(this.editAttorneyForm.value.receiveDate),
        receivedBy: this.clientid,
        paymentMode: this.editAttorneyForm.value.paymentMode,
        description: this.editAttorneyForm.value.description,
        chequeNumber: this.editAttorneyForm.value.chequeNumber,
        chequeDate: this.formatDate(this.editAttorneyForm.value.chequeDate),
        bankName: this.editAttorneyForm.value.bankName,
        accountNumber: this.editAttorneyForm.value.accountNumber,
        id: this.attorneyId,
      };
    } else {
      obj = {
        receiveDate: this.formatDate(this.editAttorneyForm.value.receiveDate),
        receivedBy: this.clientid,
        paymentMode: this.editAttorneyForm.value.paymentMode,
        description: this.editAttorneyForm.value.description,
        chequeNumber: "",
        chequeDate: "",
        bankName: "",
        accountNumber: "",
        id: this.attorneyId,
      };
    }
    console.log(obj);
    if (this.editAttorneyForm.status == "VALID") {
      this._cases.editAttorneyFee(obj).subscribe(
        (res) => {
          console.log(res);
          this.loadAttorneyTable();
          this.cancelAttorneyFee();
          $("#editAttorneyModal").modal("hide");
          swal(res.data, "", "success");
        },
        (error) => {
          $("#editAttorneyModal").modal("hide");
          swal("Error!", error.error.message, "error");
          console.log(error.error.message);
        }
      );
    }
  }

  attorneyForm() {
    this.addAttorneyFeeForm = this.fb.group({
      receiveDate: ["", [Validators.required]],
      receivedAmount: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[0-9]+([.][0-9]+)?$/),
          Validators.min(0.01),
        ],
      ],
      paymentMode: ["", [Validators.required]],
      receivedBy: ["", [Validators.required]],
      files: [""],
      description: ["", noWhitespaceValidator],
      chequeNumber: [""],
      chequeDate: [""],
      bankName: [""],
      accountNumber: [""],
    });
  }

  UploadFileForAttorney(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.addAttorneyFeeForm.value.files);
  }
  addAttorneyFee() {
    this.isSubmitted = true;
    console.log(this.addAttorneyFeeForm.value.receivedBy);
    const formdata = new FormData();
    formdata.append(
      "receiveDate",
      this.formatDate(this.addAttorneyFeeForm.value.receiveDate)
    );
    formdata.append(
      "receivedAmount",
      this.addAttorneyFeeForm.value.receivedAmount
    );
    formdata.append("paymentMode", this.addAttorneyFeeForm.value.paymentMode);
    formdata.append("files", this.fileToUpload);
    formdata.append("description", this.addAttorneyFeeForm.value.description);
    formdata.append("receivedBy", this.clientid);
    formdata.append("caseId", this.id);
    formdata.append("chequeNumber", this.addAttorneyFeeForm.value.chequeNumber);
    formdata.append(
      "chequeDate",
      this.formatDate(this.addAttorneyFeeForm.value.chequeDate)
    );
    formdata.append("bankName", this.addAttorneyFeeForm.value.bankName);
    formdata.append(
      "accountNumber",
      this.addAttorneyFeeForm.value.accountNumber
    );
    console.log(formdata);
    if (this.addAttorneyFeeForm.status == "VALID") {
      this._cases
        .updateAttorneyFee(
          formdata,
          this.caseFolderName,
          "attorneyFeeDocs",
          this.id
        )
        .subscribe(
          (res) => {
            console.log(res);
            this.loadAttorneyTable();
            this.cancelAttorneyFee();
            $("#attorneyModal").modal("hide");
          },
          (error) => {
            swal("Error!", error.error.message, "error");
          }
        );
    }
  }
  private formatDate(date) {
    if (date) {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    } else {
    }
  }
  cancelAttorneyFee() {
    this.addAttorneyFeeForm.reset();
    this.fileToUpload = "";
    this.isSubmitted = false;
    this.isCheque = false;
  }
}
