import { Injectable } from '@angular/core';
import {throwError } from 'rxjs';
import { ApiService } from '../config/sharedServices/api.service';
import { IapiBaseObject } from '../config/iModels/IApiBaseObject';
import { API_KEYS } from '../config/apiKeys/api-keys-constants';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class DocumentUtilitiesService {
  constructor(private _api: ApiService) {}

  uploadContactDocs(data, Folder) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPLOADCONTACTDOCS.replace(":folderName", Folder),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  uploadFileInDir(data, dirName, subFolder) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPLOADFILEINDIR.replace(
        ":rootfolderName",
        dirName
      ).replace(":subFolderName", subFolder),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  createDirManual(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CREATESUBFOLDER,
      apiData: data,
    };
    console.log(apiObj);
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  removeFileFromDocs(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVEFILEFROMDOC,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  removeSubFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVESUBFOLDER,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  removeFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVEFOLDER,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  updateFileTime(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEFILETIME,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  downloadCaseFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DOWNLOADCASEFOLDER,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  deleteCaseFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETECASEFOLDER,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  searchFile(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHFILEINDOCUMENT,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  searchFileStatus(data, status) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHFILEINDOCUMENTSTUTUSFILTER.replace(
        ":caseStatus",
        status
      ),
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  searchFileType(data, type) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHFILEINDOCUMENTTYPEFILTER.replace(
        ":caseType",
        type
      ),
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  searchFileDoubleFilter(data, status, type) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHFILEINDOCUMENTDOUBLEFILTE.replace(
        ":caseStatus",
        status
      ).replace(":caseType", type),
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  AlldocList() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ALLDOCS,
    };
    return this._api.getApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  getUtilites() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UTILITYTABLE,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  listContent(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LISTCONTENT,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  listTermaAboutUs(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.LISTTERMABOUT,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
