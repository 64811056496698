import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {UserService} from '../../../services/user.service'
declare var $: any;
import {noWhitespaceValidator} from '../../../config/sharedServices/customeValidation'
import swal from 'sweetalert';
@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {
  helpForm:any;
  isSubmitted:any
  constructor(private fb: FormBuilder,private userService: UserService) { 
    this.isSubmitted = false
  }

  ngOnInit() {
    this.helpFormBuilder()
    // if(window.Tawk_API) {
    //   window.Tawk_API.minimize();
    // } 
  }

  openChat() {
    console.log(window["Tawk_API"])
    if(window["Tawk_API"]) {
      window["Tawk_API"].toggle();
    } 
  }

  openWhatsappChat() {
    window.open('https://api.whatsapp.com/send?phone=917985017075')
  }

  helpFormBuilder(){
     this.helpForm = this.fb.group({
      name:['',[Validators.required,Validators.minLength(3),noWhitespaceValidator]],
      subject:['',[Validators.required,Validators.minLength(3),noWhitespaceValidator]],
      suggestions:['',[Validators.required,Validators.minLength(3),noWhitespaceValidator]]
     })
  }
  submitForm(){
    this.isSubmitted = true
    if(this.helpForm.status === 'VALID'){
      this.userService.helpCenter(this.helpForm.value).subscribe(res => {
        console.log(res)
        this.isSubmitted = false
        this.helpForm.reset()
        swal(res.message, "", "success");
        // alert("Request Submitted Successfully")
      },error => {
        swal("Error!", error.error.message, "error");
       })
    }
  
  }
}
