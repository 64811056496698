import { Component, OnInit } from '@angular/core';
import { ContactsService } from '../../../services/contacts.service'
import { environment } from '../../../../environments/environment'
import { CasesService } from '../../../services/cases.service'
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { Observable } from 'rxjs'
import { JsonPipe } from '@angular/common';
import swal from 'sweetalert';
import { map } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  baseUrl = environment.baseUrl;
  length: number;
  totalPages: any = []
  pageSize: number;
  pageIndex: number;
  contactData: any
  CSVdata: any = []
  downloadData: any = []
  importCSV: any;
  contactId: any;
  searchKey: any;
  contactType: any;
  searchField: any
  headers$: any = []
  data$: any = []
  newData: any = []
  errorArr$: any = []
  isDeleteContact: boolean = false
  isEditContact: boolean = false
  Types: any = ['company', 'person']
  Refinaries: any = [{ key: 'Company name', value: 'companyName' }, { key: 'Company owner name', value: 'companyOwnerName' },
  { key: 'Person name', value: 'personName' }, { key: 'Phone number', value: 'phoneNumber' },
  { key: 'Email address', value: 'email' }, { key: 'Ultimate beneficial person', value: 'ultimateBeneficialPerson' },]
  constructor(private _contact: ContactsService, private _cases: CasesService) {
    this.pageSize = 5;
    this.pageIndex = 1;
    this.contactType = '';
    this.searchField = '';

  }

  ngOnInit() {
    this.loadAllContacts()
    this.ContactForDownload()
  }

  loginUserData(data) {
    console.log(data.role.name)
    if (data.role.name == 'Tier-3' ||
      data.role.name == 'Sub-Admin' || data.role.name == 'Admin') {
      this.isEditContact = true
    }
    if (data.role.name == 'Admin' || data.role.name == 'Sub-Admin') {
      this.isDeleteContact = true
    }
  }
  handlePageChange(event) {
    this.pageIndex = event;
    if (this.searchKey) {
      this.searchContact("pagination")
    }
    else {
      this.loadAllContacts();
    }
  }
  addContactsPage() {
    window.location.href = "/lawyerIt/addContact"
  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.pageIndex = 1;
    if (this.searchKey) {
      this.searchContact()
    }
    else {
      this.loadAllContacts();
    }
  }

  loadAllContacts() {
    this._contact.listAllContacts(this.pageSize, this.pageIndex - 1).subscribe(res => {
      if (res.data) {
        this.contactData = res.data.findListData
        this.length = res.data.coundData.coundData
        this.totalPages = res.data.coundData.totalPages
      }
    })
    // this.lloadAllContacts()
  }

  // lloadAllContacts() {

  //   this._contact.listAllContacts(this.pageSize, this.pageIndex - 1)
  //     .pipe(map(res => {
  //       let willMask = res['data'].findListData
  //       for (let mask in willMask) {
  //         mask['email']
  //         debugger
  //       }
  //     }))
  // }
  onChangeRefinary(event) {
    console.log(event)
    this.searchField = event
  }
  onChangeType(event) {
    console.log(event)
    this.contactType = event
  }
  searchContact(p = "") {
    if (!p) this.pageIndex = 1
    let obj = {
      contactType: this.contactType,
      searchField: this.searchField,
      searchKey: this.searchKey,
    }
    console.log(obj)
    if (this.searchKey) {
      if (this.searchKey.trim() != '') {
        this._contact.searchContact(this.pageSize, this.pageIndex - 1, obj).subscribe(res => {
          console.log(res)
          this.contactData = res.data.findListData
          console.log(this.contactData)
          this.length = res.data.coundData.coundData
          this.totalPages = res.data.coundData.totalPages
          console.log("totalRecord", this.length, "total pages", this.totalPages)
        })
      }
    }
    else {
      this.loadAllContacts()
    }

  }

  delete(id) {
    this.contactId = id
  }
  deleteContact() {
    this._contact.deleteContact({ contactId: this.contactId }).subscribe(res => {
      swal(res.data, "", "success");
      console.log(res)
      if (this.searchKey) {
        this.searchContact()
      }
      else {
        this.loadAllContacts();
      }
    })
  }

  getSNo(z) {
    return z + 1 + (this.pageSize * (this.pageIndex - 1))
  }

  reload() {
    setTimeout(() => {
      window.location.reload()

    }, 500);
  }
  testy(value) {
    let data = this.data$.find(m => console.log(m))
    // console.log(data)
  }
  fileSelected(files: FileList) {
    this.importCSV = files.item(0)
    console.log(this.importCSV)
    if (this.importCSV) {
      let ext = this.importCSV.name.split('.').pop();
      if (ext == 'csv') {
        const formdata = new FormData();
        formdata.append("file", this.importCSV);
        console.log(formdata)
        this._contact.convetCsvtoJson(formdata).subscribe(res => {
          this.data$ = res.data
          this.headers$ = res.header
          this.errorArr$ = res.errorArr
          console.log(res)
          $("#gTourModal").modal('show');
          $('.modal-open').addClass('point-event');
        }, error => {
          alert(error.error.message)
          window.location.reload()
        })
      }
      else {
        alert("Invalid file ")
        window.location.reload()
      }
    }
  }

  importCSv() {
    const formdata = new FormData();
    formdata.append("file", this.importCSV);
    this._contact.importcsv(formdata).subscribe(res => {
      console.log(res)
      if (res && res.success) {
        swal(res.data, "", "success");
        setTimeout(() => {
          window.location.reload()
          this.loadAllContacts()
        }, 1000);
      }
    }, error => {
      console.log(error)
      alert(error.error.message)
      window.location.reload()
    })

  }

  downloadtemplate() {
    window.open(this.baseUrl + '/upload/lawyerittemplate.csv');
  }
  ContactForDownload() {
    this._cases.contactList().subscribe(res => {
      // console.log(res.data)
      if (res.data) {
        this.downloadData = res.data.findListData
      }
    })
  }
  downloadContacts() {
    this.CSVdata = []
    let csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'All Contacts List',
      useBom: true,
      noDownload: false,
      headers: ["Contact Type", "Name", "Email", "Phone", "CR Number",
        "CPR Number", "Company Owner Name", "Nationality", "Passport Number", "Source of Income",
        "Address", "Activity", "IBAN Number", "Occupation"]

    };
    for (let i = 0; i < this.downloadData.length; i++) {
      this.CSVdata.push({
        contactType: this.downloadData[i].contactType,
        companyName: this.downloadData[i].companyName ? this.downloadData[i].companyName : this.downloadData[i].personName,
        email: this.downloadData[i].email,
        phoneNumber: this.downloadData[i].phoneNumber,
        crNumber: this.downloadData[i].crNumber,
        cprNumber: this.downloadData[i].cprNumber,
        companyOwnerName: this.downloadData[i].companyOwnerName,
        nationality: this.downloadData[i].nationality,
        passportNumber: this.downloadData[i].passportNumber,
        sourceOfIncome: this.downloadData[i].sourceOfIncome,
        address: this.downloadData[i].address,
        activity: this.downloadData[i].activity,
        IBAN_Number: this.downloadData[i].IBAN_Number,
        occupation: this.downloadData[i].occupation
      })
    }
    const csv = new AngularCsv(this.CSVdata, 'All Contacts', csvOptions);
  }
  generateReport(contactid) {
    console.log(contactid)
    this._contact.getFeetab({ contactId: contactid }).subscribe(res => {
      console.log(res)
      this.CSVdata = []
      let csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'All Contacts List',
        useBom: true,
        noDownload: false,
        headers: ["Total CollectionFee",
          "Total Attorney Fee",
          "Case Reference", "Case Number",
          "Case Type", "Case Status", "Case Sub Status", "Case Subject"]

      };
      for (let i = 0; i < res.data.length; i++) {
        this.CSVdata.push({
          totalAmount: res.data[i].collectionfee.length == 0 ? res.data[i].totalJudgementAmount : res.data[i].collectionfee[0].totalCollectionFee,
          // paid: res.data[i].collectionfee.length == 0 ? 0 : this.getPaidCollection(res.data[i].collectionfee[0].collectionFee) ,
          // pending: res.data[i].collectionfee.length == 0 ? res.data[i].totalJudgementAmount : this.getPaindingCollection(res.data[i].collectionfee[0].collectionFee,res.data[i].collectionfee[0].totalCollectionFee) ,
          totalAttorneyAmount: res.data[i].attroneyfee.length == 0 ? res.data[i].attorneyFee : res.data[i].attroneyfee[0].totalAttorneyFees,
          // Attorneypaid:  res.data[i].attroneyfee.length == 0 ? 0 : this.getPaidCollection(res.data[i].attroneyfee[0].attorneyFee),
          // Attorneypending: res.data[i].attroneyfee.length == 0 ? res.data[i].attorneyFee : this.getPaindingCollection(res.data[i].attroneyfee[0].attorneyFee,res.data[i].attroneyfee[0].totalAttorneyFees) ,

          caseReference: res.data[i].caseReference,
          caseNumber: res.data[i].caseNumber,
          caseType: res.data[i].caseType,
          caseStatus: res.data[i].caseStatus,
          caseSubStatus: res.data[i].caseSubStatus,
          caseSubject: res.data[i].caseSubject,

        })
      }
      const csv = new AngularCsv(this.CSVdata, 'Contact Report', csvOptions);
    })
  }


  getPaidCollection(value) {
    // console.log(value)
    if (value) {

      let totalPaid = 0
      for (let i = 0; i < value.length; i++) {
        totalPaid = totalPaid + value[i].receivedAmount
      }
      return totalPaid
    }
    else {
      return 0
    }
  }


  getPaindingCollection(value, value2) {
    if (value) {
      let totalPaid = 0
      for (let i = 0; i < value.length; i++) {
        totalPaid = totalPaid + value[i].receivedAmount
      }
      return value2 - totalPaid
    }
    else {
      return value2
    }
  }
}
function x(x: any, arg1: (String: any) => void): any {
  throw new Error('Function not implemented.');
}

