import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import * as Chart from 'chart.js'
@Component({
  selector: 'app-status-chart',
  templateUrl: './status-chart.component.html',
  styleUrls: ['./status-chart.component.scss']
})
export class StatusChartComponent implements OnInit {
    totalCases:any;
    activeCases:any;
    closedCases:any;
    suspendedCases:any;
    setteledCases:any
    activeCasesPercentage:any;
    closedCasesPercentage:any;
    suspendedCasesPercentage:any;
    setteledCasesPercentage:any
  constructor(private _dashboad:DashboardService) { }

  ngOnInit() {
    this.loadStatics()
  }
  loadStatics(){
    this._dashboad.caseStatistics().subscribe(res => {
    //   console.log(res)
      this.totalCases = res.totalCases
      this.activeCases = res.activeCases
      this.closedCases = res.closedCases
      this.suspendedCases = res.suspended
      this.setteledCases = res.settledCases
      if(this.totalCases == 0){
        this.activeCasesPercentage = 0
        this.closedCasesPercentage = 0
        this.suspendedCasesPercentage = 0
        this.setteledCasesPercentage =0
      }else{
        this.activeCasesPercentage = ((this.activeCases / this.totalCases)*100).toFixed()
        this.closedCasesPercentage = ((this.closedCases / this.totalCases)*100).toFixed()
        this.suspendedCasesPercentage = ((this.suspendedCases / this.totalCases)*100).toFixed()
        this.setteledCasesPercentage = ((this.setteledCases / this.totalCases)*100).toFixed()
      }
    
    })
  }
  filterbySubStatus(value){
    console.log(value)
    if(value){
      this._dashboad.filterCaseStatistics({caseSubStatus:value}).subscribe(res => {
        console.log(res)
        this.totalCases = res.totalCases
        this.activeCases = res.activeCases
        this.closedCases = res.closedCases
        this.suspendedCases = res.suspended
        this.setteledCases = res.settledCases
        if(this.totalCases == 0){
            this.activeCasesPercentage = 0
            this.closedCasesPercentage = 0
            this.suspendedCasesPercentage = 0
            this.setteledCasesPercentage =0
        }
        else{
            this.activeCasesPercentage = ((this.activeCases / this.totalCases)*100).toFixed()
            this.closedCasesPercentage = ((this.closedCases / this.totalCases)*100).toFixed()
            this.suspendedCasesPercentage = ((this.suspendedCases / this.totalCases)*100).toFixed()
            this.setteledCasesPercentage = ((this.setteledCases / this.totalCases)*100).toFixed()
        }
       
        this.ngAfterViewInit()
       })
    }
    else{
        this.loadStatics()  
        this.ngAfterViewInit()
    }
  }
  ngAfterViewInit(){
    setTimeout(() => {
      Chart.pluginService.register({
        beforeDraw: function(chart) {
            if (chart.config.options.elements.center) {
                // Get ctx from string
                var ctx = chart.chart.ctx;
    
                // Get options from the center object in options
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || "Arial";
                var txt = centerConfig.text;
                var color = centerConfig.color || "#000";
                var maxFontSize = centerConfig.maxFontSize || 75;
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;
    
                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                var stringWidth = ctx.measureText(txt).width;
                var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
    
                // Find out how much the font can grow in width.
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = chart.innerRadius * 2;
    
                // Pick a new font size so it will not be larger than the height of label.
                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                var minFontSize = centerConfig.minFontSize;
                var lineHeight = centerConfig.lineHeight || 25;
                var wrapText = false;
    
                if (minFontSize === undefined) {
                    minFontSize = 20;
                }
    
                if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                }
    
                // Set font settings to draw it correctly.
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                ctx.font = fontSizeToUse + "px " + fontStyle;
                ctx.fillStyle = color;
    
                if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                }
    
                var words = txt.split(" ");
                var line = "";
                var lines = [];
    
                // Break words up into multiple lines if necessary
                for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + " ";
                    var metrics = ctx.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                        lines.push(line);
                        line = words[n] + " ";
                    } else {
                        line = testLine;
                    }
                }
    
                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;
    
                for (var n = 0; n < lines.length; n++) {
                    ctx.fillText(lines[n], centerX, centerY);
                    centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
            }
        },
    });
    var ctx = document.getElementById("caseBarchart");
    var myChart = new Chart(ctx, {
        type: "bar",
        data: {
            labels: ["Total Case", "Active", "Settled", "Closed","Suspended"],
            datasets: [{
                label: "Total Cases",
                data: [this.totalCases, this.activeCases, this.setteledCases, this.closedCases,this.suspendedCases],
                backgroundColor: ["#55D8FE", "#FF8373", "#FFDA83", "#A3A0FB", "#FCC48F"],
                borderColor: ["#55D8FE", "#FF8373", "#FFDA83", "#A3A0FB","#FCC48F"],
            }, ],
        },
        options: {
            responsive: true,
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    barThickness: 50,
                }, ],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: true,
                        color: "rgba(234,240,244)",
                    },
                    ticks: {
                        padding: 25,
                        suggestedMax: 100,
                        suggestedMin: -1,
                    },
                }, ],
            },
            legend: {
                display: false,
            },
        },
    });
    },2000)
  }
}
