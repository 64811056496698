import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// custorm module
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertConfig } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgImageSliderModule } from 'ng-image-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
const MyDefaultTooltipOptions: TooltipOptions = {
  'show-delay': 500
};

// custom component

import { HomeComponent } from './views/common/home/home.component';
import { HeadersComponent } from './views/common/headers/headers.component';
import { FooterComponent } from './views/common/footer/footer.component';
import { ResetPasswordComponent } from './views/common/reset-password/reset-password.component';
import { DashboardComponent } from './views/dashboard/dashboard/dashboard.component';
import { InnerHeaderComponent } from './views/dashboard/inner-header/inner-header.component';
import { InnerSidebarComponent } from './views/dashboard/inner-sidebar/inner-sidebar.component';
import { ContactListComponent } from './views/contacts/contact-list/contact-list.component';
import { AddContactComponent } from './views/contacts/add-contact/add-contact.component';
import { EditContactComponent } from './views/contacts/edit-contact/edit-contact.component';
import { ProfileComponent } from './views/settings/profile/profile.component';
import { FirmProfileComponent } from './views/settings/firm-profile/firm-profile.component';
import { AllCaseListComponent } from './views/cases/all-case-list/all-case-list.component';
import { AddNewCaseComponent } from './views/cases/add-new-case/add-new-case.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OverlayModule } from '@angular/cdk/overlay';
import { ViewCaseComponent } from './views/cases/view-case/view-case.component';
import { EditCaseComponent } from './views/cases/edit-case/edit-case.component';
import { HelpCenterComponent } from './views/settings/help-center/help-center.component';
import { AboutUsComponent } from './views/common/about-us/about-us.component';
import { FeaturesComponent } from './views/common/features/features.component';
import { LoaderComponent } from './views/shared/loader/loader.component';
import { DocumentListComponent } from './views/documentsUtilities/document-list/document-list.component';
import { UtilitiesComponent } from './views/documentsUtilities/utilities/utilities.component';
import { KeysPipe } from './views/shared/keys.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighestAmountComponent } from './views/dashboard/highest-amount/highest-amount.component';
import { Dashboard2Component } from './views/dashboard/dashboard2/dashboard2.component';
import { UserActivityCountComponent } from './views/dashboard/user-activity-count/user-activity-count.component';
import { CollectionPieChartComponent } from './views/dashboard/collection-pie-chart/collection-pie-chart.component';
import { AttorneyPieChartComponent } from './views/dashboard/attorney-pie-chart/attorney-pie-chart.component';
import { StatusChartComponent } from './views/dashboard/status-chart/status-chart.component';
import { TypeChartComponent } from './views/dashboard/type-chart/type-chart.component';
import { ProcedureComponent } from './views/cases/procedure/procedure.component';
import { NotesComponent } from './views/cases/notes/notes.component';
import { RelatedCaseComponent } from './views/cases/related-case/related-case.component';
import { CollectionTableComponent } from './views/cases/collection-table/collection-table.component';
import { AttorneyTableComponent } from './views/cases/attorney-table/attorney-table.component';
import { CalendarComponent } from './views/cases/calendar/calendar.component';
import { DocumentComponent } from './views/cases/document/document.component';
import { DatePipe } from '@angular/common';
import { TermsComponent } from './views/common/terms/terms.component';
import { TimePickerModule, DateTimePickerModule,DatePickerModule } from '@syncfusion/ej2-angular-calendars';
// import { IgxTimePickerModule } from 'igniteui-angular';
@NgModule({
  declarations: [
    AppComponent, HomeComponent, HeadersComponent, FooterComponent, ResetPasswordComponent,
    DashboardComponent, InnerHeaderComponent, InnerSidebarComponent, ContactListComponent,
    AddContactComponent, EditContactComponent, ProfileComponent, FirmProfileComponent,
    AllCaseListComponent, AddNewCaseComponent, ViewCaseComponent, EditCaseComponent,
    HelpCenterComponent, AboutUsComponent, FeaturesComponent, LoaderComponent, DocumentListComponent, UtilitiesComponent, KeysPipe, HighestAmountComponent, Dashboard2Component, UserActivityCountComponent, CollectionPieChartComponent, AttorneyPieChartComponent, StatusChartComponent, TypeChartComponent, ProcedureComponent, NotesComponent, RelatedCaseComponent, CollectionTableComponent, AttorneyTableComponent, CalendarComponent, DocumentComponent, TermsComponent,
  ],
  imports: [OverlayModule, BrowserAnimationsModule,
    BrowserModule, AppRoutingModule, AlertModule, FormsModule, ReactiveFormsModule, HttpClientModule,
    NgxPaginationModule, MatAutocompleteModule, MatInputModule, MatFormFieldModule, ImageCropperModule,
    NgImageSliderModule, CarouselModule, NgbModule, TimePickerModule, DateTimePickerModule,DatePickerModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions)
  ],
  providers: [{ provide: AlertConfig }, DatePipe,],
  bootstrap: [AppComponent]
})
export class AppModule {
}
