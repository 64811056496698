import { Component, OnInit,Input } from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import {CasesService} from '../../../services/cases.service'
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import { CalendarEvent,CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import {startOfDay, endOfDay, subDays, addDays, endOfMonth,isSameDay,isSameMonth,addHours,} from 'date-fns';
import {noWhitespaceValidator,removeDSpaces,removeSpaces} from '../../../config/sharedServices/customeValidation'
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common'
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import moment from 'moment-timezone';
import {AuthService} from '../../../services/auth.service'
import {environment} from '../../../../environments/environment';
declare var $: any;
const assignedList = {
  "Tier-3" : ["Tier-1","Tier-2"],
  "Tier-2": ["Tier-1"],
  "Tier-1": [],
  "Sub-Admin": ["Tier-1","Tier-2","Tier-3"],
  "Admin": ["Tier-1","Tier-2","Tier-3","Sub-Admin"]
}
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  baseUrl = environment.baseUrl;
  EeventId:any = []
  @Input() id :any;
  @Input() caseData :any
  userDetail :any
  caseFolderName:any
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events:any = []
  activeDayIsOpen: boolean ;
  allEvents:any;
  addEventForm:any
  editEventForm:any
  isViewEvent:boolean
  lawyerList:any = []
  LawyerId:any
  AllCounterId:any = []
  AllclientId:any=[]
  counterPartyList:any=[]
  clientList:any = []
  CounterPartyId:any;
  isEdit:boolean = false
  eventDetail:any
  // caseData:any
  clientid:any
  isShowFilter:boolean
  filterEventData:any
  fromDate:any;
  toDate:any;
  isSubmitted:boolean
  CSVdata: any = []
  canEventDelete:boolean =false
  isCreateEvent:boolean = true
  TIME:any
  currentTimeZone:any
  loginUserRoleName:any
  priorTime:any
  PriorTimeList:any = ['15 minute','30 minute','45 minute','60 minute','75 minute','90minute']
  constructor(private _dashboad:DashboardService,private _cases: CasesService,private fb: FormBuilder,
    public datepipe: DatePipe,private authService: AuthService) { 
    this.isViewEvent = false
    this.isShowFilter = false
    this.isSubmitted = false
    this.getUserDetail()

  }
  getUserDetail(){
    this.authService.getUserDetail().subscribe(res => {
      console.log(res)
      if(res){
        this.userDetail = res.data
        this.currentTimeZone = res.data.timeZone
        this.loginUserRoleName = res.data.role.name
        }
   })
  }
  ngOnInit() {
    this.viewCaseDetail()
    this.buildEventForm()
    this.buildEventEditForm()
    this.loadCalendarEvents()
    this.loadClientCouterList() 
    setTimeout(() => {
      if(this.userDetail.role.name == 'Admin'){
        this.canEventDelete = true
      }
      if(this.userDetail.role.name == 'Tier-1'){
        this.isCreateEvent = false
        this.refresh.next();
       }
    },1000)
  
     var todaysDate = new Date()
     var year = todaysDate.getFullYear();                        
     var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);  
     var day = ("0" + todaysDate.getDate()).slice(-2);   
     var minDate = (year +"-"+ month +"-"+ day);
       document.getElementById("datefield").setAttribute("min", minDate)
  }
  ngAfterViewInit(){
    setTimeout(() => {
      console.log('after view')
       this.refresh.next();
     
    },1000)
   
  }
  onChangePriorTime(value){
    this.priorTime = value
   }
onChangeClint(value){
    for(let i=0; i< this.AllclientId.length;i++){
        if(value == i){
          this.clientid = this.AllclientId[i]
        }
    }
  }
onChangeCOunter(value){
    for(let i=0; i< this.AllCounterId.length;i++){
      if(value == i){
        console.log(this.AllCounterId[i])
        this.CounterPartyId = this.AllCounterId[i]
      }
    }
}
closeEvents(){
    this.fromDate = ''
    this.toDate = ''
    this.isShowFilter = false
    // this.refresh.next();
}
viewCaseDetail(){
    this._cases.viewCaseDetail({caseId:this.id}).subscribe(res => {
      console.log(res)
      // this.caseData = res.data
      this.AllclientId = res.data.clientId
      this.clientList = res.data.clientName
      this.AllCounterId = res.data.counterPartyId
      this.counterPartyList = res.data.counterPartyName
      this.caseFolderName = res.data2.caseFolder
  
    })
}

  loadCalendarEvents(){
    // console.log(this.caseData.caseReference)
     this._dashboad.listEvent({caseId:this.caseData.caseReference}).subscribe(res => {
          // console.log(res)
          this.allEvents =res.data.findListData
           for(let i=0; i<res.data.findListData.length; i++){
             
            if(res.data.findListData[i].timezone == this.currentTimeZone) {
        
            } else {
            
             res.data.findListData[i].eventDateTime = this.convertTZ(res.data.findListData[i].eventDateTime,this.currentTimeZone)
          }
        
            this.events.push({start:startOfDay(new Date(res.data.findListData[i].eventDateTime)) ,
            _id:res.data.findListData[i]._id,title:res.data.findListData[i].eventName})
          }
          console.log(this.events)
          // this.refresh.next();
        })
  
  }

  // checkDuplicateEvente(value){
  //   let check = this.allEvents.find(m => this.datepipe.transform(m.eventDateTime, 'yyyy-MM-dd') == value)
  //   if(check){
  //     this.addEventForm.patchValue({
  //       eventDateTime:  '',
  //     })
  //     alert('An Event Already added on this date')
  //   }
  // }
  selectFromDate(value){
    this.fromDate = value
    if(this.fromDate && this.toDate){
        this.isShowFilter = true
        this._dashboad.filterEvent({type:'DateRange',startDate:this.fromDate,endDate:this.toDate,
        caseId:this.caseData.caseReference,timezone:this.currentTimeZone})
        .subscribe(res => {
          this.refresh.next();
            this.filterEventData = res.data[0].totalData
            console.log(res)
        })
    }
    
}
selectToDate(value){
    this.toDate = value
    if(this.fromDate && this.toDate){
        this.isShowFilter = true
        this._dashboad.filterEvent({type:'DateRange',startDate:this.fromDate,endDate:this.toDate,
        caseId:this.caseData.caseReference,timezone:this.currentTimeZone})
        .subscribe(res => {
          this.refresh.next();
            this.filterEventData = res.data[0].totalData
            console.log(this.filterEventData)
        })
    }
}
selectFilter(value){
  if(value){
    this.isShowFilter = true
        this._dashboad.filterEvent({type:'Events',reminders:true,caseId:this.caseData.caseReference})
        .subscribe(res => {
             this.refresh.next();
            this.filterEventData = res.data.findListData
            console.log(this.filterEventData)
      })
  }
  else{
    this.isShowFilter = false 
  }
 
}
AddEvent(){
  this.isViewEvent = false
  const state = this.isViewEvent ? 'disable' : 'enable';
  this.addEventForm.controls['eventName'][state]();
  this.addEventForm.controls['eventLocation'][state]();
  this.addEventForm.controls['eventDescription'][state]();
  this.addEventForm.controls['clientContact'][state]();
  this.addEventForm.controls['counterpartyContact'][state]();
  this.addEventForm.controls['additionalNotes'][state]();
  this.addEventForm.controls['assignedTo'][state]();
  this.addEventForm.controls['repeat'][state]();
  this.addEventForm.controls['reminders'][state]();
  this.addEventForm.controls['eventDateTime'][state]();
  this.addEventForm.controls['times'][state]();
  this.addEventForm.controls['priorTime'][state]();
  this.addEventForm.reset()
  this.addEventForm.patchValue({
    caseNumber:this.caseData.caseNumber,
    caseReference:this.caseData.caseReference,
    CourtChamberNumber:this.caseData.courtChamber
   })
   $("#modal-view-event-add").modal(); 
}
submitForm(){ 
  this.isSubmitted = true
  if(this.addEventForm.status == 'VALID'){
    this.events = []
    this.TIME = this.getTime(this.addEventForm.value.times,"",true)
    this.isViewEvent = false
    const state = this.isViewEvent ? 'disable' : 'enable';
      this.addEventForm.controls['caseNumber'][state]();
      this.addEventForm.controls['caseReference'][state]();
      this.addEventForm.controls['CourtChamberNumber'][state]();
    let obj = {
      eventName:  this.addEventForm.value.eventName,
      eventLocation:  this.addEventForm.value.eventLocation,
      eventDescription: this.addEventForm.value.eventDescription,
      caseNumber:  this.addEventForm.value.caseNumber,
      caseReference:  this.addEventForm.value.caseReference,
      CourtChamberNumber:  this.addEventForm.value.CourtChamberNumber,
      clientContact:  this.clientid,
      counterpartyContact: this.CounterPartyId,
      additionalNotes:  this.addEventForm.value.additionalNotes,
      assignedTo:  this.LawyerId,
      repeat: this.addEventForm.value.repeat,
      reminders:  this.addEventForm.value.reminders,
      eventDateTime: this.formatDate(this.addEventForm.value.eventDateTime,"",true) ,
    //  times: this.getTime(this.addEventForm.value.times,"",true),
       times: this.addEventForm.value.times,
      timezone: this.currentTimeZone,
      priorTime: this.priorTime
    }
  console.log(obj)
  this._dashboad.addEvent(obj).subscribe(res => {
    this.loadCalendarEvents()
       $("#modal-view-event-add").modal("hide");
     this.isViewEvent = false
     this.isEdit = false   
     this.isSubmitted = false
   
     this.ngAfterViewInit()
    //  window.location.reload()
       })
  }

}
CancelEvent(){
  this.isViewEvent = false
  this.isEdit = false   
  this.isSubmitted = false
  $("#modal-view-event-add").modal("hide");
  $("#modal-view-event-view").modal("hide");
}
deleteEvent(){
  this._dashboad.deleteEvent({eventId:this.eventDetail._id}).subscribe(res => {
     console.log(res)
     $("#modal-view-event-add").modal("hide");
     this.isViewEvent = false
     this.isEdit = false   
     this.loadCalendarEvents()
     })
 }
 clickEdit(){

  this.LawyerId = this.eventDetail.assignedTo._id
    if(this.eventDetail.clientContact){
     this.clientid = this.eventDetail.clientContact._id
    }
   if(this.eventDetail.counterpartyContact){
    this.CounterPartyId = this.eventDetail.counterpartyContact._id
   }
 
  this.priorTime =this.eventDetail.priorTime
         this.editEventForm.patchValue({
          eventName:  this.eventDetail.eventName,
          eventDescription: this.eventDetail.eventDescription,
          eventLocation: this.eventDetail.eventLocation,
          caseNumber: this.eventDetail.caseNumber,
          caseReference: this.eventDetail.caseReference,
          CourtChamberNumber: this.eventDetail.CourtChamberNumber,
          additionalNotes: this.eventDetail.additionalNotes,
          repeat: this.eventDetail.repeat,
          priorTime: this.eventDetail.priorTime,
          reminders: this.eventDetail.reminders,
          eventDateTime:this.formatDate(this.eventDetail.eventDateTime,this.eventDetail.timezone) ,
          times: this.getTime(this.eventDetail.eventDateTime,this.eventDetail.timezone),
          assignedTo:this.eventDetail.assignedTo.fullName+'-'+this.eventDetail.assignedTo.userId,
          clientContact:this.eventDetail.clientContact? ((this.eventDetail.clientContact.companyName ? this.eventDetail.clientContact.companyName:
                  this.eventDetail.clientContact.personName)) : '',
          counterpartyContact:this.eventDetail.counterpartyContact ?  ((this.eventDetail.counterpartyContact.companyName ? this.eventDetail.counterpartyContact.companyName:
                this.eventDetail.counterpartyContact.personName)): '',
          })
         this.isEdit = true
     }
 backToEdit(){
         this.isEdit = false   
 }
 convertTZ(date, tzString) {
  var timeZ = moment.tz(date,tzString);
  return timeZ.format("L")
}


convertTZTime(date, tzString) {
  // console.log(date, tzString)
  var timeZ = moment.tz(date,tzString);
  return timeZ.format("LT")
}


formatDate(date,timezone="",add = false) {
  if(!add && timezone != this.currentTimeZone) {
date = this.convertTZ(date,this.currentTimeZone)
  }
      
     
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
        
      return [year, month, day].join('-');
}
  getTime(str,timezone="",add = false){
    console.log(add)
    console.log(timezone)
    console.log(this.currentTimeZone)
    if(!add && timezone != this.currentTimeZone) {
    str = this.convertTZTime(str,this.currentTimeZone)
    console.log(str)
    return str
        } else {
          var date = new Date(str);
          var hh = date.getHours();
          var mm = date.getMinutes();
        
          var HH = hh < 10 ? ('0'+hh) : hh; 
          var MM = mm < 10 ? ('0'+mm ): mm;
      
          var curr_time = HH+':'+MM;
          console.log(curr_time)
          return curr_time;
        }
  
  }
convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
dayClicked({ date, events }: { date: Date; events}): void {
 
      if(events.length !=0){
         if(events.length  == 1){
                this.isViewEvent = true
                this.isShowFilter = false 
                const state = this.isViewEvent ? 'disable' : 'enable';
                this.addEventForm.controls['eventName'][state]();
                this.addEventForm.controls['eventLocation'][state]();
                this.addEventForm.controls['eventDescription'][state]();
                this.addEventForm.controls['caseNumber'][state]();
                this.addEventForm.controls['caseReference'][state]();
                this.addEventForm.controls['CourtChamberNumber'][state]();
                this.addEventForm.controls['clientContact'][state]();
                this.addEventForm.controls['counterpartyContact'][state]();
                this.addEventForm.controls['additionalNotes'][state]();
                this.addEventForm.controls['assignedTo'][state]();
                this.addEventForm.controls['repeat'][state]();
                this.addEventForm.controls['reminders'][state]();
                this.addEventForm.controls['eventDateTime'][state]();
                this.addEventForm.controls['times'][state]();
                this.addEventForm.controls['priorTime'][state]();
                this._dashboad.getEventDetail({eventId:events[0]._id}).subscribe(res => {
                  this.eventDetail = res.data
                  console.log(res)
                  this.addEventForm.patchValue({
                      eventName:  res.data.eventName,
                      eventLocation:  res.data.eventLocation,
                      eventDescription: res.data.eventDescription,
                      caseNumber:  res.data.caseNumber,
                      caseReference:  res.data.caseReference,
                      CourtChamberNumber:  res.data.CourtChamberNumber,
                      clientContact:res.data.clientContact ? (res.data.clientContact.personName ? res.data.clientContact.personName : res.data.clientContact.companyName):'',
                      counterpartyContact: res.data.counterpartyContact ? (res.data.counterpartyContact.personName ? res.data.counterpartyContact.personName :res.data.counterpartyContact.companyName):'',
                      additionalNotes:res.data.additionalNotes,
                      assignedTo:res.data.assignedTo ? res.data.assignedTo.fullName+'-'+res.data.assignedTo.userId : '',
                      repeat:res.data.repeat == 'doNotRepeat' ? 'Do Not Repeat': res.data.repeat,
                      reminders:res.data.reminders ? 'On' : 'Off',
                      eventDateTime:this.formatDate(res.data.eventDateTime,res.data.timezone) ,
                      times:this.getTime(res.data.eventDateTime,res.data.timezone),
                      priorTime:res.data.priorTime,
                  })
             })
             $("#modal-view-event-view").modal();
           }
          else{
            let date = this.convert(events[0].start)
            this.isShowFilter = true
            this._dashboad.filterEvent({type:'DateRange',startDate:date,endDate:date,timezone:this.currentTimeZone})
            .subscribe(res => {
                this.filterEventData = res.data[0].totalData
                this.refresh.next();
                console.log(this.filterEventData)
            })
            }
          }
        else{
         console.log("no event")
        }
 }
 showEvent(eventid){
  this.isViewEvent = true
  this.isShowFilter = false 
  const state = this.isViewEvent ? 'disable' : 'enable';
  this.addEventForm.controls['eventName'][state]();
  this.addEventForm.controls['eventLocation'][state]();
  this.addEventForm.controls['eventDescription'][state]();
  this.addEventForm.controls['caseNumber'][state]();
  this.addEventForm.controls['caseReference'][state]();
  this.addEventForm.controls['CourtChamberNumber'][state]();
  this.addEventForm.controls['clientContact'][state]();
  this.addEventForm.controls['counterpartyContact'][state]();
  this.addEventForm.controls['additionalNotes'][state]();
  this.addEventForm.controls['assignedTo'][state]();
  this.addEventForm.controls['repeat'][state]();
  this.addEventForm.controls['reminders'][state]();
  this.addEventForm.controls['eventDateTime'][state]();
  this.addEventForm.controls['times'][state]();
  this.addEventForm.controls['priorTime'][state]();
  this._dashboad.getEventDetail({eventId:eventid}).subscribe(res => {
    this.eventDetail = res.data
    console.log(res)
    this.addEventForm.patchValue({
        eventName:  res.data.eventName,
        eventLocation:  res.data.eventLocation,
        eventDescription: res.data.eventDescription,
        caseNumber:  res.data.caseNumber,
        caseReference:  res.data.caseReference,
        CourtChamberNumber:  res.data.CourtChamberNumber,
        clientContact:res.data.clientContact ? (res.data.clientContact.personName ? res.data.clientContact.personName : res.data.clientContact.companyName):'',
        counterpartyContact: res.data.counterpartyContact ? (res.data.counterpartyContact.personName ? res.data.counterpartyContact.personName :res.data.counterpartyContact.companyName):'',
        additionalNotes:res.data.additionalNotes,
        assignedTo:res.data.assignedTo ? res.data.assignedTo.fullName+'-'+res.data.assignedTo.userId : '',
        repeat:res.data.repeat == 'doNotRepeat' ? 'Do Not Repeat': res.data.repeat,
        reminders:res.data.reminders ? 'On' : 'Off',
        eventDateTime:this.formatDate(res.data.eventDateTime,res.data.timezone) ,
        times: this.getTime(res.data.eventDateTime,res.data.timezone),
        priorTime:res.data.priorTime,
    })
})
$("#modal-view-event-view").modal();
 }
loadClientCouterList(){
   this._cases.lawyerList().subscribe(res => {
    res.data.findListData.filter(d=>{
      assignedList[this.loginUserRoleName].filter(a=>{
          if(a == d.role.name) {
            this.lawyerList.push(d)
          }
      })
        if(this.userDetail._id == d._id) {
          this.lawyerList.push(d)
        }
    })
    })
}
onChangeLawyer(value){
  let user = value.split('-')[1]
  let lawyerData = this.lawyerList.find(m => m.userId == user)
 this.LawyerId = lawyerData._id
}
combine(contact) {
    return contact.companyName ? contact.companyName+'-'+contact.phoneNumber : contact.personName+'-'+contact.phoneNumber
 }
combineLawyer(contact) {
    return  contact.fullName+'-'+contact.userId 
 }
buildEventForm(): void {
    this.addEventForm = this.fb.group({
      eventName: [{value:'', disabled: this.isViewEvent},[
        Validators.pattern(/^([a-zA-Z0-9!*@#$[<>{}?&" "()\\-`-+.:;+%,~'/\"]+\s)*[a-zA-Z0-9!@#$[*&{}<>?" "()\\-`-+.+%,;:'/\"]+$/)]],
      eventLocation: [{value:'', disabled: this.isViewEvent},[removeSpaces,
        Validators.pattern(/^([a-zA-Z0-9!*@#$[<>{}?&" "()\\-`-+.:;+%,~'/\"]+\s)*[a-zA-Z0-9!@#$[*&{}<>?" "()\\-`-+.+%,;:'/\"]+$/)]],
      eventDescription: [{value:'', disabled: this.isViewEvent},[removeSpaces]],
      caseNumber: [{value:'', disabled: !this.isViewEvent}],
      caseReference: [{value:'', disabled: !this.isViewEvent}],
      CourtChamberNumber: [{value:'', disabled: !this.isViewEvent}],
      clientContact: [{value:'', disabled: this.isViewEvent}],
      counterpartyContact: [{value:'', disabled: this.isViewEvent}],
      additionalNotes: [{value:'', disabled: this.isViewEvent}],
      assignedTo: [{value:'', disabled: this.isViewEvent},[Validators.required,removeSpaces]],
      repeat: [{value:'', disabled: this.isViewEvent},[Validators.required]],
      reminders: [{value:'', disabled: this.isViewEvent},[Validators.required]],
      eventDateTime: [{value:'', disabled: this.isViewEvent},[Validators.required]],
      times: [{value:'', disabled: this.isViewEvent},[Validators.required,Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]],
      priorTime: [{value:'', disabled: this.isViewEvent}],
    });
  }
buildEventEditForm(): void {
    this.editEventForm = this.fb.group({
      eventName: ['',[ 
        Validators.pattern(/^([a-zA-Z0-9!*@#$[<>{}?&" "()\\-`-+.:;+%,~'/\"]+\s)*[a-zA-Z0-9!@#$[*&{}<>?" "()\\-`-+.+%,;:'/\"]+$/)]],
        eventLocation: ['',removeSpaces],
        eventDescription: ['',[removeSpaces]],
        caseNumber: [{value:'', disabled: !this.isViewEvent},[removeSpaces]],
        caseReference: [{value:'', disabled: !this.isViewEvent},],
        CourtChamberNumber: [{value:'', disabled: !this.isViewEvent},],
        clientContact: [{value:'', disabled: !this.isViewEvent},[removeSpaces]],
        counterpartyContact: [{value:'', disabled: !this.isViewEvent},[removeSpaces]],
        additionalNotes: [''],
        assignedTo: ['',[,Validators.required,removeSpaces]],
        repeat: ['',[ Validators.required]],
        reminders: ['',[ Validators.required]],
        eventDateTime: [{value:'', disabled: !this.isViewEvent},[ Validators.required]],
        times: [{value:'', disabled: !this.isViewEvent},[ Validators.required ]],
        priorTime: [''],
     
     });
  }
  submitEditForm(){
    console.log(this.editEventForm.sta) 
    this.isSubmitted = true
    if(this.editEventForm.status == 'VALID'){
      let obj = {
        eventName:  this.editEventForm.value.eventName, 
        eventDescription: this.editEventForm.value.eventDescription,
        eventLocation: this.editEventForm.value.eventLocation,
        eventId:this.eventDetail._id,
        assignedTo:  this.LawyerId,
        counterpartyContact:  this.CounterPartyId,
          clientContact:  this.clientid,
          additionalNotes:  this.editEventForm.value.additionalNotes,
          repeat: this.editEventForm.value.repeat,
          reminders:  this.editEventForm.value.reminders,
          priorTime: this.priorTime,
       }
       console.log(obj)
       this._dashboad.editEvent(obj).subscribe(res => {
        $("#modal-view-event-view").modal("hide");
        this.isSubmitted = false
        this.isEdit = false   
        this.isViewEvent = true
        this.refresh.next();
        console.log(res)
       })
    }
 } 

 DownloadPdf(){
  this.EeventId = []
  for(let i = 0; i< this.allEvents.length; i++){
    this.EeventId.push(this.allEvents[i]._id)
  }
  this._dashboad.downloadEventPdf({eventArr: this.EeventId}).subscribe(res => {
      console.log(res)
      window.open(this.baseUrl+'/'+res.data);
   })
} 
DownloadSinglePdf(){
  this.EeventId = []
  this.EeventId.push(this.eventDetail._id)
  this._dashboad.downloadEventPdf({eventArr :this.EeventId}).subscribe(res => {
      console.log(res)
      window.open(this.baseUrl+'/'+res.data);
   })
}  

 downloadSingleEvent(){
  this.CSVdata = []
  let csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Event Detail',
      useBom: true,
      noDownload: false,
       headers: ["Event Name","Event Location", "Event Date", "Case NUmber",
       "Case Reference","Court Chamber", "Client Name", "CounterParty Name",
       "Assigned To","Repeat" ]
    };
   let data =  {test:{
      eventName: this.eventDetail.eventName,
      eventLocation: this.eventDetail.eventLocation,
      eventDateTime: this.eventDetail.eventDateTime,
      caseNumber: this.eventDetail.caseNumber,
      caseReference: this.eventDetail.caseReference,
      CourtChamberNumber: this.eventDetail.CourtChamberNumber,
      clientContact:this.eventDetail.clientContact ? (this.eventDetail.clientContact.personName ? this.eventDetail.clientContact.personName : this.eventDetail.clientContact.companyName) : '',
      counterpartyContact:this.eventDetail.counterpartyContact ? (this.eventDetail.counterpartyContact.personName ? this.eventDetail.counterpartyContact.personName : this.eventDetail.counterpartyContact.companyName):'',
      assignedTo:this.eventDetail.assignedTo ? this.eventDetail.assignedTo.fullName : '',
      repeat: this.eventDetail.repeat,
     }}
     this.CSVdata = Object.values(data)
     console.log(this.CSVdata)
     const csv = new AngularCsv(this.CSVdata,this.eventDetail.eventName,csvOptions); 
} 
downloadAllEvents(){
  this.CSVdata = []
  let csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Event Detail',
      useBom: true,
      noDownload: false,
       headers: ["Event Name","Event Location", "Event Date", "Case NUmber",
       "Case Reference","Court Chamber", "Client Name", "CounterParty Name",
       "Assigned To","Repeat" ]
    };
    console.log(this.allEvents)
    for(let i = 0; i< this.allEvents.length; i++){
        
      this.CSVdata.push({
          ventName: this.allEvents[i].eventName,
          eventLocation: this.allEvents[i].eventLocation,
          eventDateTime: this.allEvents[i].eventDateTime,
          caseNumber: this.allEvents[i].caseNumber,
          caseReference: this.allEvents[i].caseReference,
          CourtChamberNumber: this.allEvents[i].CourtChamberNumber,
          clientContact:this.allEvents[i].clientContact ? (this.allEvents[i].clientContact.personName ? this.allEvents[i].clientContact.personName : this.allEvents[i].clientContact.companyName):'',
          counterpartyContact:this.allEvents[i].counterpartyContact ? (this.allEvents[i].counterpartyContact.personName ? this.allEvents[i].counterpartyContact.personName : this.allEvents[i].counterpartyContact.companyName):'',
          assignedTo: this.allEvents[i].assignedTo ? this.allEvents[i].assignedTo.fullName:'',
          repeat: this.allEvents[i].repeat,
       }) 
      }
      const csv = new AngularCsv(this.CSVdata,'All Events',csvOptions); 
}
closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
 }  
}
