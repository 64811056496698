import { Component, OnInit,ViewChild } from '@angular/core';
import {ContactsService} from '../../../services/contacts.service'
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { SharedService } from '../../../config/sharedServices/shared.service';
import swal from 'sweetalert';
import {noWhitespaceValidator,removeSpaces} from '../../../config/sharedServices/customeValidation'
import $ from 'jquery';
@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})

export class AddContactComponent implements OnInit {
  @ViewChild('myTooltip', {static: false}) myTooltip;
  contactRegisterForm:any
  isSubmitted: boolean;
  isCustomFiled: boolean;
  isCompany:boolean;
  eform:any;
  firmId:any;
  customForm:any
  FIELDNAME:any;
  FIELDTYPE:any
  FOLDERNAME:any
  filesUrl:any= []
  FileName:any
  FileToDelete:any
  constructor(private _contact: ContactsService,private fb: FormBuilder,private router: Router,
    private _shared: SharedService,) { 
        const theUser: any = this._shared.getDataFromStorage('lawyerIt');
        this.firmId = theUser.firm
        this.isSubmitted = false;
        this.isCustomFiled = false;
        this.isCompany = true;
        this.FOLDERNAME  = this._shared.getDataFromStorage('ContactDirName');
        if(this.FOLDERNAME){
          this._contact.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
            this.FOLDERNAME = ''
            this._shared.deleteDataFromStorage('ContactDirName')
          })
        }  
  }
show() {
    this.myTooltip.show();
}
ngOnInit() {
    this.buildContactForm()
    this.setUserCategoryValidators()
    this.addBenificary()
    this.customeFiled()
    this.chkDOBnotGreater()
  }
onChange(){
    this.isCompany =  !this.isCompany
    this.isSubmitted = false
    this.setUserCategoryValidators()
    this.FOLDERNAME = ''
    if(this.FOLDERNAME){
      this._contact.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
        this._shared.deleteDataFromStorage('ContactDirName')
      })
    } 
  }
createFolder(){
  let obj 
  if(this.contactRegisterForm.value.contactType == 'Company'){
      obj = {contactType: 'Company', crNumber:this.contactRegisterForm.value.crNumber}
  }
  else{
    obj = {contactType: 'Person', cprNumber:this.contactRegisterForm.value.cprNumber}
  }
  console.log(obj)
  if(this.FOLDERNAME){

  }
  else{

    this._contact.createContactFolder(obj).subscribe(res => {
      this.FOLDERNAME = res.data
      this._shared.setDataInStorage('ContactDirName',this.FOLDERNAME);
      console.log(res)
      // swal(res.data, "", "success");
     },error => {
      swal("Error!", error.error.message, "error");
     })
  }

}  
 uploadSupportingDocs(files: FileList){
  console.log(files, 'files');
  
    let supportfileToUpload = files.item(0);
    
    if(supportfileToUpload && this.FOLDERNAME){

      let ext = supportfileToUpload.name.split('.').pop();

      if(ext == 'pdf' || ext == 'docx'  ||ext == 'jpeg'||ext == 'doc'||ext == 'jpg'
      ||ext == 'flv' ||ext == 'png' ||ext == 'odt' ||ext == 'xls'||ext == 'xlsx'||ext == 'ods'
      ||ext == 'txt' || ext == 'ppt' || ext == 'pptx'){
      const formData:FormData = new FormData();
        formData.append('files', supportfileToUpload);
        this._contact.uploadContactDocsBrowse(formData,this.FOLDERNAME).subscribe(res => {
          console.log(res)
          swal("File Uploaded Successfully", "", "success");
          for(let i=0; i< res.data.length;i++){
            this.filesUrl.push(res.data[i])
            }
            console.log( this.filesUrl) 
      })
     }
     else{
      alert('not valid extension')
     
    }
    }
  
 }

removeDocs(value){
   console.log(value)
   this.FileName = value
   this.FileToDelete = value.split("/")[5]
   console.log( this.FileToDelete)
 
}  
deleteFile(){
  let obj = {
    listOfFile:[this.FileToDelete],
    folderName:this.FOLDERNAME
   }
    this._contact.removeFileFromFolder(obj).subscribe(res => {
      const index: number = this.filesUrl.indexOf(this.FileName);
            if (index !== -1) {
                this.filesUrl.splice(index, 1);
            } 
            console.log( this.filesUrl)  
     })
}
buildContactForm(): void {
    this.contactRegisterForm = this.fb.group({
      contactType: ['Company',[Validators.required] ],
      companyName: ['',],
      personName: ['',],
      numberOfPoa: [''],
      passportNumber: [''],
      occupation: [''],
      dateOfBirth: ['',],
      sourceOfIncome: [''],
      activity: [''],
      legalForm: ['',],
      mainBranchLocation: ['',],
      PlaceRegDomicile: ['',],
      representativeLawyer: ['',],
      crNumber: [''],
      cprNumber: ['',],
      email: ['',[Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')] ],
      phoneNumber: ['',[Validators.required, Validators.pattern(/^(?:\+[0-9]|[0-9])(?:[0-9])\d{6,13}$/)] ],
      IBAN_Number: ['',  ],
      nationality: ['',[Validators.required,noWhitespaceValidator]],
      address: ['',[Validators.required,noWhitespaceValidator]],
      webAddress: [''],
      companyOwnerName: ['',],
      ultimateBeneficialPerson: this.fb.array([]),
      customField: this.fb.array([]),
    });
  }

  formatDate(date) {
    var d = new Date(date),
       month = '' + (d.getMonth() + 1),
       day = '' + d.getDate(),
       year = d.getFullYear();
   if (month.length < 2) 
       month = '0' + month;
   if (day.length < 2) 
       day = '0' + day;
  return [year, month, day].join('-');
}
registerContact(){
   this.isSubmitted = true
   console.log(this.contactRegisterForm)
   console.log(this.contactRegisterForm.value.dateOfBirth)
   if(this.contactRegisterForm.value.dateOfBirth && new Date(this.contactRegisterForm.value.dateOfBirth).getTime() > new Date().getTime()) {
    alert("Date of Birth should not be greater then current date")
   } else {
    if(this.contactRegisterForm.status == 'VALID'){


      if(this.contactRegisterForm.value.contactType == 'Company'){
        console.log('Company')
        let obj = { firm:this.firmId,
          contactType:this.contactRegisterForm.value.contactType,companyName:this.contactRegisterForm.value.companyName,
          numberOfPoa:this.contactRegisterForm.value.numberOfPoa,passportNumber:this.contactRegisterForm.value.passportNumber,
          occupation:this.contactRegisterForm.value.occupation,dateOfBirth:this.formatDate(this.contactRegisterForm.value.dateOfBirth),
          sourceOfIncome:this.contactRegisterForm.value.sourceOfIncome,activity:this.contactRegisterForm.value.activity,
          legalForm:this.contactRegisterForm.value.legalForm,mainBranchLocation:this.contactRegisterForm.value.mainBranchLocation,
          PlaceRegDomicile:this.contactRegisterForm.value.PlaceRegDomicile,representativeLawyer:this.contactRegisterForm.value.representativeLawyer,
          crNumber:this.contactRegisterForm.value.crNumber,cprNumber:this.contactRegisterForm.value.cprNumber,
          email:this.contactRegisterForm.value.email,phoneNumber:this.contactRegisterForm.value.phoneNumber,
          IBAN_Number:this.contactRegisterForm.value.IBAN_Number,nationality:this.contactRegisterForm.value.nationality,
          address:this.contactRegisterForm.value.address,webAddress:this.contactRegisterForm.value.webAddress,
          companyOwnerName:this.contactRegisterForm.value.companyOwnerName,ultimateBeneficialPerson:this.contactRegisterForm.value.ultimateBeneficialPerson,
          customField:this.contactRegisterForm.value.customField,
          folderName:this.FOLDERNAME, filePath:this.filesUrl
  
           }
           console.log(obj)
           this._contact.registerContact(obj).subscribe(res => {
            console.log(res)
            this._shared.deleteDataFromStorage('ContactDirName')
            swal(res.data, "", "success");
            this.router.navigate(['/lawyerIt/contacts']);
           },error => {
            this._shared.deleteDataFromStorage('ContactDirName')
            swal("Error!", error.error.message, "error");
           })
       }
    else{
      let obj = { firm:this.firmId,
        contactType:this.contactRegisterForm.value.contactType,personName:this.contactRegisterForm.value.personName,
        numberOfPoa:this.contactRegisterForm.value.numberOfPoa,passportNumber:this.contactRegisterForm.value.passportNumber,
        occupation:this.contactRegisterForm.value.occupation,dateOfBirth:this.formatDate(this.contactRegisterForm.value.dateOfBirth),
        sourceOfIncome:this.contactRegisterForm.value.sourceOfIncome,activity:this.contactRegisterForm.value.activity,
        representativeLawyer:this.contactRegisterForm.value.representativeLawyer,
        crNumber:this.contactRegisterForm.value.crNumber,cprNumber:this.contactRegisterForm.value.cprNumber,
        email:this.contactRegisterForm.value.email,phoneNumber:this.contactRegisterForm.value.phoneNumber,
        IBAN_Number:this.contactRegisterForm.value.IBAN_Number,nationality:this.contactRegisterForm.value.nationality,
        address:this.contactRegisterForm.value.address,customField:this.contactRegisterForm.value.customField,
        folderName:this.FOLDERNAME, filePath:this.filesUrl
     
         }
         this._contact.registerContact(obj).subscribe(res => {
           console.log(res)
           swal(res.data, "", "success");
           this._shared.deleteDataFromStorage('ContactDirName')
          this.router.navigate(['/lawyerIt/contacts']);
         },error => {
          this._shared.deleteDataFromStorage('ContactDirName')
          this.FOLDERNAME = ''
          swal("Error!", error.error.message, "error");
         })
         console.log(obj)
     }
  
    }
   }

} 


setUserCategoryValidators(){
  const institutionControl = this.contactRegisterForm.get('contactType');
   if(institutionControl.value === 'Company') {
      this.contactRegisterForm.get('companyName').setValidators([Validators.required]);
      this.contactRegisterForm.get('webAddress').setValidators([Validators.pattern(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/)]);
      this.contactRegisterForm.get('companyOwnerName').setValidators([Validators.required]);
      this.contactRegisterForm.get('crNumber').setValidators([Validators.required]);
      this.contactRegisterForm.get('ultimateBeneficialPerson').setValidators([Validators.required]);
      this.contactRegisterForm.get('cprNumber').setValidators();
      // this.contactRegisterForm.get('IBAN_Number').setValidators([Validators.required]);
      }
      else{
        this.contactRegisterForm.get('personName').setValidators([Validators.required]);
        this.contactRegisterForm.get('companyName').setValidators();
        this.contactRegisterForm.get('crNumber').setValidators();
        this.contactRegisterForm.get('cprNumber').setValidators([Validators.required]);
        this.contactRegisterForm.get('webAddress').setValidators();
        // this.contactRegisterForm.get('IBAN_Number').setValidators();
        this.contactRegisterForm.get('companyOwnerName').setValidators();
        this.contactRegisterForm.get('ultimateBeneficialPerson').setValidators();
      }
      this.contactRegisterForm.get('personName').updateValueAndValidity();
      this.contactRegisterForm.get('companyName').updateValueAndValidity();
      this.contactRegisterForm.get('webAddress').updateValueAndValidity();
      this.contactRegisterForm.get('crNumber').updateValueAndValidity();
      this.contactRegisterForm.get('cprNumber').updateValueAndValidity();
      // this.contactRegisterForm.get('IBAN_Number').updateValueAndValidity();
      this.contactRegisterForm.get('companyOwnerName').updateValueAndValidity();
      this.contactRegisterForm.get('ultimateBeneficialPerson').updateValueAndValidity();
       
 }

// custom field
get customField() : FormArray {
  return this.contactRegisterForm.get("customField") as FormArray
}
customeFiled(): FormGroup {
  this.customForm = this.fb.group({
    fieldName: [this.FIELDNAME,[Validators.required]],
    fieldType: ['String'],
    fieldValue: [''],
  })
  // this.FIELDNAME = "";
  return this.customForm;
}

addCustomField(){
this.isCustomFiled = true;
let customField = this.fb.group({
  fieldName: [this.FIELDNAME,[Validators.required,noWhitespaceValidator]],
  fieldType: ['String'],
  fieldValue: ['',removeSpaces],
})
if(customField.status == 'VALID'){
  this.customField.push(customField);
  this.isCustomFiled = false;
}

}

// benificial person
get ultimateBeneficialPerson() : FormArray {
  return this.contactRegisterForm.get("ultimateBeneficialPerson") as FormArray
}
newBenificary(): FormGroup {
return this.eform = this.fb.group({
  benificaryName: [''],
})
}
addBenificary(){
console.log(this.newBenificary())
this.ultimateBeneficialPerson.push(this.newBenificary());
}
removeBenificary(i:number){
this.ultimateBeneficialPerson.removeAt(i);
}

chkDOBnotGreater(){
  var today;
  today = new Date();
  var dd;
  var mm;
  dd = today.getDate();
  mm = today.getMonth()+1; //January is 0!
  var yyyy = today.getFullYear();
  if(dd<10){
          dd='0'+dd
      } 
      if(mm<10){
          mm='0'+mm
      } 

today = yyyy+'-'+mm+'-'+dd;
document.getElementById("datefield").setAttribute("max", today);
}
}
