import { Component, OnInit,Input} from '@angular/core';
import {CasesService} from '../../../services/cases.service'
import {removeSpaces} from '../../../config/sharedServices/customeValidation'
import swal from "sweetalert";
declare var $: any;
@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
})
export class NotesComponent implements OnInit {
  @Input() id: any;
  @Input() userRole: any;
  newNote: any = "";
  NOTENAME: any = "";
  notesType: any = "";
  publicNotesData: any;
  privateNotesData: any;
  notesId: any;
  isPublicnoteDelete: boolean = false;
  isNewNote: boolean = false;
  isNotesNameEdit: boolean = true;
  element: HTMLElement;
  constructor(private _cases: CasesService) {}

  ngOnInit() {
    if (this.userRole == "Admin" || this.userRole == "Sub-Admin") {
      this.isPublicnoteDelete = true;
    }
    this.showPublicNotes();
    // this.showPrivateNotes();
  }
  showPublicNotes() {
    this.notesType = "Public";
    console.log(this.notesType, 'note type');
    
    this._cases
      .listNotes({ type: "Public", caseId: this.id })
      .subscribe((res) => {
        this.publicNotesData = res.data.findListData;
        console.log(res);
      });
  }
  showPrivateNotes() {
    this.notesType = "Private";
    console.log(this.notesType, 'note type');
    this._cases
      .listNotes({ type: "Private", caseId: this.id })
      .subscribe((res) => {
        this.privateNotesData = res.data.findListData;
        console.log(res);
      });
  }
  newPublicNote() {
    this.isNewNote = true;
    $("#stickyBluenoteModal").modal({ backdrop: "static", keyboard: false });
  }
  newPrivateNote() {
    this.isNewNote = true;
    $("#stickynoteModal").modal({ backdrop: "static", keyboard: false });
  }
  viewNotePublic(value) {
    console.log(value);
    $("#stickyBluenoteModal").modal({ backdrop: "static", keyboard: false });
    this.isNewNote = false;
    this._cases.viewNotesDetail({ notesId: value }).subscribe((res) => {
      this.newNote = res.data.notes;
      this.notesId = res.data._id;
      this.notesType = res.data.notesType;
      this.NOTENAME = res.data.notesName;
      this.isNotesNameEdit = false;
      console.log(res);
    });
  }
  viewNotePrivate(value) {
    console.log(value);
    $("#stickynoteModal").modal({ backdrop: "static", keyboard: false });
    this.isNewNote = false;
    this._cases.viewNotesDetail({ notesId: value }).subscribe((res) => {
      this.newNote = res.data.notes;
      this.notesId = res.data._id;
      this.notesType = res.data.notesType;
      this.NOTENAME = res.data.notesName;
      this.isNotesNameEdit = false;
      console.log(res);
    });
  }
  cancel() {
    this.newNote = "";
    this.notesId = "";
    this.NOTENAME = "";
    this.isNotesNameEdit = true;
  }
  deleteNote() {
    if (this.notesId) {
      if (this.notesType == "Public") {
        this._cases.deleteNotes({ notesId: this.notesId }).subscribe((res) => {
          console.log(res);
          this.showPublicNotes();
        });
        
      } else {
        this._cases.deleteNotes({ notesId: this.notesId }).subscribe((res) => {
          console.log(res);
          this.showPrivateNotes();
        });
      }
      
    }
    
    this.newNote = "";
    this.notesId = "";
    this.NOTENAME = "";
    this.isNotesNameEdit = true;
  }
  createNote() {
    // console.log(typeof noteType, 'note type');
    
    if (!this.isNewNote) {
      console.log(this.isNotesNameEdit, 'edit');
      
      if(this.newNote.trim() !== '' || this.NOTENAME.trim() !== '') {
      let obj = {
        notesType: this.notesType,
        notes: this.newNote,
        notesId: this.notesId,
        notesName: this.NOTENAME
      }
      if(this.notesType === 'Public') {
        this._cases.editNotes(obj).subscribe((res)=> {
          console.log(res, 'res');
          swal(res.data, "", "success");
        });
        this.newNote = "";
        this.notesId = "";
        this.NOTENAME = "";
        this.isNotesNameEdit = true;
      }
      else {
        this._cases.editNotes(obj).subscribe((res)=> {
          console.log(res, 'res');
          swal(res.data, "", "success");
        });
        this.newNote = "";
        this.notesId = "";
        this.NOTENAME = "";
        this.isNotesNameEdit = true;
      }
      }
    }
    else {
      console.log(this.isNotesNameEdit, 'edit');
      if (this.newNote.trim() !== '' || this.NOTENAME.trim() !== '') {
        let obj = {
          notesType: this.notesType,
          notes: this.newNote,
          caseId: this.id,
          notesName: this.NOTENAME,
        };
        if (this.notesType === "Public") {
          this._cases.addNotes(obj).subscribe((res) => {
            this.showPublicNotes();
            swal(res.data, "", "success");
            // this.newNote = "";
            // this.notesId = "";
            // this.NOTENAME = "";
            // this.isNotesNameEdit = true;
          }, err => {
            console.log(err, 'err');
            if(err.error.data.errors.notesName.path === 'notesName')
              swal("Error!", "Please add subject for the note")
          });
        } else {
          this._cases.addNotes(obj).subscribe((res) => {
            this.showPrivateNotes();
            swal(res.data, "", "success");
            // this.newNote = "";
            // this.notesId = "";
            // this.NOTENAME = "";
            // this.isNotesNameEdit = true;
          }, err => {
            console.log(err, 'err');
            if(err.error.data.errors.notesName.path === 'notesName')
              swal("Error!", "Please add subject for the note")
          });
        }
      }
      else {
        swal("Error!", "Please Fill Both Fields");
        // this.newNote = "";
        // this.notesId = "";
        // this.NOTENAME = "";
        // this.isNotesNameEdit = true;
      }
      this.newNote = "";
      this.notesId = "";
      this.NOTENAME = "";
      this.isNotesNameEdit = true;
      
    }
    // if (this.notesId) {
    //   if (this.newNote) {
    //     if (this.newNote.trim() != "" || this.NOTENAME.trim() != "") {
    //       let obj = {
    //         notesType: this.notesType,
    //         notes: this.newNote,
    //         notesId: this.notesId,
    //         notesName: this.NOTENAME,
    //       };
    //       console.log(obj);
          
    //       this._cases.editNotes(obj).subscribe((res) => {
    //         console.log(res);
    //       });
    //     }
    //   }
    // } else {
    //   if (this.NOTENAME) {
    //     if (this.newNote.trim() != "" || this.NOTENAME.trim() != "") {
    //       let obj = {
    //         notesType: this.notesType,
    //         notes: this.newNote,
    //         caseId: this.id,
    //         notesName: this.NOTENAME,
    //       };
    //       this._cases.addNotes(obj).subscribe((res) => {
    //         console.log(res);
    //         if (this.notesType == "Public") {
    //           this.showPublicNotes();
    //         } else {
    //           this.showPrivateNotes();
    //         }
    //         swal(res.data, "", "success");
    //       });
          
    //     }
    //     else {
    //      if (this.notesType == "Public") {
    //       this.showPublicNotes();
    //     } else {
    //       this.showPrivateNotes();
    //     }
    //       swal("Error!", 'please add note subject')
    //     }
    //   } 
    //   else
    //   {
    //     console.log("jkkj");
        
    //     swal("Error!", "Please Fill Both Fields");
        
    //   }
    // }
    
    // this.newNote = "";
    // this.notesId = "";
    // this.NOTENAME = "";
    // this.isNotesNameEdit = true;
  }
}
