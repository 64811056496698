import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import * as Chart from 'chart.js'
@Component({
  selector: 'app-attorney-pie-chart',
  templateUrl: './attorney-pie-chart.component.html',
  styleUrls: ['./attorney-pie-chart.component.scss']
})
export class AttorneyPieChartComponent implements OnInit {
  paidAttorneyFee:any
  pendingAttorneyFee:any
  totalAttorneyFee:any
  paidAttorneyPercentage:any 
  pendingAttorneyPercentage:any
  AttorneycaseStutus:any = ''
  AttorneycaseSubStatus:any =''
  AttorneyTransactionType:any = ''
  constructor(private _dashboad:DashboardService) { }
  ngOnInit() {
    this.loadAttorneyStatics()
  }
  loadAttorneyStatics(){
    this._dashboad.listAttorneyStatics().subscribe(res => {
       if(res.receivedAmount.length != 0){
         this.totalAttorneyFee = res.receivedAmount[0].totalAttorneyFees
         this.paidAttorneyFee = res.receivedAmount[0].totalReceivedFee
         this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee

        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
      }
      else{
        this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
      }
  
     
    })
  }
  filterAttorneybyTransactionType(value){
    this.AttorneyTransactionType = value
    if(this.AttorneycaseStutus && !this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseStatus:this.AttorneycaseStutus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })

    }
  
    if(this.AttorneycaseSubStatus && !this.AttorneycaseStutus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseSubStatus:this.AttorneycaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(!this.AttorneycaseSubStatus && !this.AttorneycaseStutus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(this.AttorneycaseStutus && this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseStatus:this.AttorneycaseStutus,caseSubStatus:this.AttorneycaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.AttorneycaseStutus && !this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseStatus:this.AttorneycaseStutus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.AttorneycaseStutus && this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseSubStatus:this.AttorneycaseSubStatus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.AttorneycaseStutus && this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseStatus:this.AttorneycaseStutus,caseSubStatus:this.AttorneycaseSubStatus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.AttorneycaseStutus && !this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this.loadAttorneyStatics()
      this.ngAfterViewInit()
    }
  }
  filterAttorneybyStatus(value){
    this.AttorneycaseStutus = value
    if(this.AttorneycaseStutus && !this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseStatus:this.AttorneycaseStutus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })

    }
  
    if(this.AttorneycaseSubStatus && !this.AttorneycaseStutus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseSubStatus:this.AttorneycaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(!this.AttorneycaseSubStatus && !this.AttorneycaseStutus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(this.AttorneycaseStutus && this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseStatus:this.AttorneycaseStutus,caseSubStatus:this.AttorneycaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.AttorneycaseStutus && !this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseStatus:this.AttorneycaseStutus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.AttorneycaseStutus && this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseSubStatus:this.AttorneycaseSubStatus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.AttorneycaseStutus && this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseStatus:this.AttorneycaseStutus,caseSubStatus:this.AttorneycaseSubStatus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.AttorneycaseStutus && !this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this.loadAttorneyStatics()
      this.ngAfterViewInit()
    }
   
  }

  filterAttorneybySubStatus(value){
    this.AttorneycaseSubStatus = value
    if(this.AttorneycaseStutus && !this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseStatus:this.AttorneycaseStutus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })

    }
  
    if(this.AttorneycaseSubStatus && !this.AttorneycaseStutus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseSubStatus:this.AttorneycaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(!this.AttorneycaseSubStatus && !this.AttorneycaseStutus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(this.AttorneycaseStutus && this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStatics({caseStatus:this.AttorneycaseStutus,caseSubStatus:this.AttorneycaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.AttorneycaseStutus && !this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseStatus:this.AttorneycaseStutus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.AttorneycaseStutus && this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseSubStatus:this.AttorneycaseSubStatus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.AttorneycaseStutus && this.AttorneycaseSubStatus && this.AttorneyTransactionType){
      this._dashboad.filterAttorneyStaticsPayment({caseStatus:this.AttorneycaseStutus,caseSubStatus:this.AttorneycaseSubStatus},this.AttorneyTransactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalAttorneyFee = res.data[0].totalAttorneyFees
          this.paidAttorneyFee = res.data[0].totalReceivedFee
          this.pendingAttorneyFee = this.totalAttorneyFee - this.paidAttorneyFee
        this.paidAttorneyPercentage = ((this.paidAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        this.pendingAttorneyPercentage = ((this.pendingAttorneyFee / this.totalAttorneyFee)*100).toFixed()
        }
        else{
          this.totalAttorneyFee = 0
        this.paidAttorneyFee = 0
        this.pendingAttorneyFee = 0
        this.paidAttorneyPercentage = 0
        this.pendingAttorneyPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.AttorneycaseStutus && !this.AttorneycaseSubStatus && !this.AttorneyTransactionType){
      this.loadAttorneyStatics()
      this.ngAfterViewInit()
    }   
  }

  ngAfterViewInit() {
   
    setTimeout(() => {
     
      Chart.pluginService.register({
        beforeDraw: function(chart) {
            if (chart.config.options.elements.center) {
                // Get ctx from string
                var ctx = chart.chart.ctx;
    
                // Get options from the center object in options
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || "Arial";
                var txt = centerConfig.text;
                var color = centerConfig.color || "#000";
                var maxFontSize = centerConfig.maxFontSize || 75;
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;
    
                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                var stringWidth = ctx.measureText(txt).width;
                var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
    
                // Find out how much the font can grow in width.
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = chart.innerRadius * 2;
    
                // Pick a new font size so it will not be larger than the height of label.
                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                var minFontSize = centerConfig.minFontSize;
                var lineHeight = centerConfig.lineHeight || 25;
                var wrapText = false;
    
                if (minFontSize === undefined) {
                    minFontSize = 20;
                }
    
                if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                }
    
                // Set font settings to draw it correctly.
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                ctx.font = fontSizeToUse + "px " + fontStyle;
                ctx.fillStyle = color;
    
                if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                }
    
                var words = txt.split(" ");
                var line = "";
                var lines = [];
    
                // Break words up into multiple lines if necessary
                for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + " ";
                    var metrics = ctx.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                        lines.push(line);
                        line = words[n] + " ";
                    } else {
                        line = testLine;
                    }
                }
    
                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;
    
                for (var n = 0; n < lines.length; n++) {
                    ctx.fillText(lines[n], centerX, centerY);
                    centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
            }
        },
    });
      var ctx = document.getElementById("attorneyDonutchart");
      
      var myChart = new Chart(ctx, {
          type: "doughnut",
          data: {
              labels: [
                  "Total Collection",
                  "Pending Collection",
                  "Paid Collection",
                
              ],
              datasets: [{
                  label: "Total Attorney Fees",
                  data: [100, this.pendingAttorneyPercentage,this.paidAttorneyPercentage],
                  backgroundColor: ["#55D8FE", "#FF8373", "#FFDA83"],
                  borderColor: ["#55D8FE", "#FF8373", "#FFDA83"],
                  borderWidth: 1,
              }, ],
          },
          options: {
              cutoutPercentage: 60,
              elements: {
                  center: {
                      text: this.totalAttorneyFee+ " Total",
                      color: "#4D4F5C",
                      fontStyle: "Roboto-Light",
                      FontSize: 19,
                      sidePadding: 50,
                  },
              },
              rotation: 10.5,
              responsive: true,
              legend: {
                  display: true,
                  position: "right",
                  labels: {
                      fontColor: "#43425D",
                      usePointStyle: true,
                      fontSize: 16,
                      padding: 25,
                  },
              },
          },
      });
      },2000)
  }
}
