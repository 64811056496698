import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth.service'
import { UserService } from '../../../services/user.service'
import { CasesService } from '../../../services/cases.service'
import { ContactsService } from '../../../services/contacts.service'
import { DocumentUtilitiesService } from '../../../services/document-utilities.service'
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.scss']
})
export class InnerHeaderComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() seachresponce = new EventEmitter<any>();
  @Output() isDashboard = new EventEmitter<boolean>();
  @Output() searchResultType = new EventEmitter<any>();
  @Input() isShowSearch: boolean
  loginUser: any = ''
  roleData: any;
  searchKey: any = ''
  searchType: any = ''
  url: any;
  
  foterType: any = [{ value: '', key: 'Filter Search' }, { value: 'Cases', key: 'Cases' }, { value: 'Contacts', key: 'Contacts' }, { value: 'Documents', key: 'Documents' }]
  nCount: any;
  nList: any;
  constructor(private authService: AuthService, private userService: UserService, private _cases: CasesService,
    private _contact: ContactsService, private document: DocumentUtilitiesService, private dashboard: DashboardService) { }

  ngOnInit() {
    this.authService.getUser().subscribe(res => {
      // console.log(res)
      if (res) {
        this.loginUser = res
        console.log(this.loginUser, 'login user');
        
        this.roleData = res
        this.newItemEvent.emit(this.roleData);
        // console.log(this.url)
        this.url = environment.baseUrl + this.loginUser.profilePic;
        console.log(this.url, 'url');
        
      }
    })
    this.authService.setUser();
    this.dashboard.getNotficationCount().subscribe(res => {
      this.nCount = res.data
    })
  }

  onChangeFilter(value) {
    this.searchType = value
    console.log(this.searchType)
  }
  searchData() {
    if (this.searchKey) {
      if (this.searchKey.trim() != '') {
        console.log(this.searchType)
        if (this.searchType == '') {
          this._cases.globalSearch({ searchKey: this.searchKey }).subscribe(res => {
            console.log(res)
            this.isDashboard.emit(false);
            this.searchResultType.emit('');
            this.seachresponce.emit(res);
          })
        }
        if (this.searchType == 'Cases') {
          this._cases.searchCases({ searchKey: this.searchKey }).subscribe(res => {
            console.log(res)
            this.isDashboard.emit(false);
            this.searchResultType.emit('Cases');
            this.seachresponce.emit(res.data.findListData);
          })
        }
        if (this.searchType == 'Contacts') {
          this._contact.searchContactfordashboard({ searchKey: this.searchKey }).subscribe(res => {
            console.log(res)
            this.isDashboard.emit(false);
            this.searchResultType.emit('Contacts');
            this.seachresponce.emit(res.data.findListData);
          })
        }
        if (this.searchType == 'Documents') {
          this.document.searchFile({ fileName: this.searchKey }).subscribe(res => {
            console.log(res)
            this.isDashboard.emit(false);
            this.searchResultType.emit('Documents');
            this.seachresponce.emit(res.data);
          })
        }
      }

    }



  }
  resetData() {
    this.isDashboard.emit(true);
    this.searchKey = ''
    this.searchType = ''
    // this.foterType= []
  }
  logout() {
    this.userService.logout().subscribe(res => {
      this.authService.signOut()
    })
  }

  notification() {
    this.dashboard.getNotificationList().subscribe(res => {
      this.nList = res.data
    })

    this.dashboard.getNotficationCount().subscribe(res => {
      this.nCount = res.data
    })
    this.dashboard.notificationStatus({ status: true }).subscribe(res => {
      this.nCount = res.data
    })

  }
}
