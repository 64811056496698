import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service'
@Component({
  selector: 'app-user-activity-count',
  templateUrl: './user-activity-count.component.html',
  styleUrls: ['./user-activity-count.component.scss']
})
export class UserActivityCountComponent implements OnInit {
  showActivity:any = 'Record'
  usersListForRecord:any =[]
  pageSize : number;
  pageIndex: number;
  length : number;
  constructor(private userService: UserService) {
    this.pageSize= 4;
    this.pageIndex = 1;
   }

  ngOnInit() {
    this.loadFirmUsersforRecord();   
  }
loadFirmUsersforRecord(){
    this.userService.getFirmUserList(this.pageSize,this.pageIndex -1 ).subscribe(res => {
     this.usersListForRecord = res.data.findListData
    //  console.log( this.usersListForRecord)
     this.length = res.data.coundData.coundData
 })
}
userActivity(value){
this.showActivity = value
}

handlePageChange(event) {
console.log(event)
   this.pageIndex = event;
   this.loadFirmUsersforRecord();       
}
getSNo(z) {
return z + 1 + (this.pageSize * (this.pageIndex -1))
}
}
