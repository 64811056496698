// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://devnode.devtechnosys.tech:17342/api/users',
  // apiUrl: 'http://192.168.1.152:3001/api/users',
  // baseUrl:'http://192.168.1.152:3001' 
  // baseUrl: 'https://devnode.devtechnosys.tech:17342'

  // apiUrl: 'http://lawyerit-env-staging.eu-west-1.elasticbeanstalk.com/api/users',
  // baseUrl: 'http://lawyerit-env-staging.eu-west-1.elasticbeanstalk.com'

  // ********************************************************************************************************** //
 
  // Working time urls
  /*baseUrl: "http://localhost:3003",
  apiUrl: "http://localhost:3003/api/users"*/
  apiUrl: 'https://apilawyer.trigma.in/api/users',
  baseUrl: 'https://apilawyer.trigma.in'
  

  // Live Time urls Which was set previously
  //  baseUrl: 'http://lawyerit-env-staging.eu-west-1.elasticbeanstalk.com',
  //  apiUrl: 'http://lawyerit-env-staging.eu-west-1.elasticbeanstalk.com/api/users'
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 