import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { AuthService } from '../../../services/auth.service'
import { UserService } from '../../../services/user.service'
import { DashboardService } from '../../../services/dashboard.service';
import { CasesService } from '../../../services/cases.service'
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, } from 'date-fns';
import { Subject } from 'rxjs';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { FormBuilder, Validators } from '@angular/forms';
import { noWhitespaceValidator, removeDSpaces, removeSpaces } from '../../../config/sharedServices/customeValidation'
declare var $: any;
import { DatePipe } from '@angular/common'
import swal from 'sweetalert';
import { enableRipple } from '@syncfusion/ej2-base';
import moment from 'moment-timezone';
import { environment } from '../../../../environments/environment';

const assignedList = {
  "Tier-3": ["Tier-1", "Tier-2"],
  "Tier-2": ["Tier-1"],
  "Tier-1": [],
  "Sub-Admin": ["Tier-1", "Tier-2", "Tier-3"],
  "Admin": ["Tier-1", "Tier-2", "Tier-3", "Sub-Admin"]
}

enableRipple(true);
@Component({
  selector: 'app-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public watermark: string = 'Select Event time';
  public Datewatermark: string = 'Select Event Date';
  public formatString: string = 'HH:mm';
  public interval: number = 2;
  baseUrl = environment.baseUrl;
  userData
  isShowFilter: boolean
  filterEventData: any
  fromDate: any;
  toDate: any;
  getStarted: boolean
  addEventForm: any
  editEventForm: any
  eventDate: any;
  clientList: any
  lawyerList: any = []
  clientId: any
  counterPartyId: any
  LawyerId: any;
  isEdit: boolean = false
  eventDetail: any
  isViewEvent: boolean
  CSVdata: any = []
  allEvents: any
  caseList: any
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean;
  events: any = [];
  canEventDelete: boolean = false
  isSubmitted: boolean;
  isClientNameError: boolean = false
  isCounterNameError: boolean = false
  isCaseNumberError: boolean = false
  isLawyerNameError: boolean = false
  isCreateEvent: boolean = true
  TIME: any
  currentTimeZone: any
  loginUserRoleName: any
  priorTime: any
  PriorTimeList: any = ['15 minute', '30 minute', '45 minute', '60 minute', '75 minute', '90minute']
  EeventId: any = []

  constructor(private authService: AuthService, private userService: UserService, private fb: FormBuilder,
    private _dashboad: DashboardService, private _cases: CasesService, public datepipe: DatePipe) {
    this.isShowFilter = false
    this.isViewEvent = false
    this.isSubmitted = false;

    this.authService.getUserDetail().subscribe(res => {
      console.log(res.data.role.name)
      this.currentTimeZone = res.data.timeZone
      this.userData = res.data
      this.loginUserRoleName = res.data.role.name
      if (res.data.role.name == 'Admin') {
        this.canEventDelete = true
      }
      if (res.data.role.name == 'Tier-1') {
        this.isCreateEvent = false
        this.refresh.next();
      }
      this.getStarted = res.data.loginFlag
      if (this.getStarted) {
        $("#gTourModal").modal('show');
        this.userService.updateLoginStatus({ userId: res.data._id }).subscribe(res => {
          console.log(res)
        })
      }
    })
  }

  ngOnInit() {
    this.loadEvents()
    this.loadClientCouterList()
    this.loadAllCases()
    this.buildEventForm()
    this.buildEventEditForm()
    var todaysDate = new Date()
    var year = todaysDate.getFullYear();
    var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
    var day = ("0" + todaysDate.getDate()).slice(-2);
    var minDate = (year + "-" + month + "-" + day);
    document.getElementById("datefield").setAttribute("min", minDate)


  }
  //  @HostListener('document:keyup', ['$event'])
  //     handleDeleteKeyboardEvent(event: KeyboardEvent) {

  //       if(event.key === 'Delete' || event.key === 'Backspace')
  //       {
  //         const input = event.target as HTMLInputElement;
  //          this._cases.contactList().subscribe(res => {
  //           this.clientList = []
  //           this.clientList = res.data.findListData
  //            if(event.target["name"] == "econtactno" && event.target['value']) {
  //             this.clientList = this.clientList.filter(cl=>cl._id !== this.counterPartyId)
  //           }
  //           if(event.target["name"] == "epartycontactno" && event.target['value']) {
  //             this.clientList = this.clientList.filter(cl=>cl._id !== this.clientId)
  //           }

  //          })
  //       }
  //   }
  shouldseevalue(lawyer) {
    console.log(lawyer)
    return true
  }

  loadClientCouterList() {
    this.lawyerList = []
    this._cases.contactList().subscribe(res => {
      this.clientList = res.data.findListData
    })
    this._cases.lawyerList().subscribe(res => {
      res.data.findListData.filter(d => {
        assignedList[this.loginUserRoleName].filter(a => {
          if (a == d.role.name) {
            this.lawyerList.push(d)
          }
        })
        if (this.userData._id == d._id) {
          this.lawyerList.push(d)
        }
      })
    })
  }
  loadAllCases() {
    this._cases.casesListForDownload().subscribe(res => {
      this.caseList = res.data.findListData

    })
  }

  loadEvents() {
    this._dashboad.listEvent({ caseId: '' }).subscribe(res => {
      this.allEvents = res.data.findListData
      // console.log( this.allEvents)
      for (let i = 0; i < res.data.findListData.length; i++) {

        if (res.data.findListData[i].timezone == this.currentTimeZone) {

        } else {

          res.data.findListData[i].eventDateTime = this.convertTZ(res.data.findListData[i].eventDateTime, this.currentTimeZone)

        }
        //  console.log(res.data.findListData[i].eventDateTime)
        this.events.push({
          start: startOfDay(new Date(res.data.findListData[i].eventDateTime)),
          _id: res.data.findListData[i]._id, title: res.data.findListData[i].eventName
        })

      }
      // this.refresh.next();
      console.log(this.events)
    })
  }

  selectFromDate(value) {
    this.fromDate = value
    if (this.fromDate && this.toDate) {
      this.isShowFilter = true
      this._dashboad.filterEvent({ type: 'DateRange', startDate: this.fromDate, endDate: this.toDate, timezone: this.currentTimeZone })
        .subscribe(res => {
          this.filterEventData = res.data[0].totalData
          console.log(res)
        })
    }

  }
  selectToDate(value) {
    this.toDate = value
    if (this.fromDate && this.toDate) {
      this.isShowFilter = true
      let timezone = this.currentTimeZone
      this._dashboad.filterEvent({ type: 'DateRange', startDate: this.fromDate, endDate: this.toDate, timezone: this.currentTimeZone })
        .subscribe(res => {
          this.refresh.next();
          this.filterEventData = res.data[0].totalData
          console.log(this.filterEventData)
        })
    }
  }
  selectFilter(value) {
    if (value) {
      this.isShowFilter = true
      this._dashboad.filterEvent({ type: 'Events', reminders: true })
        .subscribe(res => {
          this.refresh.next();
          this.filterEventData = res.data.findListData
          console.log(this.filterEventData)
        })
    }
    else {
      this.isShowFilter = false
    }

  }
  closeFilterEvents() {
    this.fromDate = ''
    this.toDate = ''
    this.isShowFilter = false
    //  window.location.reload()
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  convertTZ(date, tzString) {
    var timeZ = moment.tz(date, tzString);
    return timeZ.format("L")
  }


  convertTZTime(date, tzString) {
    var timeZ = moment.tz(date, tzString);
    return timeZ.format("LT")
  }


  formatDate(date, timezone = "", add = false) {
    if (!add && timezone != this.currentTimeZone) {
      date = this.convertTZ(date, this.currentTimeZone)
    }


    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    console.log([year, month, day].join('-'))
    return [year, month, day].join('-');
  }
  getTime(str, timezone = "", add = false) {
    if (!add && timezone != this.currentTimeZone) {
      str = this.convertTZTime(str, this.currentTimeZone)
      return str
    } else {
      var date = new Date(str);
      var hh = date.getHours();
      var mm = date.getMinutes();

      var HH = hh < 10 ? ('0' + hh) : hh;
      var MM = mm < 10 ? ('0' + mm) : mm;

      var curr_time = HH + ':' + MM;
      return curr_time;
    }

  }
  dayClicked({ date, events }: { date: Date; events }): void {
    if (events.length != 0) {
      if (events.length == 1) {

        this.isViewEvent = true
        const state = this.isViewEvent ? 'disable' : 'enable';
        this.addEventForm.controls['eventName'][state]();
        this.addEventForm.controls['eventLocation'][state]();
        this.addEventForm.controls['eventDescription'][state]();
        this.addEventForm.controls['caseNumber'][state]();
        this.addEventForm.controls['caseReference'][state]();
        this.addEventForm.controls['CourtChamberNumber'][state]();
        this.addEventForm.controls['clientContact'][state]();
        this.addEventForm.controls['counterpartyContact'][state]();
        this.addEventForm.controls['additionalNotes'][state]();
        this.addEventForm.controls['assignedTo'][state]();
        this.addEventForm.controls['repeat'][state]();
        this.addEventForm.controls['reminders'][state]();
        this.addEventForm.controls['eventDateTime'][state]();
        this.addEventForm.controls['times'][state]();
        this.addEventForm.controls['priorTime'][state]();
        this._dashboad.getEventDetail({ eventId: events[0]._id }).subscribe(res => {
          this.eventDetail = res.data

          console.log(this.eventDetail)
          console.log(res.data.eventDateTime)
          this.addEventForm.patchValue({
            eventName: res.data.eventName,
            eventLocation: res.data.eventLocation ? res.data.eventLocation : '',
            eventDescription: res.data.eventDescription,
            caseNumber: res.data.caseNumber,
            caseReference: res.data.caseReference,
            CourtChamberNumber: res.data.CourtChamberNumber,
            clientContact: res.data.clientContact ? (res.data.clientContact.personName ? res.data.clientContact.personName + '-' + res.data.clientContact.phoneNumber : res.data.clientContact.companyName + '-' + res.data.clientContact.phoneNumber) : '',
            counterpartyContact: res.data.counterpartyContact ? (res.data.counterpartyContact.personName ? res.data.counterpartyContact.personName + '-' + res.data.counterpartyContact.phoneNumber : res.data.counterpartyContact.companyName + '-' + res.data.counterpartyContact.phoneNumber) : '',
            additionalNotes: res.data.additionalNotes,
            assignedTo: res.data.assignedTo ? res.data.assignedTo.fullName + '-' + res.data.assignedTo.userId : '',
            repeat: res.data.repeat == 'doNotRepeat' ? 'Do Not Repeat' : res.data.repeat,
            reminders: res.data.reminders ? 'On' : 'Off',
            eventDateTime: this.formatDate(res.data.eventDateTime, res.data.timezone),
            times: this.getTime(res.data.eventDateTime, res.data.timezone),
            priorTime: res.data.priorTime,
          })
        })
        $("#modal-view-event-view").modal();
      }
      else {
        console.log(events[0].start)
        let date = this.convert(events[0].start)
        //  let offset = this.convertTZ(new Date(),this.currentTimeZone).getTimezoneOffset()
        //   console.log(date) 
        // let timezone = this.currentTimeZone
        this.isShowFilter = true
        this._dashboad.filterEvent({ type: 'DateRange', startDate: date, endDate: date, timezone: this.currentTimeZone })
          .subscribe(res => {
            this.filterEventData = res.data[0].totalData
            this.refresh.next();
            console.log(this.filterEventData)
          })
      }

    }
    else {
      console.log("no event")
    }

  }
  showEvent(eventid) {
    this.isViewEvent = true
    const state = this.isViewEvent ? 'disable' : 'enable';
    this.addEventForm.controls['eventName'][state]();
    this.addEventForm.controls['eventLocation'][state]();
    this.addEventForm.controls['eventDescription'][state]();
    this.addEventForm.controls['caseNumber'][state]();
    this.addEventForm.controls['caseReference'][state]();
    this.addEventForm.controls['CourtChamberNumber'][state]();
    this.addEventForm.controls['clientContact'][state]();
    this.addEventForm.controls['counterpartyContact'][state]();
    this.addEventForm.controls['additionalNotes'][state]();
    this.addEventForm.controls['assignedTo'][state]();
    this.addEventForm.controls['repeat'][state]();
    this.addEventForm.controls['reminders'][state]();
    this.addEventForm.controls['eventDateTime'][state]();
    this.addEventForm.controls['times'][state]();
    this.addEventForm.controls['priorTime'][state]();
    this._dashboad.getEventDetail({ eventId: eventid }).subscribe(res => {
      this.eventDetail = res.data

      this.addEventForm.patchValue({
        eventName: res.data.eventName,
        eventLocation: res.data.eventLocation ? res.data.eventLocation : '',
        eventDescription: res.data.eventDescription,
        caseNumber: res.data.caseNumber,
        caseReference: res.data.caseReference,
        CourtChamberNumber: res.data.CourtChamberNumber,
        clientContact: res.data.clientContact ? (res.data.clientContact.personName ? res.data.clientContact.personName + '-' + res.data.clientContact.phoneNumber : res.data.clientContact.companyName + '-' + res.data.clientContact.phoneNumber) : '',
        counterpartyContact: res.data.counterpartyContact ? (res.data.counterpartyContact.personName ? res.data.counterpartyContact.personName + '-' + res.data.counterpartyContact.phoneNumber : res.data.counterpartyContact.companyName + '-' + res.data.counterpartyContact.phoneNumber) : '',
        additionalNotes: res.data.additionalNotes,
        assignedTo: res.data.assignedTo ? res.data.assignedTo.fullName + '-' + res.data.assignedTo.userId : '',
        repeat: res.data.repeat == 'doNotRepeat' ? 'Do Not Repeat' : res.data.repeat,
        reminders: res.data.reminders ? 'On' : 'Off',
        eventDateTime: this.formatDate(res.data.eventDateTime, res.data.timezone),
        times: this.getTime(res.data.eventDateTime, res.data.timezone),
        priorTime: res.data.priorTime,
      })
    })
    $("#modal-view-event-view").modal();
  }


  buildEventForm(): void {
    console.log(this.isViewEvent)
    this.addEventForm = this.fb.group({
      eventName: [{ value: '', disabled: this.isViewEvent }, [Validators.required]],
      eventLocation: [{ value: '', disabled: this.isViewEvent }, [removeSpaces]],
      eventDescription: [{ value: '', disabled: this.isViewEvent }, [removeSpaces]],
      caseNumber: [{ value: '', disabled: this.isViewEvent }, [removeSpaces]],
      caseReference: [{ value: '', disabled: !this.isViewEvent }],
      CourtChamberNumber: [{ value: '', disabled: !this.isViewEvent }],
      clientContact: [{ value: '', disabled: this.isViewEvent }, [removeSpaces]],
      counterpartyContact: [{ value: '', disabled: this.isViewEvent }, [removeSpaces]],
      additionalNotes: [{ value: '', disabled: this.isViewEvent }],
      assignedTo: [{ value: '', disabled: this.isViewEvent }, [, Validators.required, removeSpaces]],
      repeat: [{ value: '', disabled: this.isViewEvent }, [Validators.required]],
      reminders: [{ value: '', disabled: this.isViewEvent }, [Validators.required]],
      eventDateTime: [{ value: '', disabled: this.isViewEvent }, [Validators.required]],
      times: [{ value: '', disabled: this.isViewEvent }, [Validators.required, Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]],
      times2: [{ value: '', disabled: this.isViewEvent }, []],
      priorTime: [{ value: '', disabled: this.isViewEvent }],
    });
  }
  submitForm() {
    console.log(this.addEventForm.value.times)
    console.log(this.getTime(this.addEventForm.value.times, "", true))
    // this.ngAfterViewInit()
    this.isSubmitted = true
    if (this.addEventForm.status == 'VALID' && !this.isLawyerNameError && !this.isCounterNameError
      && !this.isClientNameError && !this.isCaseNumberError) {
      console.log(this.clientId, this.counterPartyId)


      if (this.clientId && this.counterPartyId) {
        if (this.clientId == this.counterPartyId) {
          swal("Error!", "Client and CounterParty can not be same.", "error");
        }
        else {
          this.events = []
          this.isViewEvent = false
          const state = this.isViewEvent ? 'disable' : 'enable';
          this.addEventForm.controls['caseReference'][state]();
          this.addEventForm.controls['CourtChamberNumber'][state]();
          let obj = {
            eventName: this.addEventForm.value.eventName,
            eventLocation: this.addEventForm.value.eventLocation,
            eventDescription: this.addEventForm.value.eventDescription,
            caseNumber: this.addEventForm.value.caseNumber,
            caseReference: this.addEventForm.value.caseReference,
            CourtChamberNumber: this.addEventForm.value.CourtChamberNumber,
            clientContact: this.clientId,
            counterpartyContact: this.counterPartyId,
            additionalNotes: this.addEventForm.value.additionalNotes,
            assignedTo: this.LawyerId,
            repeat: this.addEventForm.value.repeat,
            reminders: this.addEventForm.value.reminders,
            eventDateTime: this.formatDate(this.addEventForm.value.eventDateTime, "", true),
            //   times: this.getTime(this.addEventForm.value.times,"",true),
            times: this.addEventForm.value.times,
            timezone: this.currentTimeZone,
            priorTime: this.priorTime,
          }
          this._dashboad.addEvent(obj).subscribe(res => {
            this.loadEvents()
            $("#modal-view-event-add").modal("hide");
            this.clientId = ""
            this.counterPartyId = ""
            this.isViewEvent = false
            this.isEdit = false
            this.isSubmitted = false
            const state = this.isViewEvent ? 'enable' : 'disable';
            this.addEventForm.controls['caseReference'][state]();
            this.addEventForm.controls['CourtChamberNumber'][state]();
            swal(res.data, "", "success");
            this.ngAfterViewInit()
          }, error => {
            swal("Error!", error.error.message, "error");
          })
        }
      }
      else {
        this.events = []
        this.isViewEvent = false
        const state = this.isViewEvent ? 'disable' : 'enable';
        this.addEventForm.controls['caseReference'][state]();
        this.addEventForm.controls['CourtChamberNumber'][state]();
        let obj = {
          eventName: this.addEventForm.value.eventName,
          eventLocation: this.addEventForm.value.eventLocation,
          eventDescription: this.addEventForm.value.eventDescription,
          caseNumber: this.addEventForm.value.caseNumber,
          caseReference: this.addEventForm.value.caseReference,
          CourtChamberNumber: this.addEventForm.value.CourtChamberNumber,
          clientContact: this.clientId,
          counterpartyContact: this.counterPartyId,
          additionalNotes: this.addEventForm.value.additionalNotes,
          assignedTo: this.LawyerId,
          repeat: this.addEventForm.value.repeat,
          reminders: this.addEventForm.value.reminders,
          eventDateTime: this.formatDate(this.addEventForm.value.eventDateTime, "", true),
          // times: this.getTime(this.addEventForm.value.times,"",true),
          times: this.addEventForm.value.times,
          timezone: this.currentTimeZone,
          priorTime: this.priorTime,
        }

        console.log(obj)
        this._dashboad.addEvent(obj).subscribe(res => {
          this.loadEvents()
          $("#modal-view-event-add").modal("hide");
          this.clientId = ""
          this.counterPartyId = ""
          this.isViewEvent = false
          this.isEdit = false
          this.isSubmitted = false
          const state = this.isViewEvent ? 'enable' : 'disable';
          this.addEventForm.controls['caseReference'][state]();
          this.addEventForm.controls['CourtChamberNumber'][state]();
          swal(res.data, "", "success");
          this.ngAfterViewInit()
        }, error => {
          swal("Error!", error.error.message, "error");
        })
      }
    }

  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  AddEvent() {
    this.isViewEvent = false
    const state = this.isViewEvent ? 'disable' : 'enable';
    this.addEventForm.controls['eventName'][state]();
    this.addEventForm.controls['eventLocation'][state]();
    this.addEventForm.controls['eventDescription'][state]();
    this.addEventForm.controls['caseNumber'][state]();
    this.addEventForm.controls['clientContact'][state]();
    this.addEventForm.controls['counterpartyContact'][state]();
    this.addEventForm.controls['additionalNotes'][state]();
    this.addEventForm.controls['assignedTo'][state]();
    this.addEventForm.controls['repeat'][state]();
    this.addEventForm.controls['reminders'][state]();
    this.addEventForm.controls['eventDateTime'][state]();
    this.addEventForm.controls['times'][state]();
    this.addEventForm.controls['priorTime'][state]();
    this.addEventForm.reset()
    $("#modal-view-event-add").modal();
  }
  CancelEvent() {
    this.isViewEvent = false
    this.isEdit = false
    this.isSubmitted = false
    this.isCaseNumberError = false
    this.isClientNameError = false
    this.isCounterNameError = false
    this.isLawyerNameError = false
    this.clientId = ""
    this.counterPartyId = ""
    $("#modal-view-event-add").modal("hide");

  }
  deleteEvent() {
    this.isViewEvent = false
    this.isEdit = false
    $("#modal-view-event-add").modal("hide");
    $("#deleteModel").modal();

  }

  yesDeleteEvent() {
    this.events = []
    this._dashboad.deleteEvent({ eventId: this.eventDetail._id }).subscribe(res => {
      swal(res.data, "", "success");
      this.isViewEvent = false
      this.isEdit = false
      this.loadEvents()
      this.ngAfterViewInit()
    })
  }
  clickEdit() {
    console.log(this.eventDetail)
    this.LawyerId = this.eventDetail.assignedTo._id
    if (this.eventDetail.clientContact) {
      this.clientId = this.eventDetail.clientContact._id
    }
    if (this.eventDetail.counterpartyContact) {
      this.counterPartyId = this.eventDetail.counterpartyContact._id
    }


    this.priorTime = this.eventDetail.priorTime
    this.editEventForm.patchValue({
      eventName: this.eventDetail.eventName,
      eventDescription: this.eventDetail.eventDescription,
      eventLocation: this.eventDetail.eventLocation,
      caseNumber: this.eventDetail.caseNumber,
      caseReference: this.eventDetail.caseReference,
      CourtChamberNumber: this.eventDetail.CourtChamberNumber,
      additionalNotes: this.eventDetail.additionalNotes,
      repeat: this.eventDetail.repeat,
      priorTime: this.eventDetail.priorTime,
      reminders: this.eventDetail.reminders,
      eventDateTime: this.formatDate(this.eventDetail.eventDateTime, this.eventDetail.timezone),
      times: this.getTime(this.eventDetail.eventDateTime, this.eventDetail.timezone),
      assignedTo: this.eventDetail.assignedTo.fullName + '-' + this.eventDetail.assignedTo.userId,
      clientContact: this.eventDetail.clientContact ? ((this.eventDetail.clientContact.companyName ? this.eventDetail.clientContact.companyName :
        this.eventDetail.clientContact.personName) + '-' + this.eventDetail.clientContact.phoneNumber) : '',
      counterpartyContact: this.eventDetail.counterpartyContact ? ((this.eventDetail.counterpartyContact.companyName ? this.eventDetail.counterpartyContact.companyName :
        this.eventDetail.counterpartyContact.personName) + '-' + this.eventDetail.counterpartyContact.phoneNumber) : '',
    })
    this.isEdit = true
  }
  backToEdit() {
    this.isEdit = false
  }

  buildEventEditForm(): void {
    console.log(this.isViewEvent)
    this.editEventForm = this.fb.group({
      eventName: ['', [Validators.required]],
      eventLocation: ['', [Validators.required]],
      eventDescription: ['', [removeSpaces]],
      caseNumber: [{ value: '', disabled: !this.isViewEvent }, [removeSpaces]],
      caseReference: [{ value: '', disabled: !this.isViewEvent },],
      CourtChamberNumber: [{ value: '', disabled: !this.isViewEvent },],
      clientContact: ['', [removeSpaces]],
      counterpartyContact: ['', [removeSpaces]],
      additionalNotes: [''],
      assignedTo: ['', [, Validators.required, removeSpaces]],
      repeat: ['', [Validators.required]],
      reminders: ['', [Validators.required]],
      eventDateTime: [{ value: '', disabled: !this.isViewEvent }, [Validators.required]],
      times: [{ value: '', disabled: !this.isViewEvent }, [Validators.required]],
      priorTime: [''],
    });
  }
  submitEditForm() {
    console.log(this.editEventForm.value)
    this.isSubmitted = true

    if (this.editEventForm.status == 'VALID' && !this.isLawyerNameError) {
      if (this.clientId == this.counterPartyId) {
        swal("Error!", "Client and CounterParty can not be same.", "error");
      }
      else {
        let obj = {
          eventName: this.editEventForm.value.eventName.trim(),
          eventDescription: this.editEventForm.value.eventDescription,
          eventLocation: this.editEventForm.value.eventLocation,
          eventId: this.eventDetail._id,
          assignedTo: this.LawyerId,
          counterpartyContact: this.counterPartyId,
          clientContact: this.clientId,
          additionalNotes: this.editEventForm.value.additionalNotes,
          repeat: this.editEventForm.value.repeat,
          reminders: this.editEventForm.value.reminders,
          priorTime: this.priorTime,
        }
        console.log(obj)
        this._dashboad.editEvent(obj).subscribe(res => {
          this.isSubmitted = false
          this.isEdit = false
          this.clientId = ""
          this.counterPartyId = ""
          $("#modal-view-event-view").modal("hide");
          this.refresh.next();

          console.log(res)
        })
      }

    }


  }

  selectCaseNumber(value) {
    let caseData = this.caseList.find(m => m.caseNumber == value)
    if (caseData) {
      this.isCaseNumberError = false
      this.addEventForm.patchValue({
        caseReference: caseData.caseReference,
        CourtChamberNumber: caseData.courtChamber
      })
    }
    else {
      this.isCaseNumberError = true
    }

  }
  combine(contact) {
    return contact.companyName ? contact.companyName + '-' + contact.phoneNumber : contact.personName + '-' + contact.phoneNumber
  }
  combineLawyer(contact) {
    return contact.fullName + '-' + contact.userId
  }
  onChangePriorTime(value) {
    this.priorTime = value
  }
  onChangeClint(value) {

    let phone = value.split('-')[1]
    let clientData = this.clientList.find(m => m.phoneNumber == phone)
    if (clientData) {
      this.isClientNameError = false
      this.clientId = clientData._id
      if (this.clientId == this.counterPartyId) {
        swal("Error!", "Client and CounterParty can not be same.", "error");

      }
      // var index = this.clientList.findIndex(x => x._id === clientData._id);
      // console.log(index)
      // if(index > -1){
      //  this.clientList.splice(index,1);
      // }
    }
    else {
      this.isClientNameError = true
    }

  }
  onChangeCounterParty(value) {
    let phone = value.split('-')[1]
    let counterData = this.clientList.find(m => m.phoneNumber == phone)
    if (counterData) {
      this.isCounterNameError = false
      this.counterPartyId = counterData._id
      if (this.clientId == this.counterPartyId) {
        swal("Error!", "Client and CounterParty can not be same.", "error");
      }
      // var index = this.clientList.findIndex(x => x._id === counterData._id);
      // console.log(index)
      // if(index > -1){
      //  this.clientList.splice(index,1);
      // }
    }
    else {
      this.isCounterNameError = true
    }
  }
  onChangeLawyer(value) {
    let user = value.split('-')[1]
    let lawyerData = this.lawyerList.find(m => m.userId == user)
    if (lawyerData) {
      this.isLawyerNameError = false
      this.LawyerId = lawyerData._id
    }
    else {
      this.isLawyerNameError = true
    }
  }

  downloadSingleEvent() {
    this.CSVdata = []
    let csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Event Detail',
      useBom: true,
      noDownload: false,
      headers: ["Event Name", "Event Location", "Event Date", "Case NUmber",
        "Case Reference", "Court Chamber", "Client Name", "CounterParty Name",
        "Assigned To", "Repeat", "Reminder", "Description"]
    };
    let data = {
      test: {
        eventName: this.eventDetail.eventName,
        eventLocation: this.eventDetail.eventLocation,
        eventDateTime: new Date(this.eventDetail.eventDateTime).toLocaleDateString("en-US"),
        caseNumber: this.eventDetail.caseNumber,
        caseReference: this.eventDetail.caseReference,
        CourtChamberNumber: this.eventDetail.CourtChamberNumber,
        clientContact: this.eventDetail.clientContact ? (this.eventDetail.clientContact.personName ? this.eventDetail.clientContact.personName : this.eventDetail.clientContact.companyName) : '',
        counterpartyContact: this.eventDetail.counterpartyContact ? (this.eventDetail.counterpartyContact.personName ? this.eventDetail.counterpartyContact.personName : this.eventDetail.counterpartyContact.companyName) : '',
        assignedTo: this.eventDetail.assignedTo ? this.eventDetail.assignedTo.fullName : '',
        repeat: this.eventDetail.repeat,
        reminders: this.eventDetail.reminders ? 'On' : 'Off',
        eventDescription: this.eventDetail.eventDescription,
      }
    }
    this.CSVdata = Object.values(data)
    console.log(this.CSVdata)
    const csv = new AngularCsv(this.CSVdata, this.eventDetail.eventName, csvOptions);
  }
  downloadAllEvents() {
    this.CSVdata = []
    let csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Event Detail',
      useBom: true,
      noDownload: false,
      headers: ["Event Name", "Event Location", "Event Date", "Case NUmber",
        "Case Reference", "Court Chamber", "Client Name", "CounterParty Name",
        "Assigned To", "Repeat", "Reminder", "Description"]
    };
    console.log(this.allEvents)
    for (let i = 0; i < this.allEvents.length; i++) {

      this.CSVdata.push({
        ventName: this.allEvents[i].eventName,
        eventLocation: this.allEvents[i].eventLocation,
        eventDateTime: new Date(this.allEvents[i].eventDateTime).toLocaleDateString("en-US"),
        caseNumber: this.allEvents[i].caseNumber,
        caseReference: this.allEvents[i].caseReference,
        CourtChamberNumber: this.allEvents[i].CourtChamberNumber,
        clientContact: this.allEvents[i].clientContact ? (this.allEvents[i].clientContact.personName ? this.allEvents[i].clientContact.personName : this.allEvents[i].clientContact.companyName) : '',
        counterpartyContact: this.allEvents[i].counterpartyContact ? (this.allEvents[i].counterpartyContact.personName ? this.allEvents[i].counterpartyContact.personName : this.allEvents[i].counterpartyContact.companyName) : '',
        assignedTo: this.allEvents[i].assignedTo ? this.allEvents[i].assignedTo.fullName : '',
        repeat: this.allEvents[i].repeat,
        reminders: this.allEvents[i].reminders ? 'On' : 'Off',
        eventDescription: this.allEvents[i].eventDescription,
      })
    }
    const csv = new AngularCsv(this.CSVdata, 'All Events', csvOptions);
  }
  DownloadPdf() {
    this.EeventId = []
    for (let i = 0; i < this.allEvents.length; i++) {
      this.EeventId.push(this.allEvents[i]._id)
    }
    this._dashboad.downloadEventPdf({ eventArr: this.EeventId }).subscribe(res => {
      console.log(res)
      window.open(this.baseUrl + '/' + res.data);
    })
  }
  DownloadSinglePdf() {
    this.EeventId = []
    this.EeventId.push(this.eventDetail._id)
    this._dashboad.downloadEventPdf({ eventArr: this.EeventId }).subscribe(res => {
      console.log(res)
      window.open(this.baseUrl + '/' + res.data);
    })
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.refresh.next();
      console.log('Afterview')
      //  $(document).ready(function(){
      //   var c_time=ct(new Date());
      //   var hr=parseInt(c_time.split(':')[0]);
      //   var min=parseInt(c_time.split(':')[1]);
      //   var meridiem=c_time.split(':')[2];
      //   $('.tp-min>span').html(min<10?'0'+min:min);
      //   $('.tp-hr>span').html(hr);
      //   $('.tp-am-pm').html(meridiem);
      //   $('.tp-hr>.tp-up-arrow').click(function(){
      //     hr=parseInt($('.tp-hr>span').html());
      //     hr=(hr==1?12:hr-=1);
      //     console.log(hr)
      //     $('.tp-hr>span').html(hr);
      //   });
      //   $('.tp-min>.tp-up-arrow').click(function(){
      //     min=parseInt($('.tp-min>span').html());
      //     min=(min== 0o0?59:min-=1);
      //     console.log(min)
      //     $('.tp-min>span').html(min<10?'0'+min:min);
      //   });
      //   $('.tp-hr>.tp-down-arrow').click(function(){
      //     hr=parseInt($('.tp-hr>span').html());
      //     hr=(hr==12?1:hr+=1);
      //     console.log(hr)
      //     $('.tp-hr>span').html(hr);
      //   });
      //   $('.tp-min>.tp-down-arrow').click(function(){
      //     min=parseInt($('.tp-min>span').html());
      //     min=(min==59?0o0:min+=1);
      //     $('.tp-min>span').html(min<10?'0'+min:min);
      //   });
      //   $('.tp-am-pm').click(function(){
      //     meridiem=meridiem=='AM'?'PM':'AM';
      //     $('.tp-am-pm').html(meridiem);
      //   });
      //   $('.tp-hr').on('wheel', function(event){
      //     var oEvent = event.originalEvent,
      //         delta  = oEvent.deltaY || oEvent.wheelDelta;
      //     if (delta > 0) {
      //       hr=(hr==12?1:hr+=1);
      //     } else {
      //       hr=(hr==1?12:hr-=1);
      //     }
      //     console.log(hr)
      //     $('.tp-hr>span').html(hr);
      //   });
      //   $('.tp-min').on('wheel', function(event){
      //     var oEvent = event.originalEvent,
      //         delta  = oEvent.deltaY || oEvent.wheelDelta;
      //     if (delta > 0) {
      //       min=(min==59?0o0:min+=1);
      //     } else {
      //       min=(min==0o0?59:min-=1);
      //     }
      //     $('.tp-min>span').html(min<10?'0'+min:min);
      //   });
      //   $(".tp-hr>span").click(function() {
      //     this.focus();
      //     $('.tp-hr>span').html('&nbsp;');
      //     $(this).keyup(function(e) {
      //       console.log(e.keyCode);
      //       $('.tp-hr>span').html();
      //       if(/[0-9]/.test(e.key)) {
      //         var cVal=$('.tp-hr>span').html();
      //         if(cVal=='&nbsp;') {
      //           $('.tp-hr>span').html(e.key);
      //         } else {
      //           if(cVal==0) {
      //             $('.tp-hr>span').append(e.key);
      //             exitHr(this,$(this));
      //           } else if(cVal==1) {
      //             if(/[0-2/]/.test(e.key)) {
      //               $('.tp-hr>span').append(e.key); 
      //               exitHr(this,$(this));
      //             } else {
      //               $('.tp-hr>span').html(e.key);
      //             }
      //           } else {
      //             $('.tp-hr>span').html(e.key);
      //           }
      //         }
      //       } else if((/13|9/.test(e.keyCode))||(/:/.test(e.key))) {
      //         exitHr(this,$(this));
      //       }   
      //     });
      //   });

      //   $(".tp-min>span").click(function() {
      //     this.focus();
      //     $('.tp-min>span').html('&nbsp;');
      //     $(this).keyup(function(e) {
      //       $('.tp-min>span').html();
      //       if(/[0-9]/.test(e.key)) {
      //         var cVal=$('.tp-min>span').html();
      //         if((cVal=='&nbsp;')&&(/[0-5]/.test(e.key))) {
      //           $('.tp-min>span').html(e.key);
      //         } else {
      //           $('.tp-min>span').append(e.key);
      //           exitMin(this,$(this));
      //         }
      //       } else if((/13|9/.test(e.keyCode))||(/:/.test(e.key))) {
      //         exitMin(this,$(this));
      //       }     
      //     });
      //   });
      //   $('.tp-hr>span').blur(function(){
      //     var a=$('.tp-hr>span').html();
      //     if((a=='')||(a=='&nbsp;')) {
      //       var hr=parseInt(ct(new Date()).split(':')[0]);
      //       $('.tp-hr>span').html(hr);
      //     }
      //   });
      //   $('.tp-min>span').blur(function(){
      //     var a=$('.tp-min>span').html();
      //     if((a=='')||(a=='&nbsp;')) {
      //       var min=parseInt(ct(new Date()).split(':')[1]);
      //       $('.tp-min>span').html(min);
      //     }
      //   });
      // });
      // function exitHr(a,b) {
      //   a.blur();
      //   b.off('keyup');
      //   $(".tp-min>span").trigger( "click" );
      // }
      // function exitMin(a,b) {
      //   a.blur();
      //   b.off('keyup');
      // }
      // function ct(date) {
      //   console.log(date)
      //   var hrs = date.getHours();
      //   var mns = date.getMinutes();
      //   var mer = hrs >= 12 ? 'PM' : 'AM';
      //   hrs = hrs % 12;
      //   hrs = hrs ? hrs : 12;
      //   mns = mns < 10 ? '0'+mns : mns;
      //   return (hrs + ':' + mns + ':' + mer);
      // }
    }, 1000)
  }

}
