import { Component, OnInit } from '@angular/core';
import {DocumentUtilitiesService} from '../../../services/document-utilities.service'
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutUsData:any
  constructor(private document: DocumentUtilitiesService) { }

  ngOnInit() {
    this.document.listTermaAboutUs({type:'AboutUs'}).subscribe(res => {
      console.log(res)
      this.aboutUsData = res.data.html
       })
  }
  
}
