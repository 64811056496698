import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import * as Chart from 'chart.js'
@Component({
  selector: 'app-type-chart',
  templateUrl: './type-chart.component.html',
  styleUrls: ['./type-chart.component.scss']
})
export class TypeChartComponent implements OnInit {
    totalCases:any;
    administrativeCases:any;
    civilCases:any;
    commercialCases:any;
    criminalCases:any
    familyCases:any;
    laborCases:any;
    militaryCases:any;
    administrativeCasesPercentage:any;
    civilCasesPercentage:any;
    commercialCasesPercentage:any;
    criminalCasesPercentage:any
    familyCasesPercentage:any;
    laborCasesPercentage:any;
    militaryCasesPercentage:any;
  constructor(private _dashboad:DashboardService) { }

  ngOnInit() {
    this.loadStatics()
  }
  loadStatics(){
    this._dashboad.caseTypeStatistics().subscribe(res => {
    //   console.log(res)
      this.totalCases = res.totalCases
      this.administrativeCases = res.administrativeCases
      this.civilCases = res.civilCases
      this.commercialCases = res.commercialCases
      this.criminalCases = res.criminalCases
      this.familyCases = res.familyCases
      this.laborCases = res.laborCases
      this.militaryCases = res.militaryCases
      if(this.totalCases == 0){
        this.administrativeCasesPercentage = 0
        this.civilCasesPercentage = 0
        this.commercialCasesPercentage = 0
        this.criminalCasesPercentage = 0
        this.familyCasesPercentage = 0
        this.laborCasesPercentage = 0
        this.militaryCasesPercentage = 0
      }else{
        this.administrativeCasesPercentage = ((this.administrativeCases / this.totalCases)*100).toFixed()
        this.civilCasesPercentage = ((this.civilCases / this.totalCases)*100).toFixed()
        this.commercialCasesPercentage = ((this.commercialCases / this.totalCases)*100).toFixed()
        this.criminalCasesPercentage = ((this.criminalCases / this.totalCases)*100).toFixed()
        this.familyCasesPercentage = ((this.familyCases / this.totalCases)*100).toFixed()
        this.laborCasesPercentage = ((this.laborCases / this.totalCases)*100).toFixed()
        this.militaryCasesPercentage = ((this.militaryCases / this.totalCases)*100).toFixed()
      }
      
    
    })
  }
  ngAfterViewInit(){
    setTimeout(() => {
      Chart.pluginService.register({
        beforeDraw: function(chart) {
            if (chart.config.options.elements.center) {
                // Get ctx from string
                var ctx = chart.chart.ctx;
    
                // Get options from the center object in options
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || "Arial";
                var txt = centerConfig.text;
                var color = centerConfig.color || "#000";
                var maxFontSize = centerConfig.maxFontSize || 75;
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;
    
                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                var stringWidth = ctx.measureText(txt).width;
                var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
    
                // Find out how much the font can grow in width.
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = chart.innerRadius * 2;
    
                // Pick a new font size so it will not be larger than the height of label.
                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                var minFontSize = centerConfig.minFontSize;
                var lineHeight = centerConfig.lineHeight || 25;
                var wrapText = false;
    
                if (minFontSize === undefined) {
                    minFontSize = 20;
                }
    
                if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                }
    
                // Set font settings to draw it correctly.
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                ctx.font = fontSizeToUse + "px " + fontStyle;
                ctx.fillStyle = color;
    
                if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                }
    
                var words = txt.split(" ");
                var line = "";
                var lines = [];
    
                // Break words up into multiple lines if necessary
                for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + " ";
                    var metrics = ctx.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                        lines.push(line);
                        line = words[n] + " ";
                    } else {
                        line = testLine;
                    }
                }
    
                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;
    
                for (var n = 0; n < lines.length; n++) {
                    ctx.fillText(lines[n], centerX, centerY);
                    centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
            }
        },
    });
    var ctx = document.getElementById("typecaseBarchart");
    var myChart = new Chart(ctx, {
        type: "bar",
        data: {
            labels: [
                "Total Case",
                "Military Case",
                "Family Case",
                "Commercial Case",
                "Criminal Case",
                "Labor Case",
                "Civil Case",
                "Administrative Case"
            ],
            datasets: [{
                label: "Total Cases",
                data: [this.totalCases, this.militaryCases, this.familyCases, this.commercialCases,
                     this.criminalCases,this.laborCases,this.civilCases,this.administrativeCases],
                backgroundColor: [
                    "#55D8FE",
                    "#FCC48F",
                    "#FF8373",
                    "#FFDA83",
                    "#A3A0FB",
                    "#FF8373",
                    "#FFDA83",
                    "#A3A0FB",
                ],
                borderColor: ["#55D8FE", "#FCC48F", "#FF8373", "#FFDA83", "#A3A0FB","#FF8373", "#FFDA83", "#A3A0FB"],
            }, ],
        },
        options: {
            responsive: true,
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    barThickness: 50,
                }, ],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: true,
                        color: "rgba(234,240,244)",
                    },
                    ticks: {
                        padding: 25,
                        suggestedMax: 100,
                        suggestedMin: -1,
                    },
                }, ],
            },
            legend: {
                display: false,
            },
        },
    });
  },2000)
}
}
