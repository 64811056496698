import { Injectable } from '@angular/core';
import {STORAGE_KEYS} from '../apiKeys/storage-keys-constants';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
   constructor() { }

  setDataInStorage(key, value): any {
    if(key != '' && value != '')
    {
     localStorage.setItem(key, JSON.stringify(value) ) ;
    }
  }

  getDataFromStorage(key): any {
    try {
      if(key != '')
      {
          let data = JSON.parse(localStorage.getItem(key))
        return data;
      }
    } catch (ex) {
      return null;
    }

  }

  deleteDataFromStorage(key)
  {
    if(key != '')
    {
      return localStorage.removeItem(key);
    }
  }

  /**
   * @method isUserHasPermission()
   * @desc used to check whether user has an authentication or not by using seesion(token).
   * @param key :string .
   */
  isUserHasPermission(entity, permission): any {
    const data = this.getDataFromStorage(STORAGE_KEYS.PERMISSION_SESSION);
    let permissionGrant = false;
    if ((data) && (data.permissions) && (data.permissions.length)) {
        data.permissions.forEach(element => {
          if (element.entity === entity && element.actions.includes(permission)) {
            permissionGrant = true;
          }
        });
    }
    return permissionGrant;
  }
  /**
   * @method isUserAuthenticated()
   * @desc used to check whether user has an authentication or not by using seesion(token).
   * @param key :string .
   */
  isUserAuthenticated(): any {
    const data = this.getDataFromStorage(STORAGE_KEYS.SESSION);
    if (data) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * @method getUserId()
   * @desc used to check whether user has an authentication or not by using seesion(token).
   * @param key :string .
   */
  getUserId(): any {
    const data = this.getDataFromStorage(STORAGE_KEYS.SESSION);
    if (data) {
      return data.id;
    } else {
      return -1;
    }
  }



}
