import { Component, OnInit,Input } from '@angular/core';
import { from } from 'rxjs';
import {CasesService} from '../../../services/cases.service'
import {environment} from '../../../../environments/environment'
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
declare var $: any;
@Component({
  selector: 'app-all-case-list',
  templateUrl: './all-case-list.component.html',
  styleUrls: ['./all-case-list.component.scss']
})
export class AllCaseListComponent implements OnInit {
  baseUrl = environment.baseUrl;
  headers$: any = []
  data$: any = []
  errorArr$:any=[]
  caseList:any = []
   length : number;
  pageSize : number;
  pageIndex: number;
  REFINEFIELD:any =''
  CASESTATUS:any= ''
  CASETYPE:any =''
  searchKey:any;
  isSearch:boolean;
  importCSV:any;
  CASEID:any
  isEditCase:boolean = false
  isDeleteCase:boolean = false
  ALLCAESFORDOWNLOAD:any = []
  CSVdata:any = []
  Types:any = ['Family','Military','Commercial','Civil','Criminal','Labor','Administrative']
  Status:any = ['Active','Closed','Settled','Suspended']
  Refinaries:any = [{key:'Case Reference',value:'caseReference'},{key:'File Number',value:'fileNumber'},
  {key:'Client name',value:'clientName.name'},{key:'Counter Party Name',value:'counterPartyName.name'},
  {key:'Case Number',value:'caseNumber'},{key:'Lawyer Name',value:'laywerName'},]
  constructor(private _cases: CasesService) {
    this.pageSize= 5;
    this.pageIndex = 1;
   }

  ngOnInit() {
     this.loadAllCases()
     this.loadCasesforDownload()
     this.isSearch = false
  }
  loginUserData(data){ 
    console.log(data.role.name)
    // in this condition it was 'Tier-3' and i made it 'Tier-1'
    if(data.role.name == 'Tier-3' ||  
        data.role.name == 'Sub-Admin' || data.role.name == 'Admin'){
      this.isEditCase = true
    }
    if(data.role.name == 'Admin' || data.role.name == 'Sub-Admin'){
      this.isDeleteCase = true
    }
   }
  loadAllCases(){
    console.log(this.pageSize,this.pageIndex -1)
    this._cases.casesList(this.pageSize,this.pageIndex -1).subscribe(res =>{
      console.log(res)
      this.caseList = res.data.findListData
      this.length = res.data.coundData.coundData
    })
  }

  onChangeRefinary(value){
    this.isSearch = true
    this.REFINEFIELD = value
  }
  onChangeCaseWithoutName(value){
    console.log(value)
    if(value){
      this._cases.filterCases({user:value}).subscribe(res =>{
        console.log(res)
        this.caseList = res.data.findListData
        this.length = res.data.coundData.coundData
      })
      }
      else{
        this.loadAllCases() 
      }
  }
  onChangeType(value){
    this.isSearch = true
    this.CASETYPE = value
    let obj = {
       searchKey : this.searchKey,
       caseType : this.CASETYPE,
       caseStatus : this.CASESTATUS,
       searchField : this.REFINEFIELD,
   }
   this._cases.searchCases(obj).subscribe(res =>{
     
     console.log(res)
     this.ALLCAESFORDOWNLOAD = res.data.findListData
     this.caseList = res.data.findListData
     this.length = res.data.coundData.coundData
   })
  }
  onChangeStatus(value){
    this.isSearch = true
    this.CASESTATUS = value
    let obj = {
      searchKey : this.searchKey,
       caseType : this.CASETYPE,
       caseStatus : this.CASESTATUS,
       searchField : this.REFINEFIELD,
   }
   this._cases.searchCases(obj).subscribe(res =>{
     console.log(res)
     this.ALLCAESFORDOWNLOAD = res.data.findListData
     this.caseList = res.data.findListData
     this.length = res.data.coundData.coundData
   })
  }

  searchFilterCases(){
    if(this.searchKey){
     if(this.searchKey.trim() != ''){
      this.isSearch = true
      let obj = {
          searchKey : this.searchKey,
          caseType : this.CASETYPE,
          caseStatus : this.CASESTATUS,
          searchField : this.REFINEFIELD,
      }
      this._cases.searchCases(obj).subscribe(res =>{
        console.log(res)
        this.ALLCAESFORDOWNLOAD = res.data.findListData
        this.caseList = res.data.findListData
        this.length = res.data.coundData.coundData
      })
     }
    }
    else{
   this.ngOnInit()
    }
  
  }
  handlePageChange(event) {
    this.pageIndex = event;
    if(this.searchKey){
      this.searchFilterCases();
    }
    else{
      this.loadAllCases();
    }
   
   }
  handlePageSizeChange(event) {
    this.pageSize = event;
    this.pageIndex = 1;
    this.loadAllCases();
  }


fileSelected(files: FileList){
  this.importCSV = files.item(0)
  console.log( this.importCSV)
  if(this.importCSV){
   let ext = this.importCSV.name.split('.').pop();
   if(ext == 'csv'){
     const formdata = new FormData();
     formdata.append("file",this.importCSV);
     console.log(formdata)
     this._cases.convertCsvForCase(formdata).subscribe(res => {
         console.log(res)
            this.data$ = res.data
            this.headers$ = res.header
            this.errorArr$ = res.errorArr
         $("#gTourModal").modal('show');
         $('.modal-open').addClass('point-event');
     },error =>{
       
       alert(error.error.message)
       window.location.reload()
     })
    }
  else{
    alert("Invalid file ")
    window.location.reload()
    }
  }
}

importCSv() {
  const formdata = new FormData();
  formdata.append("file",this.importCSV);
  this._cases.importcsvCases(formdata).subscribe(res => {
   console.log(res)
   if(res && res.success) {
     setTimeout(() => {
      window.location.reload()
      this.loadAllCases()
     }, 1000);
    }
  },error => {
    console.log(error)
    console.log(error.error.message)
    alert(error.error.message)
    window.location.reload()
  })

}
   reload(){
    setTimeout(() => {
      window.location.reload() 
      
    },500);
  }
 downloadtemplate() {
     window.open(this.baseUrl+'/uploads/lawyerit_cases_template.csv');
 }

delete(id){
    this.CASEID = id
  }
deleteCase(){
    this._cases.deleteCase({caseId:this.CASEID}).subscribe(res => {
      console.log(res)
      this.loadAllCases()
    })
  }
  loadCasesforDownload(){
    this._cases.casesListForDownload().subscribe(res =>{
      // console.log(res.data.findListData)
      this.ALLCAESFORDOWNLOAD = res.data.findListData
    })
  }
  downloadSingleCase(id){
console.log(id)
this.CSVdata = []
let csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalseparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Case Detail',
  useBom: true,
  noDownload: false,
   headers: ["Subject","Status", "Sub Status", "Client Name", "CounterParty Name", 
   "Lawyer Name","Case Reference","File Number","Case Type","Case Number",
   "Court Chamber", 
   "Attorney Fee","Collection Fee" ]
};

this._cases.viewCaseDetailForView({caseId:id}).subscribe(res => {
  console.log(res.data)
  this.CSVdata.push({
    caseSubject: res.data.caseSubject,
    caseStatus: res.data.caseStatus,
    caseSubStatus: res.data.caseSubStatus,
    clientName: res.data.clientName[0].name,
    counterPartyName: res.data.counterPartyName[0].name,
    laywerName: res.data.laywerName,
    caseReference: res.data.caseReference,
    fileNumber: res.data.fileNumber,
    caseType: res.data.caseType,
    caseNumber: res.data.caseNumber,
    courtChamber: res.data.courtChamber,
    attorneyFee: res.data.attorneyFee,
    totalJudgementAmount: res.data.totalJudgementAmount,
   })
   console.log(this.CSVdata)
   const csv = new AngularCsv(this.CSVdata,'Case',csvOptions); 
  })
  }
  downloadAllCases(){
    this.CSVdata = []
    let csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'All Case List',
        useBom: true,
        noDownload: false,
         headers: ["Subject","Status", "Sub Status", "Client Name", "CounterParty Name", 
         "Lawyer Name","Case Reference","File Number","Case Type","Case Number",
         "Court Chamber", "id",
         "Attorney Fee","Collection Fee" ]
      };
      console.log(this.ALLCAESFORDOWNLOAD)
      for(let i = 0; i< this.ALLCAESFORDOWNLOAD.length; i++){
         this.CSVdata.push({
          caseSubject: this.ALLCAESFORDOWNLOAD[i].caseSubject,
          caseStatus: this.ALLCAESFORDOWNLOAD[i].caseStatus,
          caseSubStatus: this.ALLCAESFORDOWNLOAD[i].caseSubStatus,
          clientName: this.ALLCAESFORDOWNLOAD[i].clientName[0].name,
          counterPartyName: this.ALLCAESFORDOWNLOAD[i].counterPartyName[0].name,
          laywerName: this.ALLCAESFORDOWNLOAD[i].laywerName,
          caseReference: this.ALLCAESFORDOWNLOAD[i].caseReference,
          fileNumber: this.ALLCAESFORDOWNLOAD[i].fileNumber,
          caseType: this.ALLCAESFORDOWNLOAD[i].caseType,
          caseNumber: this.ALLCAESFORDOWNLOAD[i].caseNumber,
          courtChamber: this.ALLCAESFORDOWNLOAD[i].courtChamber,
          id:this.ALLCAESFORDOWNLOAD[i]._id,
          attorneyFee: this.ALLCAESFORDOWNLOAD[i].attorneyFee,
          totalJudgementAmount: this.ALLCAESFORDOWNLOAD[i].totalJudgementAmount,
         }) 
        }
        console.log(this.CSVdata)
        const csv = new AngularCsv(this.CSVdata,'All Cases',csvOptions); 
}
}
