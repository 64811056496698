import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  subscriptionPlan:any =[]
  constructor(private userService: UserService) {
    this.userService.listAllPlan().subscribe(res => {
      this.subscriptionPlan = res.data.findListData
     
      console.log(this.subscriptionPlan)
    })
   }

  ngOnInit() {
  }

}
