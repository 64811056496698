import { Component, OnInit,Input } from '@angular/core';
import {DocumentUtilitiesService} from '../../../services/document-utilities.service'
import {CasesService} from '../../../services/cases.service'
import moment from 'moment-timezone';
import { environment } from '../../../../environments/environment';
declare var $: any;


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  @Input() id :any;
  @Input() userDetail :any
  Documents:any
  File:any=-1
  isSubFolder:boolean;
  isFile:boolean
  caseFolderName:any
  newSubFolder:any
  subFolderId:any;
  SubFolderNameForDelete:any
  SubFolderNameForAddFile:any
  fileId
  SubFolderNameForDeleteFile
  fileNameToDelete
  fileToUpload:any = ''
  isDeleteDocs:boolean = false
  isUploadDocs:boolean = true
  clientFolder:any = []
  counterFolder:any = []
  ContactFile:any = -1
  isContactFile:boolean
  isCounterContactFile:boolean
  contactFolderName:any;
  contactID:any;
  currentTimeZone:any
  isFolderNameError:boolean
  constructor(private _cases: CasesService, private document: DocumentUtilitiesService,) { 
    this.isSubFolder = false
    this.isFile = false
    this.isContactFile = false
    this.isCounterContactFile = false
    this.isFolderNameError = false
  }

  ngOnInit() {
    this.viewCaseDetail()
    this.viewContactFolder()
    console.log(this.userDetail.timeZone)
    this.currentTimeZone = this.userDetail.timeZone
    if(this.userDetail.role.name == 'Admin' || this.userDetail.role.name == 'Sub-Admin'){
      this.isDeleteDocs = true
    }
    if(this.userDetail.role.name == 'Tier-2' ){
      this.isUploadDocs = false
    }
  }
  showSubFolder(){
    this.isSubFolder =  !this.isSubFolder
  }
showFile(value){
    this.isFile =  !this.isFile
    this.File = value
 }
 showContactFile(value){
   console.log('Clint')
  this.isContactFile =  !this.isContactFile
  this.ContactFile = value
}
showCounterContactFile(value){
  console.log('Counter')
  this.isCounterContactFile =  !this.isCounterContactFile
  this.ContactFile = value
}
selectFileForContact(folderName,id) {
  console.log(folderName,id)
 this.contactFolderName = folderName
 this.contactID = id
 document.getElementById('fileInputContact').click();
 }

fileSelectedForContact(files){
      const formData: FormData = new FormData
      formData.append("contactId",this.contactID)
  if(files.length != 0){
    for(let i=0; i< files.length; i++){
      let ext = files[i].name.split('.').pop();
      if(ext == 'pdf' || ext == 'docx'  ||ext == 'jpeg'||ext == 'doc'||ext == 'jpg'
      ||ext == 'flv' ||ext == 'png' ||ext == 'odt' ||ext == 'xls'||ext == 'xlsx'||ext == 'ods'
      ||ext == 'txt' || ext == 'ppt' || ext == 'pptx'){
        formData.append("files",files[i])
   
      }
      else{
        alert('not valid extension');
      }
      if(files.length - 1 == i){
        this.document.uploadContactDocs(formData,this.contactFolderName).subscribe(res => {
          console.log(res)
          this.viewContactFolder()
         },error => {
          
           alert(error.error.message)
         })
      }
    }
  }




}
convertTZ(date, timezone="") { 
  if(timezone == this.currentTimeZone){
     return date
  }
  else{
    var timeZ = moment.tz(date,this.currentTimeZone);
    return timeZ.format("L")
  }
  
}
  viewCaseDetail(){
    this._cases.viewCaseDetail({caseId:this.id}).subscribe(res => {
      console.log(res)
      this.Documents = res.data2
      this.caseFolderName = res.data2.caseFolder;
      // console.log("ye doc hai sb",this.Documents)
   })
  }
  viewContactFolder(){
    this._cases.viewContactInCase({caseId:this.id}).subscribe(res => {
      console.log(res)
      this.clientFolder = res.data.clientId
      this.counterFolder = res.data.counterPartyId
      // console.log(this.clientFolder)
      // console.log(this.counterFolder)
   })
  }
  getCaseFolderName(){
    $("#modal-view-event").modal({backdrop: 'static', keyboard: false});
     
    }
createSubFolder(){
   console.log(this.newSubFolder)
  if(this.newSubFolder){
      let obj ={
        rootFolderName:this.caseFolderName,
        newFolderName :this.newSubFolder,
        caseId:this.id
      }
      if(this.newSubFolder.trim() != ""){
        this.document.createDirManual(obj).subscribe(res => {
          console.log(res)
          this.newSubFolder = ''
          this.isFolderNameError = false
          this.viewCaseDetail()
         },error => {
          this.newSubFolder = ''
          this.isFolderNameError = false
           alert(error.error.message)
         })
      }
      else{
        this.isFolderNameError = true
        }
    }
    else{
      this.isFolderNameError = true
    }
   }
cancelToCreate(){
  this.newSubFolder = ''
  this.isFolderNameError = false
}
downloadFolder(folderName){
  // console.log(folderName, 'folder name');
  
    let obj = {caseFolder:folderName,type:"rootDir"}
    console.log(obj)
    this.document.downloadCaseFolder(obj).subscribe(res => {
        // console.log(res, 'res')
        this.viewCaseDetail()
        window.open(environment.baseUrl+'/'+res.data);
       },error =>{
         console.log(error)
       })
  }
downloadSubFolder(folderName,subfolder){
    let obj = {caseFolder:folderName,type:"subDir",caseSubFolder:subfolder}
    console.log(obj)
    this.document.downloadCaseFolder(obj).subscribe(res => {
        // console.log(res, 'res')
        this.viewCaseDetail()
        // console.log(res.data, 'res data');
        
        window.open(environment.baseUrl + '/' +res.data);
       },error =>{
         console.log(error)
       })
  }
selectFile(folderName) {
    this.SubFolderNameForAddFile = folderName
    document.getElementById('fileInput').click();
   
     }
fileSelected(files){
           const formData: FormData = new FormData
           formData.append("caseId",this.id)
    
          if(files.length != 0){
                for(let i=0; i< files.length; i++){
                  let ext = files[i].name.split('.').pop();
                  if(ext == 'pdf' || ext == 'docx'  ||ext == 'jpeg'||ext == 'doc'||ext == 'jpg'
                  ||ext == 'flv' ||ext == 'png' ||ext == 'odt' ||ext == 'xls'||ext == 'xlsx'||ext == 'ods'
                  ||ext == 'txt' || ext == 'ppt' || ext == 'pptx'){
                    formData.append("file",files[i])
                  }
                  else{
                    alert('not valid extension');
                    break;
                  }
                        if(files.length - 1 == i){

                          this.document.uploadFileInDir(formData,this.caseFolderName,this.SubFolderNameForAddFile).subscribe(res => {
                            console.log(res)
                        
                            this.viewCaseDetail()
                          },error => {
                            
                            alert(error.error.message)
                          })
                        }
                }
          }
 
   
   
  }
getForDeleteFile(Fileid,subfolder,value){
    this.fileId = Fileid
    this.SubFolderNameForDeleteFile = subfolder
    this.fileNameToDelete = value
    
  }
deleteFile(){
    let obj = {
      listOfFile : [this.fileNameToDelete],
      subFolderName : this.SubFolderNameForDeleteFile,
      folderName : this.caseFolderName,
      fileId : this.fileId,
      caseId : this.id
    }
    this.document.removeFileFromDocs(obj).subscribe(res => {
      console.log(res)
      this.viewCaseDetail()
    },error => {
      alert(error.error.message)
    })
  }
downloadFile(data,id,subId, k){
  // console.log(data, 'data');
  // console.log(this.Documents, 'docs');
  console.log(this.counterFolder, 'counter folder');
  
  
  console.log(k, 'index');
  console.log(this.clientFolder[0].contactDocs[0].url[k], 'client folder');
  let fileId =  this.clientFolder[0].contactDocs[0]['_id'];
  console.log(fileId, 'fileId');
  console.log(id, 'id');
  
  let documentUrl = this.clientFolder[0].contactDocs[0].url[k].substring(18);
      console.log(documentUrl, 'document url');
  // console.log(data.substring(18), 'new url');
  console.log(subId)
  if(id){
    this.document.updateFileTime({fileId:id,subFolderId:subId}).subscribe(res => {
      this.viewCaseDetail()
      // console.log(res.fileData[0].caseSubFolder.files.url, 'res');
      
      console.log(this.isContactFile, 'contact file');
      console.log(this.isFile, 'file');
      
      if(this.isContactFile) {
      window.open(environment.baseUrl + '/public/' + documentUrl);  
      console.log('contact');
      
      }
      else if(this.isFile) { 
      window.open(environment.baseUrl  + res.fileData[0].caseSubFolder.files.url);
      }
        // console.log(res)
     },error =>{
       console.log(error)
     })
  }
  else{
    console.log(this.isContactFile, 'contact file');
      console.log(this.isFile, 'file');
    window.open(data);
  }
 
    
  }
getForDeleteSubFolder(id,subfolder){
    this.subFolderId = id
    this.SubFolderNameForDelete = subfolder
  }
deleteDirectory(){
     if(this.SubFolderNameForDelete){
       this.document.removeSubFolder({folderName:this.caseFolderName, subFolderName:this.SubFolderNameForDelete,
        folderId:this.subFolderId}).subscribe(res => {
        console.log(res)
        this.viewCaseDetail()
        this.SubFolderNameForDelete= ''
      },error => {
        alert(error.error.message)
      })
    }
    else{
     this.document.removeFolder({folderName:this.caseFolderName, caseId:this.id}).subscribe(res => {
        console.log(res)
        this.viewCaseDetail()
      },error => {
        alert(error.error.message)
      })
    }
 
 } 
}
