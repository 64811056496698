import { Component, Input, OnInit,HostListener } from '@angular/core';
import {AuthService} from './services/auth.service'
import {ApiService} from './config/sharedServices/api.service'
import { LocationStrategy } from '@angular/common';
import {Router} from '@angular/router'
import * as io from 'socket.io-client';
import { SharedService } from './config/sharedServices/shared.service';
const STORE_KEY =  'lastAction';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  title = 'lawyerIt-frontUser';
  status: boolean;
  socket;
  public isLoader: boolean = false
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
     localStorage.setItem(STORE_KEY,Date.now().toString() + 2 *60 * 60 * 1000);
  }
  constructor(private authService: AuthService, private loader: ApiService,
     private location: LocationStrategy, private _shared: SharedService,private route:Router){
  }

  ngOnInit(){
     if(this.authService.isLoggedIn()){
      // console.log('logedin')
     }
     else{
    
     }
     this.loader.getLoader().subscribe(res => {
      setTimeout(() => this.isLoader = res);
     });
  }

}
