import { Component, OnInit } from '@angular/core';
import {DocumentUtilitiesService} from '../../../services/document-utilities.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CasesService} from '../../../services/cases.service'
import {environment} from '../../../../environments/environment'
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $: any;
@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent implements OnInit {
  baseUrl = environment.baseUrl;
  linkData:any=[]
  textInformation:any =[];
  advertisement:any =[];
  recieptModelForm:any
  invoiceModelForm:any
  voucherModelForm:any
  isSubmitted:boolean;
  tableHeaderData:any = []
  tableData:any = []
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 15,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  src: string;
  constructor(private document: DocumentUtilitiesService,private fb: FormBuilder,
    private _cases: CasesService) { 
      this.isSubmitted = false
    }

  ngOnInit() {
    this.loadData()
    this.buildRecieptForm()
    this.buildInvoiceForm()
    this.buildVoucherForm()
  }
loadData(){
    this.document.listContent({type:'Text'}).subscribe(res => {
        this.textInformation = res.data.findListData
         console.log(this.textInformation)
       })
       this.document.listContent({type:'Link'}).subscribe(res => {
        this.linkData = res.data.findListData
        console.log(this.linkData)
       })
       this.document.listContent({type:'Advertisment'}).subscribe(res => {
        this.advertisement = res.data.findListData;
        console.log(this.advertisement, 'advertisement');
        console.log(res, 'res');
        this.src = environment.baseUrl + '/public/advertisement/';
       })
       this.document.getUtilites().subscribe(res => {
        console.log(res)
        if(res.data.length !=0) {
          this.tableHeaderData = res.data[0].headers
          this.tableData = res.data[0].table 
           }
     })   
   }
goToLink(url: string){
  console.log(url)
    window.open(url, "_blank");
} 

 closeModel(){
   this.isSubmitted = false
   this.invoiceModelForm.reset()
   this.recieptModelForm.reset()
   this.voucherModelForm.reset()
 }

formatDate(date) {
       var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
     return [year, month, day].join('-');
}

 buildVoucherForm(){
  this.voucherModelForm = this.fb.group({
    date: ['',[Validators.required]],
    from: ['',[Validators.required]],
    paid: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$"),Validators.min(.01)]],
    reason: [''],
    pendingamount: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$")]],
    paymentmethod: ['',[Validators.required]],
    receivedBy: [''],
    chequeDate: [''],
    chequeNo: [''],
    drawingAccount: [''],
    drawnBankName: [''],
    type:['Attorney']
     });
}
generatePaymentVoucher(){
  this.isSubmitted = true
  this.voucherModelForm.value.date =  this.formatDate(this.voucherModelForm.value.date)
  this.voucherModelForm.value.chequeDate =  this.formatDate(this.voucherModelForm.value.chequeDate)
  console.log(this.voucherModelForm.value)
  if(this.voucherModelForm.status == 'VALID'){
    this._cases.generatePaymentVoucher(this.voucherModelForm.value).subscribe(res => {
      // console.log(res)
      this.isSubmitted = false
      this.voucherModelForm.reset()
      $("#voucherModal").modal("hide");
      window.open(this.baseUrl+'/'+res.data);
     })
  }
}
  buildRecieptForm(){
    this.recieptModelForm = this.fb.group({
      date: ['',[Validators.required]],
      recieptNo: [''],
      from: ['',[Validators.required]],
      paid: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$"),Validators.min(.01)]],
      reason: [''],
      pendingamount: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$")]],
      // dueDate: [''],
      paymentmethod: ['',[Validators.required]],
      receivedBy: ['',[]],
      chequeDate: ['',[]],
      chequeNo: ['',[]],
      drawingAccount: ['',[]],
      drawnBankName: ['',[]],
      type:['Attorney']
       });
  }
  generateReciept(){
    this.isSubmitted = true
    this.recieptModelForm.value.date =  this.formatDate(this.recieptModelForm.value.date)
    this.recieptModelForm.value.chequeDate =  this.formatDate(this.recieptModelForm.value.chequeDate)
    console.log(this.recieptModelForm.value)
    if(this.recieptModelForm.status == 'VALID'){
      this._cases.generateReceipt(this.recieptModelForm.value).subscribe(res => {
        console.log(res)
        this.isSubmitted = false
        this.recieptModelForm.reset()
        $("#receiptModal").modal("hide");
        window.open(this.baseUrl+'/'+res.data);
       })
    }
  }
  buildInvoiceForm(){
    this.invoiceModelForm = this.fb.group({
      date: ['',[Validators.required]],
      invoiceNo: [''],
      from: ['',[Validators.required]],
      paid: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$"),Validators.min(.01)]],
      pendingamount: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$")]],
      amount: ['',[Validators.required,Validators.pattern("^\\d+(\\.\\d+)*$")]],
      iban: [''],
      firmName: [''],
      type:['Attorney']
       });
  }
generateInvoice(){
    this.isSubmitted = true
    console.log(this.invoiceModelForm)
    this.invoiceModelForm.value.date =  this.formatDate(this.invoiceModelForm.value.date)
    if(this.invoiceModelForm.status == 'VALID'){
      this._cases.generateInvoice(this.invoiceModelForm.value).subscribe(res => {
        console.log(res)
        this.isSubmitted = false
        this.invoiceModelForm.reset()
        $("#invoiceModal").modal("hide");
        window.open(this.baseUrl+'/'+res.data);
       })
    }
 
  } 

     
}
