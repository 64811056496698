import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CasesService} from '../../../services/cases.service'
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import swal from 'sweetalert';
@Component({
  selector: 'app-edit-case',
  templateUrl: './edit-case.component.html',
  styleUrls: ['./edit-case.component.scss']
})
export class EditCaseComponent implements OnInit {
  params:any
  caseDetail:any
  editCaseForm:any
  isSubmitted: boolean;
  clientList:any
  lawyerList:any
  LAWYERNAME:any;
  LAWYERID:any;
  clientForm:any;
  counterPartyForm
  clientNameArray:any = []
  clientIdArray:any = []
  counterNameArray:any = []
  counterIdArray:any = [];
  isClientNameError:boolean = false;
  isCounterNameError:boolean = false;
  isContactSame:boolean = false
  constructor(private navParam:ActivatedRoute,private _cases: CasesService,private fb: FormBuilder,
    private router: Router) { 
    this.params = this.navParam.snapshot.params.caseId;
    this.isSubmitted = false;
  }

  ngOnInit() {
    this.buildCaseForm()
    this.loadContactLawyer()
    setTimeout(()=>{ this.viewCaseDetail()},1)
  }
  loadContactLawyer(){
    this._cases.contactList().subscribe(res => {
      this.clientList = res.data.findListData
       })
    this._cases.lawyerList().subscribe(res => {
      this.lawyerList = res.data.findListData
      })
  }
  combineLawyer(contact) {
    return  contact.fullName+'-'+contact.userId 
 }
  combine(contact) {
    // console.log(contact)
     return contact.companyName ? contact.companyName+'-'+contact.phoneNumber : contact.personName+'-'+contact.phoneNumber
  }
  onChangeLawyer(value){
    let user = value.split('-')[1]
    console.log(user)
    let lawyerData = this.lawyerList.find(m => m.userId == user)
    console.log(lawyerData)
    this.LAWYERNAME = lawyerData.fullName
    this.LAWYERID = lawyerData._id
    this.editCaseForm.value.laywer =  this.LAWYERID 
    this.editCaseForm.value.laywerName =  this.LAWYERNAME 
  }

  onChangeClint(value){
    let phone = value.split('-')[1]
    let clientData = this.clientList.find(m => m.phoneNumber == phone)
    if(clientData){
      this.isClientNameError = false
    }else{
      this.isClientNameError = true
    }
    // this.clientNameArray.push({name:clientData.personName ? clientData.personName : clientData.companyName})
    // this.clientIdArray.push(clientData._id)
    // var index = this.clientList.findIndex(x => x._id === clientData._id);
    // console.log(index)
    // if(index > -1){
    //  this.clientList.splice(index,1);
    // }
 }

 getClientName(value){
  for(let i =0; i< value.length; i++){
    if(value[i].name){
          let phone = value[i].name.split('-')[1]  
          let clientData = this.clientList.find(m =>  m.phoneNumber == phone)
              if(clientData){
                  this.clientNameArray.push({name:clientData.personName ? clientData.personName : clientData.companyName})
               }
      }
    }
    return this.clientNameArray
}
getClientId(value){
  for(let i =0; i< value.length; i++){
    if(value[i].name){
      let phone = value[i].name.split('-')[1]  
      let clientData = this.clientList.find(m => m.phoneNumber == phone)
      if(clientData){
        this.clientIdArray.push(clientData._id)
      }
      else{
        this.isClientNameError = true
      }
    }
  }
  return this.clientIdArray
}
  onChangeCounterParty(value){
   let phone = value.split('-')[1]
   let counterData = this.clientList.find(m => m.phoneNumber == phone)
   if(counterData){
    this.isCounterNameError = false
   }
   else{
    this.isCounterNameError = true
   }
  //  this.counterNameArray.push({name:counterData.personName ? counterData.personName : counterData.companyName})
  //  this.counterIdArray.push(counterData._id)
  //  var index = this.clientList.findIndex(x => x._id === counterData._id);
  //  console.log(index)
  //  if(index > -1){
  //   this.clientList.splice(index,1);
  //  }
 }

 getCounterName(value){
  for(let i =0; i< value.length; i++){
    if(value[i].name){
      let phone = value[i].name.split('-')[1]  
      let counterData = this.clientList.find(m =>  m.phoneNumber == phone)
      if(counterData){
        this.counterNameArray.push({name:counterData.personName ? counterData.personName : counterData.companyName})
      }
   }
  }
  
  return this.counterNameArray
}
getCounterId(value){
  for(let i =0; i< value.length; i++){
    if(value[i].name){
      let phone = value[i].name.split('-')[1]  
      let counterData = this.clientList.find(m => m.phoneNumber == phone)
      if(counterData){
        this.counterIdArray.push(counterData._id)
        this.isCounterNameError = false
      }
      else{
        this.isCounterNameError = true
      }
   }
  }

  return this.counterIdArray
}

checkDupes(array)
{
   for (var i = 0; i < array.length -1; i++)
        {
              if (array[i+1] == array[i])
              {
                return true;
              }
        }
  return false;
} 
CompareArray(array1,array2)
{
      for(let i = 0; i < array1.length ; i++){
        for(let j = 0; j < array2.length ; j++){
          console.log(array1[i],array2[j])
          if(array1[i]=== array2[j]){
            return true
            }
          else{
 
          }
        }
     }
     return false

} 
  submitCase(){
    this.isSubmitted = true
    this.counterNameArray = []
    this.counterIdArray = []
    this.clientNameArray = []
    this.clientIdArray = []
    this.editCaseForm.value.clientId =  this.getClientId(this.editCaseForm.value.clientName)
    this.editCaseForm.value.clientName = this.getClientName(this.editCaseForm.value.clientName) 
    this.editCaseForm.value.counterPartyId = this.getCounterId(this.editCaseForm.value.counterPartyName)
    this.editCaseForm.value.counterPartyName = this.getCounterName(this.editCaseForm.value.counterPartyName)
    this.editCaseForm.value.caseId =  this.params 
    console.log(this.editCaseForm.value)

    if(this.editCaseForm.value.clientId.length != 0){
      var firstArray =  this.checkDupes(this.editCaseForm.value.clientId) 
    }
    if(this.editCaseForm.value.counterPartyId.length != 0){
      var secondArray =  this.checkDupes(this.editCaseForm.value.counterPartyId) 
    }
     if(firstArray || secondArray){
      this.isContactSame = true
    }
    else{
        if(this.CompareArray(this.editCaseForm.value.clientId,this.editCaseForm.value.counterPartyId)){
          this.isContactSame = true
        }
        else{
          this.isContactSame = false
        }
    }

    if(this.editCaseForm.status == 'VALID' && !this.isCounterNameError
     && !this.isClientNameError){
      if(this.isContactSame){
        swal("Error!", "Contacts can not be same.", "error");
       }
       else{
        this._cases.editCases(this.editCaseForm.value).subscribe(res => {
          console.log(res)
          this.isSubmitted = false
          swal(res.data, "", "success");
          this.router.navigate(['/lawyerIt/cases']);
        },error => {
          swal("Error!", error.error.message, "error");
        })
       }
  
    }

  }


get clientName() : FormArray {
    return this.editCaseForm.get("clientName") as FormArray
}
newClient(): FormGroup {
  return this.clientForm = this.fb.group({
    name: ['',[Validators.required]],
  })
}
addClient(){
  this.clientName.push(this.newClient());
}

removeClient(i:number){
  this.clientName.removeAt(i);
  this.clientNameArray.splice(i,1);
  this.clientIdArray.splice(i,1);
 }


get counterPartyName() : FormArray {
  return this.editCaseForm.get("counterPartyName") as FormArray
}
newCounter(): FormGroup {
return this.counterPartyForm = this.fb.group({
  name: ['',[Validators.required]],
})
}
addCounterParty(){
this.counterPartyName.push(this.newCounter());
}

removeCounterParty(i:number){
this.counterPartyName.removeAt(i);
this.counterNameArray.splice(i,1);
this.counterIdArray.splice(i,1);
}
 
viewCaseDetail(){
    this._cases.viewCaseDetail({caseId:this.params}).subscribe(res => {
      this.caseDetail = res.data
      console.log( this.caseDetail)
      if(this.caseDetail.clientId.length != 0){
        for(let i=0; i< this.caseDetail.clientId.length; i++){
          this.addClient()
         let clientData = this.clientList.find(m => m._id == this.caseDetail.clientId[i])
          // console.log(clientData)
          this.clientForm.patchValue({
            name: clientData.companyName ? clientData.companyName+'-'+clientData.phoneNumber : clientData.personName+'-'+clientData.phoneNumber
          })
          this.onChangeClint(this.clientForm.value.name)
        }
      }
      else{
        this.addClient()
      }
      if(this.caseDetail.counterPartyId.length != 0){
        for(let i=0; i< this.caseDetail.counterPartyId.length; i++){
          this.addCounterParty()
          let counterData = this.clientList.find(m => m._id == this.caseDetail.counterPartyId[i])
          this.counterPartyForm.patchValue({
            name: counterData.companyName ? counterData.companyName+'-'+counterData.phoneNumber : counterData.personName+'-'+counterData.phoneNumber
          })
          this.onChangeCounterParty(this.counterPartyForm.value.name)
        }
      }
      else{
        this.addCounterParty()
      }
      this.LAWYERNAME = this.caseDetail.laywerName
      this.LAWYERID = this.caseDetail.laywer
      this.editCaseForm.patchValue({
        caseSubject:this.caseDetail.caseSubject,
        caseStatus:this.caseDetail.caseStatus,
        caseSubStatus:this.caseDetail.caseSubStatus,
        laywerName:this.LAWYERNAME,
        laywer:this.LAWYERID,
        caseType:this.caseDetail.caseType,
        fileNumber:this.caseDetail.fileNumber,
        caseNumber:this.caseDetail.caseNumber,
        courtChamber:this.caseDetail.courtChamber,
        executionNumber:this.caseDetail.executionNumber,
        intrestRate:this.caseDetail.intrestRate,
        // passportNumber:this.caseDetail.passportNumber,
        caseReference:this.caseDetail.caseReference,
        courtFees:this.caseDetail.courtFees,
        expertFee:this.caseDetail.expertFee,
        otherFee:this.caseDetail.otherFee,
        claimAmount:this.caseDetail.claimAmount,

      })

    })
  }
  buildCaseForm(){
    this.editCaseForm = this.fb.group({
      caseId:[''],
      caseReference:[''],
      caseSubject: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(" +
              /^[a-zA-Z\u0600-\u06FF,-][sa-zA-Z\u0600-\u06FF,-]*$/ +
              ") || ( " +
              /^[0-9]/ +
              ")"
          ),
        ],
      ],
     caseStatus: ['', ],
      caseSubStatus: ['',],
      clientId:[''],
      clientName: this.fb.array([]),
      counterPartyId:[''],
      counterPartyName: this.fb.array([]),
      laywer: [''],
      laywerName:['',[Validators.required]],
      fileNumber: ['',[Validators.required]],
      caseType: ['',[Validators.required]],
      caseNumber: ['',[Validators.required,Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      courtChamber: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(" +
              /^[a-zA-Z\u0600-\u06FF,-][sa-zA-Z\u0600-\u06FF,-]*$/ +
              ") || ( " +
              /^[0-9]/ +
              ")"
          ),
        ],
      ],
      executionNumber: ['',[Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      intrestRate: ['',[Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      // passportNumber: ['',[Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      courtFees: ['',[Validators.pattern("^\\d+(\\.\\d+)*$")]],
      expertFee: ['',Validators.pattern("^\\d+(\\.\\d+)*$")],
      otherFee: ['',[Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      claimAmount: ['',[Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      });
  }
}
