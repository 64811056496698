import { Component, OnInit,AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import {ContactsService} from '../../../services/contacts.service'
import swal from 'sweetalert';
import {removeSpaces,noWhitespaceValidator} from '../../../config/sharedServices/customeValidation'
@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit,AfterViewInit {
  params:any;
  contactRegisterForm:any
  contactDetail:any;
  isCompany:boolean;
  isCustomFiled: boolean;
  isSubmitted:boolean
  customForm:any
  FIELDNAME:any;
  FIELDTYPE:any
  eform:any;
  allCaseData:any =[]
  feeTabData:any = []
  isEditContact:boolean = true
  totalCollection:number = 0;
  totalAttorney:number = 0
  constructor(private navParam:ActivatedRoute,private fb: FormBuilder,private _contact: ContactsService,
    private router: Router) { 
    this.params = this.navParam.snapshot.params.contactId;
    this.isSubmitted = false
   }

  ngOnInit() {
    this.buildContactForm()
    this.viewContact()
    this.customeFiled()
    this.addBenificary()
    this.loadFeeTabData()
          var today;
          today = new Date();
          var dd;
          var mm;
      dd = today.getDate();
      mm = today.getMonth()+1; //January is 0!
      var yyyy = today.getFullYear();
      if(dd<10){
              dd='0'+dd
          } 
          if(mm<10){
              mm='0'+mm
          } 

      today = yyyy+'-'+mm+'-'+dd;
      document.getElementById("datefield").setAttribute("max", today);
   
  }

  loginUserData(data){
        if(data.role.name == 'Tier-1' || data.role.name == 'Tier-2'){
          this.isEditContact = false
          const state = this.isEditContact ? 'enable' : 'disable';
          this.contactRegisterForm.controls['companyName'][state]();
          this.contactRegisterForm.controls['personName'][state]();
          this.contactRegisterForm.controls['numberOfPoa'][state]();
          this.contactRegisterForm.controls['passportNumber'][state]();
          this.contactRegisterForm.controls['occupation'][state]();
          this.contactRegisterForm.controls['dateOfBirth'][state]();
          this.contactRegisterForm.controls['sourceOfIncome'][state]();
          this.contactRegisterForm.controls['activity'][state]();
          this.contactRegisterForm.controls['legalForm'][state]();
          this.contactRegisterForm.controls['mainBranchLocation'][state]();
          this.contactRegisterForm.controls['PlaceRegDomicile'][state]();
          this.contactRegisterForm.controls['representativeLawyer'][state]();
          this.contactRegisterForm.controls['crNumber'][state]();
          this.contactRegisterForm.controls['cprNumber'][state]();
          this.contactRegisterForm.controls['email'][state]();
          this.contactRegisterForm.controls['phoneNumber'][state]();
          this.contactRegisterForm.controls['IBAN_Number'][state]();
          this.contactRegisterForm.controls['nationality'][state]();
          this.contactRegisterForm.controls['address'][state]();
          this.contactRegisterForm.controls['webAddress'][state]();
          this.contactRegisterForm.controls['companyOwnerName'][state]();
          this.contactRegisterForm.controls['ultimateBeneficialPerson'][state]();
       
    }
    console.log(data.role.name,this.isEditContact)
   }
 ngAfterViewInit(){
    setTimeout(() => {
      this.setUserCategoryValidators()
    },1000)
   
  }
loadFeeTabData(){
    this._contact.getFeetab({contactId:this.params}).subscribe(res => {
      console.log(res)
      this.feeTabData = res.data
      for(let i=0; i< this.feeTabData.length; i++){
        this.totalCollection = this.totalCollection + parseInt(this.feeTabData[i].totalJudgementAmount) 
        this.totalAttorney =  this.totalAttorney + parseInt(this.feeTabData[i].attorneyFee)
        }
    })
  
  }
getPaidValue(value){
  if(value){
    let totalPaid = 0
    for(let i=0; i< value.length; i++){
      totalPaid = totalPaid + value[i].receivedAmount 
    }
    return totalPaid
  }
  else{
    return 0
  }
  } 
getPaindingValue(value,value2){
  if(value){
    let totalPaid = 0
    for(let i=0; i< value.length; i++){
      totalPaid = totalPaid + value[i].receivedAmount 
    }
    return value2-totalPaid
  }
  else{
    return value2
  }
}  
getPaidCollection(value){
  // console.log(value)
  if(value){
    
    let totalPaid = 0
    for(let i=0; i< value.length; i++){
      totalPaid = totalPaid + value[i].receivedAmount 
    }
    return totalPaid
  }
  else{
    return 0
  }
  } 
  getPaindingCollection(value,value2){
  if(value){
    let totalPaid = 0
    for(let i=0; i< value.length; i++){
      totalPaid = totalPaid + value[i].receivedAmount 
    }
    return value2-totalPaid
  }
  else{
    return value2
  }
} 
loadCases(){
  this._contact.getRelatedCases({contactId:this.params}).subscribe(res => {
    console.log(res)
    this.allCaseData = res.data
  })

}  
private formatDate(date) {
      if(date){
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
      else{
      
      }
 }
 viewContact(){
    this._contact.viewContactDetail({contactId:this.params}).subscribe(res => {
      this.contactDetail = res.data
      if(this.contactDetail.contactType == 'Company' || this.contactDetail.contactType == 'company'){
        this.isCompany = true
      }
      else{
        this.isCompany = false
      }
    
      if(this.contactDetail.customField && this.contactDetail.customField.length > 0) {
        this.addIntoexistfield()
      }  
    if(this.contactDetail.ultimateBeneficialPerson && this.contactDetail.ultimateBeneficialPerson.length > 0) {
      this.addUltimateIntoexistfield()
    }  
    
      this.contactRegisterForm.patchValue({
        contactType: this.contactDetail.contactType,
        companyName: this.contactDetail.companyName,
        personName: this.contactDetail.personName,
        numberOfPoa: this.contactDetail.numberOfPoa,
        passportNumber: this.contactDetail.passportNumber,
        occupation: this.contactDetail.occupation,
        dateOfBirth: this.formatDate(this.contactDetail.dateOfBirth),
        sourceOfIncome: this.contactDetail.sourceOfIncome,
        activity: this.contactDetail.activity,
        legalForm: this.contactDetail.legalForm,
        mainBranchLocation: this.contactDetail.mainBranchLocation,
        PlaceRegDomicile: this.contactDetail.PlaceRegDomicile,
        representativeLawyer: this.contactDetail.representativeLawyer,
        crNumber: this.contactDetail.crNumber,
        cprNumber: this.contactDetail.cprNumber,
        email: this.contactDetail.email,
        phoneNumber: this.contactDetail.phoneNumber,
        IBAN_Number: this.contactDetail.IBAN_Number,
        nationality: this.contactDetail.nationality,
        address: this.contactDetail.address,
        webAddress: this.contactDetail.webAddress,
        companyOwnerName: this.contactDetail.companyOwnerName,
        // customField: this.contactDetail.customField,
        // ultimateBeneficialPerson:this.contactDetail.ultimateBeneficialPerson
      })
    })
  }

  setUserCategoryValidators(){
     if(this.contactDetail.contactType == 'Company' || this.contactDetail.contactType == 'company') {
  
        this.contactRegisterForm.get('companyName').setValidators([Validators.required]);
        this.contactRegisterForm.get('webAddress').setValidators([Validators.pattern(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/)]);
        this.contactRegisterForm.get('companyOwnerName').setValidators([Validators.required]);
        this.contactRegisterForm.get('crNumber').setValidators([Validators.required]);
        this.contactRegisterForm.get('cprNumber').setValidators();
        }
        else{
        
          this.contactRegisterForm.get('personName').setValidators([Validators.required]);
          this.contactRegisterForm.get('companyName').setValidators();
          this.contactRegisterForm.get('crNumber').setValidators();
          this.contactRegisterForm.get('cprNumber').setValidators([Validators.required]);
          this.contactRegisterForm.get('webAddress').setValidators();
                 this.contactRegisterForm.get('companyOwnerName').setValidators();
         }
        this.contactRegisterForm.get('personName').updateValueAndValidity();
        this.contactRegisterForm.get('companyName').updateValueAndValidity();
        this.contactRegisterForm.get('webAddress').updateValueAndValidity();
        this.contactRegisterForm.get('crNumber').updateValueAndValidity();
        this.contactRegisterForm.get('cprNumber').updateValueAndValidity();
        this.contactRegisterForm.get('companyOwnerName').updateValueAndValidity();
  }
  buildContactForm(): void {
    console.log('Buld',this.isEditContact)
    this.contactRegisterForm = this.fb.group({
      contactType: ['',[Validators.required] ],
      companyName: [{value:'', disabled: !this.isEditContact}],
      personName: [''],
      numberOfPoa: [''],
      passportNumber: [''],
      occupation: [''],
      dateOfBirth: ['', ],
      sourceOfIncome: [''],
      activity: [''],
      legalForm: [''],
      mainBranchLocation: [''],
      PlaceRegDomicile: [''],
      representativeLawyer: [''],
      crNumber: ['', ],
      cprNumber: ['',],
      email: ['',[Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')] ],
      phoneNumber: ['',[Validators.required, Validators.pattern(/^(?:\+[0-9]|[0-9])(?:[0-9])\d{6,13}$/)] ],
      IBAN_Number: ['',],
      nationality: ['',[Validators.required]],
      address: ['',[Validators.required]],
      webAddress: [''],
      companyOwnerName: [''],
      ultimateBeneficialPerson: this.fb.array([]),
      customField: this.fb.array([]),
    });
  }

    // custom field
    get customField() : FormArray {
      return this.contactRegisterForm.get("customField") as FormArray
    }

    customeFiled(): FormGroup {
      return this.customForm = this.fb.group({
        fieldName: [this.FIELDNAME,[Validators.required]],
        fieldType: ['String'],
        fieldValue: [''],
      })
    }

    addIntoexistfield() {
      if(this.contactDetail.customField.length > 0) {
        this.contactDetail.customField.forEach(custome=>{
          let customField = this.fb.group({
            fieldName: [custome.fieldName,[Validators.required,noWhitespaceValidator]],
            fieldType: ['String'],
            fieldValue: [custome.fieldValue.replace(/\s\s+/g, ' ')],
          })
          if(customField.status == 'VALID'){
            this.customField.push(customField);
          }
        })
      }
    }


    addUltimateIntoexistfield() {
      if(this.contactDetail.ultimateBeneficialPerson.length > 0) {
           this.contactDetail.ultimateBeneficialPerson.forEach(custome=>{
          let customField = this.fb.group({
            benificaryName: [custome.benificaryName],
          })
          if(customField.status == 'VALID'){
            this.ultimateBeneficialPerson.push(customField);
          }
        })
      }
    }
  
  addCustomField(){
    this.isCustomFiled = true;
    let customField = this.fb.group({
      fieldName: [this.FIELDNAME,[Validators.required,noWhitespaceValidator]],
      fieldType: ['String'],
      fieldValue: ['',removeSpaces],
    })
    if(customField.status == 'VALID'){
      this.customField.push(customField);
      this.isCustomFiled = false;
    }
  
    }

    get ultimateBeneficialPerson() : FormArray {
      return this.contactRegisterForm.get("ultimateBeneficialPerson") as FormArray
  }
  newBenificary(): FormGroup {
    return this.eform = this.fb.group({
      benificaryName: [''],
    })
  }
  addBenificary(){
   
    this.ultimateBeneficialPerson.push(this.newBenificary());
  }
  removeBenificary(i:number){
    this.ultimateBeneficialPerson.removeAt(i);
  }

    

  editContact(){
    this.isSubmitted = true
    let isEMail = false
    let isPhone = false
    console.log(this.contactRegisterForm)
    console.log(this.contactRegisterForm.status)
    if(this.contactRegisterForm.value.dateOfBirth && new Date(this.contactRegisterForm.value.dateOfBirth).getTime() > new Date().getTime()) {
      alert("Date of Birth should not be greater then current date")
     } else {
       if(this.contactDetail.email.toLowerCase() == this.contactRegisterForm.value.email.toLowerCase()) {
        isEMail = true
       }
       if(this.contactDetail.phoneNumber == this.contactRegisterForm.value.phoneNumber) {
        isPhone = true
      }
      if(this.contactRegisterForm.status == 'VALID'){
 
 
        if(this.contactDetail.contactType == 'Company' || this.contactDetail.contactType == 'company'){
          console.log('Company')
          let obj = { firm:this.contactDetail.firm._id,contactId:this.params,
            contactType:this.contactRegisterForm.value.contactType,companyName:this.contactRegisterForm.value.companyName,
            numberOfPoa:this.contactRegisterForm.value.numberOfPoa,passportNumber:this.contactRegisterForm.value.passportNumber,
            occupation:this.contactRegisterForm.value.occupation,dateOfBirth:this.contactRegisterForm.value.dateOfBirth,
            sourceOfIncome:this.contactRegisterForm.value.sourceOfIncome,activity:this.contactRegisterForm.value.activity,
            legalForm:this.contactRegisterForm.value.legalForm,mainBranchLocation:this.contactRegisterForm.value.mainBranchLocation,
            PlaceRegDomicile:this.contactRegisterForm.value.PlaceRegDomicile,representativeLawyer:this.contactRegisterForm.value.representativeLawyer,
            crNumber:this.contactRegisterForm.value.crNumber,cprNumber:this.contactRegisterForm.value.cprNumber,
            email:this.contactRegisterForm.value.email,phoneNumber:this.contactRegisterForm.value.phoneNumber,
            IBAN_Number:this.contactRegisterForm.value.IBAN_Number,nationality:this.contactRegisterForm.value.nationality,
            address:this.contactRegisterForm.value.address,webAddress:this.contactRegisterForm.value.webAddress,
            companyOwnerName:this.contactRegisterForm.value.companyOwnerName,
            ultimateBeneficialPerson:this.contactRegisterForm.value.ultimateBeneficialPerson,
            customField: this.contactRegisterForm.value.customField,
            isEmail:isEMail,
            isPhone:isPhone
             }
             console.log(obj)
             this._contact.editConatct(obj).subscribe(res => {
              console.log(res)
              swal(res.data, "", "success");
              this.router.navigate(['/lawyerIt/contacts']);
             },error => {
               swal("Error!", error.error.message, "error");
             })
         }
      else{
        let obj = { firm:this.contactDetail.firm._id,contactId:this.params,
          contactType:this.contactRegisterForm.value.contactType,personName:this.contactRegisterForm.value.personName,
          numberOfPoa:this.contactRegisterForm.value.numberOfPoa,passportNumber:this.contactRegisterForm.value.passportNumber,
          occupation:this.contactRegisterForm.value.occupation,dateOfBirth:this.contactRegisterForm.value.dateOfBirth,
          sourceOfIncome:this.contactRegisterForm.value.sourceOfIncome,activity:this.contactRegisterForm.value.activity,
         
          representativeLawyer:this.contactRegisterForm.value.representativeLawyer,
          crNumber:this.contactRegisterForm.value.crNumber,cprNumber:this.contactRegisterForm.value.cprNumber,
          email:this.contactRegisterForm.value.email,phoneNumber:this.contactRegisterForm.value.phoneNumber,
          IBAN_Number:this.contactRegisterForm.value.IBAN_Number,nationality:this.contactRegisterForm.value.nationality,
          address:this.contactRegisterForm.value.address,
          customField: this.contactRegisterForm.value.customField,
          isEmail:isEMail,
          isPhone:isPhone
           }
           this._contact.editConatct(obj).subscribe(res => {
             console.log(res)
             swal(res.data, "", "success");
            this.router.navigate(['/lawyerIt/contacts']);
           },error => {
             swal("Error!", error.error.message, "error");
           })
           console.log(obj)
       }
    
      }
     }

 } 
 
}
