import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './views/common/reset-password/reset-password.component';
import { HeadersComponent } from './views/common/headers//headers.component';
import { DashboardComponent } from './views/dashboard/dashboard/dashboard.component';
import { ContactListComponent } from './views/contacts/contact-list/contact-list.component';
import { AddContactComponent } from './views/contacts/add-contact/add-contact.component';
import { EditContactComponent } from './views/contacts/edit-contact/edit-contact.component';
import {AuthgaurdService} from './gaurd/authgaurd.service'
import {LoginService} from './gaurd/login.service'
import {RolegaurdService} from './gaurd/rolegaurd.service'
import { ProfileComponent } from './views/settings/profile/profile.component';
import { FirmProfileComponent } from './views/settings/firm-profile/firm-profile.component';
import { AllCaseListComponent } from './views/cases/all-case-list/all-case-list.component';
import { AddNewCaseComponent } from './views/cases/add-new-case/add-new-case.component';
import { ViewCaseComponent } from './views/cases/view-case/view-case.component';
import { EditCaseComponent } from './views/cases/edit-case/edit-case.component';
import { HelpCenterComponent } from './views/settings/help-center/help-center.component';
import { DocumentListComponent } from './views/documentsUtilities/document-list/document-list.component';
import { UtilitiesComponent } from './views/documentsUtilities/utilities/utilities.component';
import { Dashboard2Component } from './views/dashboard/dashboard2/dashboard2.component';

const routes: Routes = [
  { path: '', canActivate:[LoginService],  redirectTo: 'lawyerIt',pathMatch: 'full', },
  { path: 'lawyerIt', canActivate:[LoginService], component: HeadersComponent },
  { path: 'lawyerIt/resetPassword/:id',canActivate:[LoginService], component: ResetPasswordComponent },
  { path: 'lawyerIt/dashboard',canActivate:[AuthgaurdService], component: Dashboard2Component },
  { path: 'lawyerIt/contacts',canActivate:[AuthgaurdService], component: ContactListComponent },
  { path: 'lawyerIt/addContact',canActivate:[AuthgaurdService], component: AddContactComponent },
  { path: 'lawyerIt/viewContact/:contactId', canActivate:[AuthgaurdService],component: EditContactComponent },
  { path: 'lawyerIt/profile',canActivate:[AuthgaurdService], component: ProfileComponent },
  { path: 'lawyerIt/firmProfile',canActivate:[AuthgaurdService], component: FirmProfileComponent },
  { path: 'lawyerIt/cases',canActivate:[AuthgaurdService], component: AllCaseListComponent },
  { path: 'lawyerIt/addCase',canActivate:[AuthgaurdService], component: AddNewCaseComponent },
  { path: 'lawyerIt/viewCase/:caseId', canActivate:[AuthgaurdService],component: ViewCaseComponent },
  
  { path: 'lawyerIt/editCase/:caseId', canActivate:[AuthgaurdService,RolegaurdService],component: EditCaseComponent },
  { path: 'lawyerIt/help_center',canActivate:[AuthgaurdService], component: HelpCenterComponent },
  { path: 'lawyerIt/documents',canActivate:[AuthgaurdService], component: DocumentListComponent },
  { path: 'lawyerIt/utilities',canActivate:[AuthgaurdService], component: UtilitiesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
