import { Injectable } from '@angular/core';
import {throwError } from 'rxjs';
import { ApiService } from '../config/sharedServices/api.service';
import { IapiBaseObject } from '../config/iModels/IApiBaseObject';
import { API_KEYS } from '../config/apiKeys/api-keys-constants';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: "root",
})
export class CasesService {
  constructor(private _api: ApiService) {}

  globalSearch(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GLOBALSEARCH,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  generatePaymentVoucher(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GENERATEPAYMENTVOUCHER,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  generateInvoice(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GENERATEINVOICE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  generateReceipt(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GENERATERECEIPT,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  removeRelatedCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVERELATEDCASES,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  addRelatedCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDRELATEDCASES,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  searchRelatedCase(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHRELATEDCASES,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  listProcedure(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LISTPROCEDURE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  updateProcedure(data, folder, Type, pName) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEPROCEDURE.replace(":folderName", folder)
        .replace(":type", Type)
        .replace(":procedureName", pName),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  addProcedure(data, folder, Type) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDProcedure.replace(":folderName", folder).replace(
        ":type",
        Type
      ),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  downloadProcedure(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DOWNLOADPROCEDURE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  listCollectionFee(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LISTCOLLECTIONFEE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateCollectionFee(data, folder, Type, ID) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATECOLLECTIONFEE.replace(":folderName", folder)
        .replace(":type", Type)
        .replace(":caseId", ID),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  listAttorneyFee(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LISTATTORNEYFEE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  editAttorneyFee(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITATTORNEYFEE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  editCollectionFee(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITCOLLECTIONFEE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  deleteAttorneyFee(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETEATTORNEYFEE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  deleteCollectionFee(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETECOLLECTIONFEE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  updateAttorneyFee(data, folder, Type, ID) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEATTORNYFEE.replace(":folderName", folder)
        .replace(":type", Type)
        .replace(":caseId", ID),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  listNotes(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.NOTESLSIT,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  viewNotesDetail(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWNOTE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  deleteNotes(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETENOTE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  addNotes(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDNOTE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  editNotes(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITNOTE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  casesList(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CASELIST.replace(":size", pageSize).replace(
        ":pageNo",
        pageIndex
      ),
    };
    return this._api.getApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  casesListForDownload() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CASELIST,
    };
    return this._api.getApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  removeFolderFromDocs(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVEFOLDERBEFOREADDCASE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  editCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITCASE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  addCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDNEWCASE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  filterCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERCASES,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  searchCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHFILTERCASES,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  contactList() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CONTACTST,
    };
    return this._api.getApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  lawyerList() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LAWYST,
    };
    return this._api.getApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  createDir() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CREATEDIR,
    };
    return this._api.getApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  convertCsvForCase(formdata) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.COVERTCSVFORCASE,
      apiData: formdata,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  importcsvCases(formdata) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.IMPORTCSVCASES,
      apiData: formdata,
      multipart: true,
    };

    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  getFileUrl(data, folder, Type) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.getFILEURL
        .replace(":folderName", folder)
        .replace(":type", Type),
      apiData: data,
      multipart: true,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  removeFileFromFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVEFILE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  deleteImage(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DeleteFileUrl,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  viewContactInCase(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWCONTACTINCASE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  viewCaseDetail(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWCASE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  viewCaseDetailForView(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWCASEFORVIEW,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  deleteCase(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETECASE,
      apiData: data,
    };
    return this._api.postApi(apiObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
