import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service'
import {AuthService} from '../../../services/auth.service'
import { SharedService } from '../../../config/sharedServices/shared.service';
import { ImageCroppedEvent,base64ToFile} from 'ngx-image-cropper';
import { tr } from 'date-fns/locale';
import swal from 'sweetalert';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-firm-profile',
  templateUrl: './firm-profile.component.html',
  styleUrls: ['./firm-profile.component.scss']
})
export class FirmProfileComponent implements OnInit {
  length : number;
  totalPages:any =[]
  pageSize : number;
  pageIndex: number;
  usersList:any;
  fileToUpload:any
  firmData:any
  firmImage:any;
  firmName:any = ''
  roleData:any =[]
  firmAddress:any;
  isFirmEdit:boolean;
  isPicEdit:boolean;
  imageChangedEvent: any = '';
  isShow:boolean
  isError:boolean = false
  url = environment.baseUrl
  constructor(private userService:UserService,private authService: AuthService,private _shared: SharedService) { 
    this.pageSize= 5;
    this.pageIndex = 1;
    this.isFirmEdit = false
    this.isPicEdit = false
    this.isShow = false
  }
 ngOnInit() {
   this.loadProfile() 
   this.loadFirmUsers()
  }

 loadProfile(){ 
  this.isFirmEdit = false
  this.authService.getUserDetail().subscribe(res =>{
    console.log(res)
    this.firmData = res.data
    this.firmImage = this.firmData.firm.firmImg
    this.firmName = this.firmData.firm.firmName
    this.firmAddress = this.firmData.firm.firmAddress
    this.userService.userRoleList().subscribe(res =>{
      console.log(res.data.findListData)
      if(this.firmData.role.name == 'Admin'){
         for(let i=0; i<res.data.findListData.length; i++){
           if(res.data.findListData[i].name != 'Admin'){
            this.roleData.push(res.data.findListData[i])
          }
        }
        // this.roleData = res.data.findListData
        console.log(this.roleData)
        this.isPicEdit = true
      }
      else{
        this.roleData =[]
        this.isPicEdit = false
      }
      })
  })
} 
fileChangeEvent(event: any): void {
  this.isShow = true
  this.imageChangedEvent = event;
}
imageCropped(event: ImageCroppedEvent) {
  this.fileToUpload = this.base64ToFile(event.base64,this.imageChangedEvent.target.files[0].name)
  console.log(this.fileToUpload);
  return this.fileToUpload;
}
imageLoaded() {
  // show cropper
}
cropperReady() {
  // cropper ready
}
loadImageFailed(){
 this.isShow = false
 alert("Image is Invalid")
}
base64ToFile(data, filename) {
  const arr = data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

UploadFile() {
  this.isShow = false
    // this.fileToUpload = files.item(0);
    if(this.fileToUpload){
      let ext = this.fileToUpload.name.split('.').pop();
      // if(ext == 'png' ||ext == 'jpg' || ext =='jpeg' ||ext == 'svg'){
        const formData: FormData = new FormData();
        formData.append('file', this.fileToUpload);
        formData.append('firmId', this.firmData.firm._id);
         this.userService.updateFirmDetail(formData).subscribe(
          (res: any) => {
              this.roleData =[]
                this.ngOnInit()
           })
      // }
      // else{
      //   alert("Image is Invalid")
      // }
    }
  }
enableFirmName(){
    if(this.firmData.role.name == 'Admin'){
      this.isFirmEdit = true
    }
  }
  
chnageFirmName(){
  console.log(this.firmName)
  if(this.firmName && this.firmAddress){
     if(this.firmName.trim() != "" && this.firmAddress.trim() != ""){
      this.userService.updateFirmName({firmId:this.firmData.firm._id, firmName:  this.firmName,firmAddress:this.firmAddress}).subscribe(res => {
        console.log(res)
        swal(res.data, "", "success");
        this.isError = false
        this.roleData =[]
        this.ngOnInit()
      })
    }
    else{
      this.isError = true
    }
  }
   else{
    this.isError = true
    }
  }
onChangeRole(userId,roleId){
    console.log(userId,roleId)
    this.userService.changeRole({userId:userId, roleId:roleId}).subscribe(res => {
      swal(res.data, "", "success");
      this.roleData =[]
        this.ngOnInit()
    })
  }

handlePageChange(event) {
    this.pageIndex = event;
      this.loadFirmUsers();       
  }
loadFirmUsers(){
    this.userService.getFirmUserList(this.pageSize,this.pageIndex -1 ).subscribe(res => {
       this.usersList = res.data.findListData
       this.length = res.data.coundData.coundData
      this.totalPages = res.data.coundData.totalPages
    
    })
  }
}
