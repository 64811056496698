import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormArray} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service'
import {CasesService} from '../../../services/cases.service'
import { SharedService } from '../../../config/sharedServices/shared.service';
import swal from 'sweetalert';
import { noWhitespaceValidator, removeSpaces } from 'src/app/config/sharedServices/customeValidation';
declare var $: any;

@Component({
  selector: 'app-add-new-case',
  templateUrl: './add-new-case.component.html',
  styleUrls: ['./add-new-case.component.scss']
})
export class AddNewCaseComponent implements OnInit {
  newCaseForm:any
  clientForm:any;
  counterPartyForm:any
  firmData:any
  isSubmitted: boolean;
  clientList:any
 
  lawyerList:any
  supportingDocs:any =[];
  expertDocs:any =[];
  claimDocs:any =[];
  courtDocs:any =[];
  attornyDocs:any =[];
  otherDocs:any =[];
  judgementDocs:any =[];
  clientNameArray:any = []
  clientIdArray:any = []
  clientIdArrayTochkDuplicate:any = []
  counterIdArrayTochkDuplicate:any = []
  counterNameArray:any = []
  counterIdArray:any = [];
  LAWYERNAME:any;
  LAWYERID:any;
  FOLDERNAME:any;
  CASEREFRENCE:any;
  isClientNameError:boolean = false;
  isCounterNameError:boolean = false;
  isLawyerNameError:boolean = false;
  currentTimeZone:any
  FileName:any
  FileToDelete:any
  indexArray:any =[]
  isContactSame:boolean = false
  imageNameForDelete:any; 
  customForm: FormGroup;
  isCustomFiled: boolean;
  FIELDNAME: any;
  constructor(private authService: AuthService,private fb: FormBuilder,
    private _cases: CasesService,private router: Router,private _shared: SharedService,) { 
        this.isSubmitted = false;
        this.FOLDERNAME  = this._shared.getDataFromStorage('DirName');
      if(this.FOLDERNAME){
        this._cases.removeFolderFromDocs({folderName:this.FOLDERNAME}).subscribe(res => {
          this._shared.deleteDataFromStorage('DirName')
        })
      }
 
   }

  ngOnInit() {
    this.loadClientCouterCreateDir()
    this.buildCaseForm()
    this.addClient()
    this.addCounterParty()
    this.customeFiled()
  }
 loginUserData(data){
    this.currentTimeZone = data.timeZone
    console.log(this.currentTimeZone)
   }

  loadClientCouterCreateDir()
  {
      this._cases.contactList().subscribe(res => {
            this.clientList = res.data.findListData
          
            console.log(this.clientList)
           
      })
      this._cases.lawyerList().subscribe(res => {
            this.lawyerList = res.data.findListData
        })
     this._cases.createDir().subscribe(res => {
            this.FOLDERNAME = res.path
            this.CASEREFRENCE = res.data
            this._shared.setDataInStorage('DirName',this.FOLDERNAME);
         })
   }

  buildCaseForm(){
    this.newCaseForm = this.fb.group({
      caseSubject: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(" +
              /^[a-zA-Z\u0600-\u06FF,-][sa-zA-Z\u0600-\u06FF,-]*$/ +
              ") || ( " +
              /^[0-9]/ +
              ")"
          ),
        ],
      ],
      caseStatus: ["", [Validators.required]],
      caseSubStatus: ["", [Validators.required]],
      supportingDocs: [""],
      clientId: [""],
      clientName: this.fb.array([], Validators.required),
      counterPartyId: [""],
      counterPartyName: this.fb.array([], Validators.required),
      laywer: [""],
      laywerName: ["", [Validators.required]],

      fileNumber: ["", [Validators.required]],
      caseType: ["", [Validators.required]],
      caseNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/),
        ],
      ],
      // ("(" + one.source + ")|(" + two.source + ")") This pattern accepts Arabic,Number,English.
      courtChamber: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(" +
              /^[a-zA-Z\u0600-\u06FF,-][sa-zA-Z\u0600-\u06FF,-]*$/ +
              ") || ( " +
              /^[0-9]/ +
              ")"
          ),
        ],
      ],
      executionNumber: [
        "",
        [Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)],
      ],
      // passportNumber: [
      //   "",
      //   [Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)],
      // ],
      intrestRate: [
        "",
        [Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)],
      ],
      intrestAmount: [
        "",
        [Validators.pattern("^\\d+(\\.\\d+)*$"), Validators.min(0.01)],
      ],
      judgementAmount: [
        "",
        [
          Validators.required,
          Validators.pattern("^\\d+(\\.\\d+)*$"),
          Validators.min(1),
        ],
      ],
      attorneyFee: [
        "",
        [
          Validators.required,
          Validators.pattern("^\\d+(\\.\\d+)*$"),
          Validators.min(1),
        ],
      ],
      attorneyFeeDocs: this.fb.array([]),
      courtFees: ["", [Validators.pattern("^\\d+(\\.\\d+)*$")]],
      courtFeesDocs: this.fb.array([]),
      expertFee: ["", Validators.pattern("^\\d+(\\.\\d+)*$")],
      expertFeeDocs: this.fb.array([]),
      otherFee: ["", [Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)]],
      otherFeeDocs: this.fb.array([]),
      claimAmount: [
        "",
        [Validators.pattern(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/)],
      ],
      claimAmountFeeDocs: this.fb.array([]),
      // ^[0-9]*\.[0-9]{2}$
      totalJudgementAmountDocs: this.fb.array([]),
      customField: this.fb.array([]),
    });
  }
 combineLawyer(contact) {

    return  contact.fullName+'-'+contact.userId 
 }
combine(contact) {
   return contact.companyName ? contact.companyName+'-'+contact.phoneNumber : contact.personName+'-'+contact.phoneNumber
}

  onChangeClint(value){
       let phone = value.split('-')[1]
       let clientData = this.clientList.find(m => m.phoneNumber == phone)
       this.clientNameArray = []
       this.clientIdArray = []
     if(clientData){
          this.isClientNameError = false
      //   if(this.clientIdArrayTochkDuplicate.length != 0){
      //       console.log("A")
      //         let id = this.clientIdArrayTochkDuplicate
      //         for(let i =0; i< this.clientIdArrayTochkDuplicate.length; i++){
      //           console.log("hh",this.clientIdArrayTochkDuplicate.length)
      //               if(this.clientIdArrayTochkDuplicate[i] == clientData._id){
      //                 swal("Error!", "Can not use same client.", "error");
      //                 this.isContactSame = true
      //               }
      //        }
      //        this.clientIdArrayTochkDuplicate.push(clientData._id)
      //  }
      //  if(this.counterIdArrayTochkDuplicate.length != 0){
      //   console.log("AA")
      //       for(let i =0; i< this.counterIdArrayTochkDuplicate.length; i++){
      //               if(this.counterIdArrayTochkDuplicate[i] == clientData._id){
      //                 swal("Error!", "Can not use same Counter Party Name.", "error");
      //               }
      //       }
      //  }
      //  else{
      //   console.log("AAA")
      //   this.clientIdArrayTochkDuplicate.push(clientData._id)
      //  }
       
  
    //  this.clientNameArray.push({name:clientData.personName ? clientData.personName : clientData.companyName})  
    //  var index = this.clientList.findIndex(x => x._id === clientData._id);
    //  if(index > -1){
    //   this.clientList.splice(index,1);

    //  }
 
     }
     else{
       console.log("OOOOOOO")
      this.isClientNameError = true
     }
    //  console.log(this.clientIdArrayTochkDuplicate)
  }

  getClientName(value){
    for(let i =0; i< value.length; i++){
      if(value[i].name){
            let phone = value[i].name.split('-')[1]  
            let clientData = this.clientList.find(m =>  m.phoneNumber == phone)
                if(clientData){
                 
                  this.clientNameArray.push({name:clientData.personName ? clientData.personName : clientData.companyName})
                 
                }
              
       
      }
       
    }
    
    return this.clientNameArray
  }
  getClientId(value){
    for(let i =0; i< value.length; i++){
      if(value[i].name){
        let phone = value[i].name.split('-')[1]  
        let clientData = this.clientList.find(m => m.phoneNumber == phone)
        if(clientData){
          this.clientIdArray.push(clientData._id)
          this.isClientNameError = false
        }
        else{
          this.isClientNameError = true
        }
     }
    }
 
    return this.clientIdArray
  }
   onChangeCounterParty(value){
    let phone = value.split('-')[1]
    let counterData = this.clientList.find(m => m.phoneNumber == phone)
    this.counterNameArray = []
    this.counterIdArray = []
    if(counterData){
      this.isCounterNameError = false
      // if(this.counterIdArrayTochkDuplicate.length != 0){
      //   for(let i =0; i< this.counterIdArrayTochkDuplicate.length; i++){
      //     if(this.counterIdArrayTochkDuplicate[i] == counterData._id){
      //       swal("Error!", "Can not use same Counter Party.", "error");
      //     }
      //    }
      //    this.counterIdArrayTochkDuplicate.push(counterData._id) 
      //  }
      //  if(this.clientIdArrayTochkDuplicate.length != 0){
      //   for(let i =0; i< this.clientIdArrayTochkDuplicate.length; i++){
      //     if(this.clientIdArrayTochkDuplicate[i] == counterData._id){
      //       swal("Error!", "Can not use same Client .", "error");
      //     }
      //    }
      //  }
      //  else{
      //   this.counterIdArrayTochkDuplicate.push(counterData._id)
      //  }
      // this.counterNameArray.push({name:counterData.personName ? counterData.personName : counterData.companyName})
      // this.counterIdArray.push(counterData._id)
      // var index = this.clientList.findIndex(x => x._id === counterData._id);
      // console.log(index)
      // if(index > -1){
      //  this.clientList.splice(index,1);
      // }
    }
    else{
      this.isCounterNameError = true
    }
  
  }
  getCounterName(value){
    for(let i =0; i< value.length; i++){
      if(value[i].name){
        let phone = value[i].name.split('-')[1]  
        let counterData = this.clientList.find(m =>  m.phoneNumber == phone)
        if(counterData){
          this.counterNameArray.push({name:counterData.personName ? counterData.personName : counterData.companyName})
        }
       
      }
       
    }
    
    return this.counterNameArray
  }
  getCounterId(value){
    for(let i =0; i< value.length; i++){
      if(value[i].name){
        let phone = value[i].name.split('-')[1]  
        let counterData = this.clientList.find(m => m.phoneNumber == phone)
        if(counterData){
          this.counterIdArray.push(counterData._id)
          this.isCounterNameError = false
        }
        else{
          this.isCounterNameError = true
        }
     }
    }
 
    return this.counterIdArray
  }

 
 checkDupes(array)
{
  // array.sort(); // use whatever sort you want
  for (var i = 0; i < array.length -1; i++)
  {
    if (array[i+1] == array[i])
    {
      return true;
    }
  }
  return false;
} 
CompareArray(array1,array2)
{
      for(let i = 0; i < array1.length ; i++){
        for(let j = 0; j < array2.length ; j++){
          console.log(array1[i],array2[j])
          if(array1[i]=== array2[j]){
            console.log("A")
            return true
            }
          else{
            console.log("AAAA")
          }
        }
     }
     return false
  // array1.forEach((elem1, index) => {elem1;
  //   array2.forEach((elem2, index) => {elem2;
  //     console.log(elem1,elem2)
  //     if(elem1=== elem2)
  //     {
  //      return true
  //     }
  //     else{
  //      return false
  //      }
  //   });
  // });
} 
submitCase(){
  this.isSubmitted = true
        this.counterNameArray = []
        this.counterIdArray = []
        this.clientNameArray = []
        this.clientIdArray = []
    let obj = {
      caseReference:this.CASEREFRENCE, folderName:this.FOLDERNAME,
      clientName:this.getClientName(this.newCaseForm.value.clientName), clientId:this.getClientId(this.newCaseForm.value.clientName),
      counterPartyName:this.getCounterName(this.newCaseForm.value.counterPartyName), counterPartyId:this.getCounterId(this.newCaseForm.value.counterPartyName),
      laywerName: this.LAWYERNAME, laywer:this.LAWYERID,
      caseSubject:this.newCaseForm.value.caseSubject, caseStatus:this.newCaseForm.value.caseStatus,
      caseSubStatus:this.newCaseForm.value.caseSubStatus, supportingDocs:this.supportingDocs,
      fileNumber:this.newCaseForm.value.fileNumber, 
      caseType:this.newCaseForm.value.caseType, caseNumber:this.newCaseForm.value.caseNumber,
      courtChamber:this.newCaseForm.value.courtChamber, executionNumber:this.newCaseForm.value.executionNumber,
      // passportNumber:this.newCaseForm.value.passportNumber,` 
      intrestRate:this.newCaseForm.value.intrestRate,intrestAmount:this.newCaseForm.value.intrestAmount,
      courtFees:this.newCaseForm.value.courtFees,courtFeesDocs:this.courtDocs,
      attorneyFee:this.newCaseForm.value.attorneyFee,attorneyFeeDocs:this.attornyDocs,
      expertFee:this.newCaseForm.value.expertFee,expertFeeDocs:this.expertDocs,
      otherFee:this.newCaseForm.value.otherFee,otherFeeDocs:this.otherDocs,
      claimAmount:this.newCaseForm.value.claimAmount,claimAmountFeeDocs:this.claimDocs,
      judgementAmount:this.newCaseForm.value.judgementAmount,
      totalJudgementAmountDocs:this.judgementDocs,
      timezone:this.currentTimeZone,
      customField:this.newCaseForm.value.customField,
    }
    // console.log(obj)
    console.log(obj.clientId)
    console.log(obj.counterPartyId)
          
    if(obj.clientId.length != 0){
      var firstArray =  this.checkDupes(obj.clientId) 
    }
    if(obj.counterPartyId.length != 0){
      var secondArray =  this.checkDupes(obj.counterPartyId) 
    }
    console.log(obj.clientId)
    console.log(obj.counterPartyId)
  //  console.log(firstArray,secondArray)
   if(firstArray || secondArray){
    this.isContactSame = true
   }
   else{
    if(this.CompareArray(obj.clientId,obj.counterPartyId)){
      this.isContactSame = true
    }
    else{
      this.isContactSame = false
    }
 


   }
 
   console.log(this.isContactSame)
    console.log(this.newCaseForm.status)
    console.log(this.isClientNameError)
    console.log(this.isCounterNameError)
    if(this.newCaseForm.status == 'VALID' && !this.isLawyerNameError && !this.isCounterNameError
     && !this.isClientNameError){
      if(this.isContactSame){
        swal("Error!", "Contacts can not be same.", "error");
       }
       else{
        this._cases.addCases(obj).subscribe(res => {
          console.log(res)
          swal(res.data, "", "success");
          this._shared.deleteDataFromStorage('DirName');
          this.router.navigate(['/lawyerIt/cases']);
        },error => {
          swal("Error!", error.error.message, "error");
        })
        
       }
   
    }
  
    
  }
  
  onChangeLawyer(value){
    let user = value.split('-')[1]
    let lawyerData = this.lawyerList.find(m => m.userId == user)
    if(lawyerData){
      this.isLawyerNameError = false
      this.LAWYERNAME = lawyerData.fullName
      this.LAWYERID = lawyerData._id
    }
    else{
      this.isLawyerNameError = true
    }
  }
uploadSupportingDocs(files: FileList){
    let supportfileToUpload = files.item(0);
    if(supportfileToUpload){
      let ext = supportfileToUpload.name.split('.').pop();
      this.imageNameForDelete = supportfileToUpload.name;
      console.log("ye delete hoskati hai",this.imageNameForDelete);
      console.log(ext)
      if(ext == 'pdf' || ext == 'docx'  ||ext == 'jpeg'||ext == 'doc'||ext == 'jpg'
      ||ext == 'flv' ||ext == 'png' ||ext == 'odt' ||ext == 'xls'||ext == 'xlsx'||ext == 'ods'
      ||ext == 'txt' || ext == 'ppt' || ext == 'pptx'){
        const formData:FormData = new FormData();
        formData.append('files', supportfileToUpload);
        this._cases.getFileUrl(formData,this.FOLDERNAME,'supportingDocs').subscribe(res => {
          for(let i=0; i< res.data.length;i++){
            if (this.supportingDocs.indexOf(supportfileToUpload.name) !== -1) {
             swal("Error!", "Please choose different file");
            } else {
              this.supportingDocs.push(supportfileToUpload.name);
            }
          
            }
             console.log( this.supportingDocs)
           })
      }
      else{
        alert('not valid extension')
       
      }
     }
 }

// custom field
get customField() : FormArray {
  return this.newCaseForm.get("customField") as FormArray
}
customeFiled(): FormGroup {
  this.customForm = this.fb.group({
    fieldName: [this.FIELDNAME,[Validators.required]],
    fieldType: ['String'],
    fieldValue: [''],
  })
  // this.FIELDNAME = "";
  return this.customForm;
}

addCustomField(){
this.isCustomFiled = true;
let customField = this.fb.group({
  fieldName: [this.FIELDNAME,[Validators.required,noWhitespaceValidator]],
  fieldType: ['String'],
  fieldValue: ['',removeSpaces],
})
if(customField.status == 'VALID'){
  this.customField.push(customField);
  this.isCustomFiled = false;
}

}

removeSupportDocs(value){
  console.log(value);

   console.log("phle",this.supportingDocs);
  const index = this.supportingDocs.indexOf(value);
  if (index > -1) {
    this.supportingDocs.splice(index, 1);
  }
  console.log("bad m",this.supportingDocs);
 
  let data = {
    folderName: this.FOLDERNAME,
    imgName: value
  };
this._cases.deleteImage(data).subscribe(res=>{
  swal(res.data, "", "success");
})


}  
deleteFile(){
  let obj = {
    listOfFile:[this.FileToDelete],
    folderType:'supportingDocs',
    folderName:this.FOLDERNAME
   }
    this._cases.removeFileFromFolder(obj).subscribe(res => {
      const index: number = this.supportingDocs.indexOf(this.FileName);
            if (index !== -1) {
                this.supportingDocs.splice(index, 1);
            } 
            console.log( this.supportingDocs)  
     })
}


  uploadExpertDocs(files: FileList){
    let expertfileToUpload = files.item(0);
    const formData:FormData = new FormData();
    formData.append('files', expertfileToUpload);
    this._cases.getFileUrl(formData,this.FOLDERNAME,'expertFeeDocs').subscribe(res => {
      for(let i=0; i< res.data.length;i++){
        this.expertDocs.push(res.data[i])
       }
       console.log( this.expertDocs)
    })
  }
  removeExpertDocs(value){
    let fileName = value.split("/")[7]
    let obj = {
     listOfFile:[fileName],
     folderType:'expertFeeDocs',
     folderName:this.FOLDERNAME
    }
     this._cases.removeFileFromFolder(obj).subscribe(res => {
    const index: number = this.expertDocs.indexOf(value);
          if (index !== -1) {
              this.expertDocs.splice(index, 1);
          } 
          console.log( this.expertDocs) 
        })      
  }
  uploadClaimAmountDocs(files: FileList){
    let claimfileToUpload = files.item(0);
    const formData:FormData = new FormData();
    formData.append('files', claimfileToUpload);
    this._cases.getFileUrl(formData,this.FOLDERNAME,'claimAmountFeeDocs').subscribe(res => {
      for(let i=0; i< res.data.length;i++){
        this.claimDocs.push(res.data[i])
       }
       console.log( this.claimDocs)
    })
  }
  removeClaimDocs(value){
    let fileName = value.split("/")[7]
    let obj = {
     listOfFile:[fileName],
     folderType:'claimAmountFeeDocs',
     folderName:this.FOLDERNAME
    }
     this._cases.removeFileFromFolder(obj).subscribe(res => {
    const index: number = this.claimDocs.indexOf(value);
          if (index !== -1) {
              this.claimDocs.splice(index, 1);
          } 
          console.log( this.claimDocs) 
        })       
  }
  uploadCourtFeesDocs(files: FileList){
    let courtfileToUpload = files.item(0);
    const formData:FormData = new FormData();
    formData.append('files', courtfileToUpload);
    this._cases.getFileUrl(formData,this.FOLDERNAME,'courtFeesDocs').subscribe(res => {
      for(let i=0; i< res.data.length;i++){
        this.courtDocs.push(res.data[i])
       }
       console.log( this.courtDocs)
    })
  }
  removeCourtFeesDocs(value){
    let fileName = value.split("/")[7]
    let obj = {
     listOfFile:[fileName],
     folderType:'courtFeesDocs',
     folderName:this.FOLDERNAME
    }
     this._cases.removeFileFromFolder(obj).subscribe(res => {
    const index: number = this.courtDocs.indexOf(value);
          if (index !== -1) {
              this.courtDocs.splice(index, 1);
          } 
          console.log( this.courtDocs) 
        })      
  }
  uploadAttoryFeesDocs(files: FileList){
    let attornyfileToUpload = files.item(0);
    const formData:FormData = new FormData();
    formData.append('files', attornyfileToUpload);
    this._cases.getFileUrl(formData,this.FOLDERNAME,'attorneyFeeDocs').subscribe(res => {
      for(let i=0; i< res.data.length;i++){
        this.attornyDocs.push(res.data[i])
       }
       console.log( this.attornyDocs)
    })
  }
  removeAttornyDocs(value){
    let fileName = value.split("/")[7]
    let obj = {
     listOfFile:[fileName],
     folderType:'attorneyFeeDocs',
     folderName:this.FOLDERNAME
    }
     this._cases.removeFileFromFolder(obj).subscribe(res => {
    const index: number = this.attornyDocs.indexOf(value);
          if (index !== -1) {
              this.attornyDocs.splice(index, 1);
          } 
          console.log( this.attornyDocs) 
        })      
  }
  uploadOtherFeesDocs(files: FileList){
    let otherfileToUpload = files.item(0);
    const formData:FormData = new FormData();
    formData.append('files', otherfileToUpload);
    this._cases.getFileUrl(formData,this.FOLDERNAME,'otherFeeDocs').subscribe(res => {
      for(let i=0; i< res.data.length;i++){
        this.otherDocs.push(res.data[i])
       }
       console.log( this.otherDocs)
    })
  }
  removeOtherDocs(value){
    let fileName = value.split("/")[7]
    let obj = {
     listOfFile:[fileName],
     folderType:'otherFeeDocs',
     folderName:this.FOLDERNAME
    }
     this._cases.removeFileFromFolder(obj).subscribe(res => {
    const index: number = this.otherDocs.indexOf(value);
          if (index !== -1) {
              this.otherDocs.splice(index, 1);
          } 
          console.log( this.otherDocs) 
        })    
  }
  uploadJudgementDocs(files: FileList){
    let judgementfileToUpload = files.item(0);
    const formData:FormData = new FormData();
    formData.append('files', judgementfileToUpload);
    this._cases.getFileUrl(formData,this.FOLDERNAME,'totalJudgementAmountDocs').subscribe(res => {
      for(let i=0; i< res.data.length;i++){
        this.judgementDocs.push(res.data[i])
       }
       console.log( this.judgementDocs)
    })
  }
 removeJudgementDocs(value){
    let fileName = value.split("/")[7]
    let obj = {
     listOfFile:[fileName],
     folderType:'totalJudgementAmountDocs',
     folderName:this.FOLDERNAME
    }
     this._cases.removeFileFromFolder(obj).subscribe(res => {
    const index: number = this.judgementDocs.indexOf(value);
          if (index !== -1) {
              this.judgementDocs.splice(index, 1);
          } 
          console.log( this.judgementDocs) 
        })     
  }

get clientName() : FormArray {
    return this.newCaseForm.get("clientName") as FormArray
}
newClient(): FormGroup {
  return this.clientForm = this.fb.group({
    name: ['',[Validators.required]],
  })
}
addClient(){
  this.clientName.push(this.newClient());
}

removeClient(i:number){
  console.log(i)
  this.clientName.removeAt(i);
}


get counterPartyName() : FormArray {
  return this.newCaseForm.get("counterPartyName") as FormArray
}
newCounter(): FormGroup {
return this.counterPartyForm = this.fb.group({
  name: ['',[Validators.required]],
})
}
addCounterParty(){
this.counterPartyName.push(this.newCounter());
}

removeCounterParty(i:number){
this.counterPartyName.removeAt(i);
}


}
