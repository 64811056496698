import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import {AuthService} from '../services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class RolegaurdService {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
     return this.isAuthenticated(state.url);
  }

  isAuthenticated(url: string): boolean {
     console.log(this.authService.isRoleUser())
    if (this.authService.isRoleUser() == 'Tier-2') {
      this.router.navigate(['/lawyerIt/dashboard']);
      return false;
       
    }  
    return true;
  }
}
