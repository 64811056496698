export const API_KEYS = {
  LOGIN: "public/firmUserController/login",
  FORGOTPASSWORD: "public/firmUserController/forgotPassword",
  RESETPASSWORD: "public/firmUserController/updatePassword",
  REGISTERFIRMREQUEST: "public/firmUserController/firmRegisterRequest",
  PLANLIST: "public/firmUserController/listAllPlan",
  HELPCENTER: "private/firmUserController/addSuggestions",
  LISTCONTENT: "private/contentManagment/listContent",
  UTILITYTABLE: "private/contentManagment/getUtilites",
  LISTTERMABOUT: "public/contentManagment/listTermaAboutUs",

  // event management
  ADDEVENT: "private/eventsManagment/addEvent",
  EVENTLIST: "private/eventsManagment/listEvent",
  EVENTDETAIL: "private/eventsManagment/getEventDetail",
  EDITEVENT: "private/eventsManagment/editEvent",
  DELETEEVENT: "private/eventsManagment/deleteEvent",
  FILTEREVENT: "private/eventsManagment/filterEvent",
  DOWNLOADEVENTPDF: "private/eventsManagment/downloadEventPdf",
  notificationList: "private/eventsManagment/notificationList",
  unReadNotification: "private/eventsManagment/unReadNotification",
  notificationStatusChange: "private/eventsManagment/notificationStatusChange",
  // fee management
  CASETYPESTATICS: "private/feeManagment/caseTypeStatistics",
  CASESTATICS: "private/feeManagment/caseStatistics",
  FILTERCASESTATICS: "private/feeManagment/filterCaseStatistics",
  COLLECTIONFEESTATICS: "private/feeManagment/listStatistics",
  FILTERCOLLECTIONSTATICS: "private/feeManagment/filterStatistics",
  FILTERCOLLECTIONSTATICSPayment:
    "private/feeManagment/filterStatistics?paymentMode=:paymentMode",
  ATTORNEYFEESTATICS: "private/feeManagment/listAttorneyStatistics",
  FILTERATTORNEYSTATICS: "private/feeManagment/filterAttorneyStatistics",
  FILTERATTORNEYSTATICSPayment:
    "private/feeManagment/filterAttorneyStatistics?paymentMode=:paymentMode",
  HIGHESTAMOUNT:
    "private/feeManagment/getHighestAmount?size=:size&&pageNo=:pageNo",
  GENERATERECEIPT: "private/feeManagment/generateReceipt",
  GENERATEINVOICE: "private/feeManagment/generateInvoice",
  GENERATEPAYMENTVOUCHER: "private/feeManagment/generatePaymentVoucher",
  LISTPROCEDURE: "private/caseManagment/listProcedure",

  ADDProcedure:
    "private/caseManagment/addProcedure?folderName=:folderName&&type=:type",
  UPDATEPROCEDURE:
    "private/caseManagment/updateProcedure?folderName=:folderName&&type=:type&&pName=:procedureName",
  LISTCOLLECTIONFEE: "private/feeManagment/listCollectionFee",
  UPDATECOLLECTIONFEE:
    "private/feeManagment/updateCollectionFee?folderName=:folderName&&type=:type&&caseId=:caseId",
  LISTATTORNEYFEE: "private/feeManagment/listAttorneyFee",
  UPDATEATTORNYFEE:
    "private/feeManagment/updateAttorneyFee?folderName=:folderName&&type=:type&&caseId=:caseId",
  DELETECOLLECTIONFEE: "private/feeManagment/deleteCollectionFee",
  DELETEATTORNEYFEE: "private/feeManagment/deleteAttorneyFee",
  EDITCOLLECTIONFEE: "private/feeManagment/editCollectionFee",
  EDITATTORNEYFEE: "private/feeManagment/editAttorneyFee",
  // notes management
  NOTESLSIT: "private/notesManagment/listNotes",
  VIEWNOTE: "private/notesManagment/viewNotesDetail",
  DELETENOTE: "private/notesManagment/deleteNotes",
  ADDNOTE: "private/notesManagment/addNotes",
  EDITNOTE: "private/notesManagment/editNotes",

  // case management
  GLOBALSEARCH: "private/caseManagment/globalSearch",
  REMOVERELATEDCASES: "private/caseManagment/removeRelatedCases",
  ADDRELATEDCASES: "private/caseManagment/addRelatedCases",
  SEARCHRELATEDCASES: "private/caseManagment/searchRelatedCase",
  COVERTCSVFORCASE: "private/caseManagment/convertcsvtojson",
  CASELIST: "private/caseManagment/casesList?size=:size&&pageNo=:pageNo",
  FILTERCASES: "private/caseManagment/filterCases",
  SEARCHFILTERCASES: "private/caseManagment/searchCases",
  getFILEURL:
    "private/caseManagment/uploadFileApi?folderName=:folderName&&type=:type",
  DeleteFileUrl: "private/caseManagment/deleteFileApi",

  // SEARCHName: 'private/caseManagment/searchDetail',
  CONTACTST: "private/caseManagment/contactList",
  LAWYST: "private/caseManagment/listLaywer",
  CREATEDIR: "private/caseManagment/createDir",
  REMOVEFILE: "private/caseManagment/removeFileFromFolder",
  ADDNEWCASE: "private/caseManagment/addCases",
  IMPORTCSVCASES: "private/caseManagment/importCases",
  VIEWCASE: "private/caseManagment/viewCaseDetail",
  VIEWCASEFORVIEW: "private/caseManagment/viewCaseDetail",
  VIEWCONTACTINCASE: "private/caseManagment/viewContactInCase",
  DELETECASE: "private/caseManagment/deleteCase",
  EDITCASE: "private/caseManagment/editCases",

  // document management

  REMOVEFOLDERBEFOREADDCASE: "private/documentManagment/removeFolderFromDocs",
  UPLOADFILEINDIR:
    "private/documentManagment/uploadFileInDir?rootfolderName=:rootfolderName&&subFolderName=:subFolderName",
  CREATESUBFOLDER: "private/documentManagment/createDirManual",
  REMOVESUBFOLDER: "private/documentManagment/removeSubFolder",
  REMOVEFILEFROMDOC: "private/documentManagment/removeFileFromDocs",
  REMOVEFOLDER: "private/caseManagment/removeFolder",
  SEARCHFILEINDOCUMENT: "private/caseManagment/searchFile",
  SEARCHFILEINDOCUMENTSTUTUSFILTER:
    "private/caseManagment/searchFile?caseStatus=:caseStatus",
  SEARCHFILEINDOCUMENTTYPEFILTER:
    "private/caseManagment/searchFile?caseType=:caseType",
  SEARCHFILEINDOCUMENTDOUBLEFILTE:
    "private/caseManagment/searchFile?caseStatus=:caseStatus&&caseType=:caseType",
  ALLDOCS: "private/documentManagment/AlldocList",
  DOWNLOADCASEFOLDER: "private/documentManagment/downloadCaseFolder",
  DELETECASEFOLDER: "private/documentManagment/deleteDownloadedFiles",
  UPDATEFILETIME: "private/documentManagment/updateFileTime",
  DOWNLOADPROCEDURE: "private/caseManagment/downloadProcedureFolder",
  // firm controller  http://192.168.1.152:3001/api/users/private/caseManagment/listProcedure

  LOGOUT: "private/firmUserController/logout",
  UPDATELOGINSTATUS: "private/firmUserController/updateLoginStatus",
  CHANGEPASSWORD: "private/firmUserController/resetPassword",
  getDetail: "private/firmUserController/getUserDetail",
  UPDATEUSERPROFILE: "private/firmUserController/updateProfilePicture",
  UPDATEFIRMDETAIL: "private/firmUserController/updateFirmDetail",
  UPDATEFIRMNAME: "private/firmUserController/updateFirmName",

  ROLELIST: "private/firmUserController/userRoleList",
  CHANGEROLE: "private/firmUserController/changeRole",
  FIRMUSERLIST:
    "private/firmUserController/getFirmUserList?size=:size&&pageNo=:pageNo",
  UPDATETIMEZONE: "private/firmUserController/updateTimezone",
  TIMEZONELIST: "public/firmUserController/getTimezonelist",

  // Contact management
  REMOVEFOLDERFOCONTACTS: "private/contactManagment/removeFolderFromDocs",
  REMOVEFILEFromCONTACTS: "private/contactManagment/removeFileFromFolder",
  CREATECONTACTFOLDER: "private/contactManagment/createContactFolder",
  UPLOADDOCSGETURL:
    "private/contactManagment/uploadContactDocsBrowse?folderName=:folderName",
  UPLOADCONTACTDOCS:
    "private/contactManagment/uploadContactDocs?folderName=:folderName",
  GETFEETAB: "private/contactManagment/getFeetab",
  RELATEDCASESINCONTACT: "private/contactManagment/getRelatedCases",
  CONTACTLIST:
    "private/contactManagment/listAllContact?size=:size&&pageNo=:pageNo",
  REGISTERCONTACT: "private/contactManagment/registerContact",
  VIEWCONTACT: "private/contactManagment/viewContactDetail",
  EDITCONTACT: "private/contactManagment/editConatct",
  DELETECONTACT: "private/contactManagment/deleteContact",
  SEARCHCONTACT:
    "private/contactManagment/searchContact?size=:size&&pageNo=:pageNo",
  DOWNLOADTEMP: "private/contactManagment/importExportContact",
  COVERTCSV: "private/contactManagment/convertcsvtojson",
  IMPORTCSV: "private/contactManagment/importcsv",
};
