import { Component, OnInit } from '@angular/core';
import {CasesService} from '../../../services/cases.service'
@Component({
  selector: 'app-dashboard2',
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.scss']
})
export class Dashboard2Component implements OnInit {
  isShowDashboard:boolean
  showTypeResponce:string = ''
  caseList:any
  contactData:any
  subFolder:any=-1
  File:any=-1
  isSubFolder:boolean;
  isFile:boolean
  Documents:any
  isShowStatics:boolean = false
  isShowChartStatics:boolean = false

  constructor() { 
    this.isShowDashboard = true
    this.isSubFolder = false
    this.isFile = false
  }

  ngOnInit() {
  }
  loginUserData(data){
    // console.log(data.role.name)
    if(data.role.name == 'Admin' || data.role.name == 'Sub-Admin'){
      this.isShowStatics = true
      this.isShowChartStatics = true
    }
    if(data.role.name == 'Tier-3'){
        this.isShowChartStatics = true
    }
  }

  showdashboard(data){
    this.isShowDashboard = data
  }
  showType(data){
    this.showTypeResponce = data
  }
  caseSearchResult(data){
    console.log(this.showTypeResponce)
    if(this.showTypeResponce == ''){
      console.log('A')
      this.caseList = data.caseData.findListData
      this.contactData = data.contactData.findListData
      this.Documents = data.documentData
      console.log(  this.Documents)
    }
    else{
      console.log('B')
      this.caseList = data
      this.contactData = data
      this.Documents = data
    }
   
  }
  showSubFolder(value){
    this.subFolder = value
    this.isSubFolder =  !this.isSubFolder
  }
  showFile(value){
    this.isFile =  !this.isFile
    this.File = value
  }
}
