import { Injectable } from '@angular/core';
import {throwError } from 'rxjs';
import { ApiService } from '../config/sharedServices/api.service';
import { IapiBaseObject } from '../config/iModels/IApiBaseObject';
import { API_KEYS } from '../config/apiKeys/api-keys-constants';
import { map, catchError } from 'rxjs/operators';
import { data } from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private _api: ApiService) { }

  removeFolderFromDocs(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVEFOLDERFOCONTACTS,
      apiData:data,
      };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  removeFileFromFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REMOVEFILEFromCONTACTS,
      apiData:data,
      };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  uploadContactDocsBrowse(formdata,folder) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPLOADDOCSGETURL.replace(":folderName", folder),
      apiData : formdata,
      multipart: true
    };
 
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  createContactFolder(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CREATECONTACTFOLDER,
      apiData: data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  getFeetab(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GETFEETAB,
      apiData: data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  getRelatedCases(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.RELATEDCASESINCONTACT,
      apiData: data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  listAllContacts(pageSize,pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CONTACTLIST.replace(":size", pageSize).replace(":pageNo", pageIndex),
    };
   return this._api.getApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  } 
  searchContact(pageSize,pageIndex,data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHCONTACT.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  } 
  searchContactfordashboard(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHCONTACT,
      apiData: data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  } 
  // downloadtemplate() {
  //   const apiObj: IapiBaseObject = {
  //     tokenRequiredFlag: true,
  //     endpoint: API_KEYS.DOWNLOADTEMP,
  //     apiData : {type:"Download"}
  //   };
  //  return this._api.postApi(apiObj).
  //     pipe(map((response: any) => {
  //          return response;
  //     }),
  //       catchError((error) => {
  //          return throwError(error);
  //       })
  //     );
  // }

  convetCsvtoJson(formdata) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.COVERTCSV,
      apiData : formdata,
      multipart: true
    };
    console.log(apiObj)
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  importcsv(formdata) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.IMPORTCSV,
      apiData : formdata,
      multipart: true
    };
    console.log(apiObj)
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }

  registerContact(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REGISTERCONTACT,
      apiData : data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  deleteContact(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETECONTACT,
      apiData : data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  viewContactDetail(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWCONTACT,
      apiData : data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  editConatct(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITCONTACT,
      apiData : data
    };
   return this._api.postApi(apiObj).
      pipe(map((response: any) => {
           return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
}
