import { Component, OnInit,Input} from '@angular/core';
import {CasesService} from '../../../services/cases.service'
@Component({
  selector: 'app-related-case',
  templateUrl: './related-case.component.html',
  styleUrls: ['./related-case.component.scss']
})
export class RelatedCaseComponent implements OnInit {
  @Input() id :any
  @Input() userRole :any
  isReferenceList:boolean;
  searchKey:any = ''
  relatedCases:any = []
  searchcaseresult:any = []
  isAddOrDelete:boolean = false
  REFINEFIELD:any =''
  Refinaries:any = [{key:'Case Reference',value:'caseReference'},{key:'File Number',value:'fileNumber'},
  {key:'Client name',value:'clientName.name'},{key:'Counter Party Name',value:'counterPartyName.name'},
  {key:'Case Number',value:'caseNumber'},{key:'Lawyer Name',value:'laywerName'},]
  constructor(private _cases: CasesService) { 
    this.isReferenceList = true
  }

  ngOnInit() {
    this.loadRelatedCases()
    if(this.userRole == 'Admin' || this.userRole == 'Sub-Admin'){
      this.isAddOrDelete = true
    }
    
  }
  viewCase(id){
    window.location.href = "/lawyerIt/viewCase/"+id
   }
  loadRelatedCases(){
    console.log(this.id)
    this._cases.viewCaseDetail({caseId:this.id}).subscribe(res => {
      this.relatedCases = res.data.relatedCase
      console.log( this.relatedCases)
     })
  }
  removeRelatedCase(relatyedid){
     this._cases.removeRelatedCases({caseId:this.id,relatedCase:relatyedid}).subscribe(res => {
      this.loadRelatedCases()
           console.log(res)
     })
  }
  searchCase(event){
    console.log(event.target.value)
    this.searchKey = event.target.value
    if(event.target.value){
      this.isReferenceList = false
      this._cases.searchCases({searchKey:event.target.value,searchField : this.REFINEFIELD}).subscribe(res => {
        this.searchcaseresult = res.data.findListData
        console.log(this.searchcaseresult)
        var index = res.data.findListData.findIndex(x => x._id === this.id);
        console.log(index)
        if(index > -1){
         this.searchcaseresult.splice(index,1);
        }
        if(this.relatedCases.length != 0){
           for(let i=0; i< this.relatedCases.length; i++){
              var newIndex = this.searchcaseresult.findIndex(x => x._id === this.relatedCases[i]._id); 
              console.log(newIndex)
              if(newIndex > -1){
 
                this.searchcaseresult.splice(newIndex,1);
               } 
            }   
        }
       console.log(  this.searchcaseresult )
      })
    }
    else{
      this.isReferenceList = true
    }
 
  }
onChangeRefinary(value){
  if(value == '' && this.searchKey == ''){
    this.isReferenceList = true
  }
    this.REFINEFIELD = value
  }
addRelatedCase(id){
  console.log(id  , this.id)
    this._cases.addRelatedCases({caseId:this.id,case_id: id}).subscribe(res => {
      console.log(res)
      this.searchKey = ''
      this.loadRelatedCases()
      this.isReferenceList = true
    })
  } 

}
