import { Component, OnInit,} from '@angular/core';
import {DocumentUtilitiesService} from '../../../services/document-utilities.service'
import {CasesService} from '../../../services/cases.service'
import {ContactsService} from '../../../services/contacts.service'
import moment from 'moment-timezone';
import swal from 'sweetalert';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"],
})
export class DocumentListComponent implements OnInit {
  newSubFolder: any;
  SubFolderNameForAddFile: any;
  SubFolderNameForDeleteFile: any;
  SubFolderNameForDelete: any;
  fileNameToDelete: any;
  DirectoryName: any;
  CaseID: any;
  subFolderId: any;
  fileId: any;
  fileToUpload: any;
  Documents: any = [];
  subFolder: any = -1;
  File: any = -1;
  ContactFile: any = -1;
  isSubFolder: boolean;
  isFile: boolean;
  isContactFile: boolean;
  searchKey: any;
  searchType: string;
  isDeleteDocs: boolean = false;
  isUploadDocs: boolean = true;
  isDateSearch: boolean = false;
  caseStatus: any = "";
  caseType: any = "";
  Documentscontact: any = [];
  contactFolderName: any;
  contactID: any;
  searchKeyContact: any = "";
  currentTimeZone: any;
  isFolderNameError: boolean;
  oldZipName='';
  Types: any = [
    "family",
    "military",
    "commercial",
    "civil",
    "criminal",
    "labor",
    "administrative",
  ];
  Status: any = ["active", "closed", "settled", "suspended"];
  DocsType: any = [
    { key: "Supporting Docs", value: "SupportingDocs" },
    { key: "Expert Fees Docs", value: "ExpertFees" },
    { key: "ClaimAmount Docs", value: "ClaimAmount" },
    { key: "Court Fees Docs", value: "CourtFees" },
    { key: "Attorney Fees Docs", value: "AttorneyFees" },
    { key: "Other Fees Docs", value: "OtherFees" },
    { key: "TotalJudgmentAmount Docs", value: "TotalJudgmentAmount" },
  ];
  constructor(
    private document: DocumentUtilitiesService,
    private _cases: CasesService,
    private _contact: ContactsService
  ) {
    this.isSubFolder = false;
    this.isFile = false;
    this.isContactFile = false;
    this.isFolderNameError = false;
  }

  ngOnInit() {
    this.loadAllDocuments();
    this.loadContactFolder();
  }

  loginUserData(data) {
    this.currentTimeZone = data.timeZone;
    console.log(data.role.name);
    if (data.role.name == "Admin" || data.role.name == "Sub-Admin") {
      this.isDeleteDocs = true;
    }
    if (data.role.name == "Tier-2") {
      this.isUploadDocs = false;
    }
  }
  loadContactFolder() {
    this._cases.contactList().subscribe((res) => {
      console.log(res);
      if (res.data) {
        this.Documentscontact = res.data.findListData;
      }
    });
  }
  searchContact() {
    if (this.searchKeyContact) {
      this._contact
        .searchContactfordashboard({ searchKey: this.searchKeyContact })
        .subscribe((res) => {
          this.Documentscontact = res.data.findListData;
        });
    } else {
      this.loadContactFolder();
    }
  }
  showContactFile(value) {
    this.isContactFile = !this.isContactFile;
    this.ContactFile = value;
  }
  selectFileForContact(folderName, id) {
    console.log(folderName, id);
    this.contactFolderName = folderName;
    this.contactID = id;
    document.getElementById("fileInputContact").click();
  }
  fileSelectedForContact(files) {
    // this.fileToUpload = files.item(0)
    const formData: FormData = new FormData();
    formData.append("contactId", this.contactID);
    if (files.length != 0) {
      for (let i = 0; i < files.length; i++) {
        let ext = files[i].name.split(".").pop();
        if (
          ext == "pdf" ||
          ext == "docx" ||
          ext == "jpeg" ||
          ext == "doc" ||
          ext == "jpg" ||
          ext == "flv" ||
          ext == "png" ||
          ext == "odt" ||
          ext == "xls" ||
          ext == "xlsx" ||
          ext == "ods" ||
          ext == "txt" ||
          ext == "ppt" ||
          ext == "pptx"
        ) {
          formData.append("files", files[i]);
        } else {
          swal("Error!", "not valid extension", "error");
        }

        if (files.length - 1 == i) {
          this.document
            .uploadContactDocs(formData, this.contactFolderName)
            .subscribe(
              (res) => {
                console.log(res);
                swal("File Uploaded Successfully", "", "success");
                this.loadContactFolder();
              },
              (error) => {
                swal("Error!", error.error.message, "error");
                // alert(error.error.message)
              }
            );
        }
      }
    }
  }
  convertTZ(date, timezone = "") {
    if (timezone == this.currentTimeZone) {
      return date;
    } else {
      var timeZ = moment.tz(date, this.currentTimeZone);
      return timeZ.format("L");
    }
  }
  loadAllDocuments() {
    this.document.AlldocList().subscribe((res) => {
      // console.log(res)
      this.Documents = res.data.findListData;
      // if(this.Documents.length == 0){
      //   alert('No Documents Found')
      //  }
      console.log(this.Documents);
    });
  }
  showSubFolder(value) {
    this.subFolder = value;
    this.isSubFolder = !this.isSubFolder;
  }
  showFile(value) {
    this.isFile = !this.isFile;
    this.File = value;
  }

  getCaseFolderName(name, id) {
    $("#modal-view-event").modal({ backdrop: "static", keyboard: false });
    this.DirectoryName = name;
    this.CaseID = id;
    console.log(this.DirectoryName, this.CaseID);
  }
  getForDeleteSubFolder(name, id, subfolder) {
    this.DirectoryName = name;
    this.subFolderId = id;
    this.SubFolderNameForDelete = subfolder;
  }
  getForDeleteFile(name, caseID, Fileid, subfolder, value) {
    this.DirectoryName = name;
    this.CaseID = caseID;
    this.fileId = Fileid;
    this.SubFolderNameForDeleteFile = subfolder;
    this.fileNameToDelete = value;
    console.log(
      this.DirectoryName,
      this.CaseID,
      this.fileId,
      this.SubFolderNameForDeleteFile
    );
  }
  deleteFile() {
    let obj = {
      listOfFile: [this.fileNameToDelete],
      subFolderName: this.SubFolderNameForDeleteFile,
      folderName: this.DirectoryName,
      fileId: this.fileId,
      caseId: this.CaseID,
    };
    console.log(obj);
    this.document.removeFileFromDocs(obj).subscribe(
      (res) => {
        console.log(res);
        this.loadAllDocuments();
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }
  deleteDirectory() {
    console.log(this.DirectoryName);
    if (this.SubFolderNameForDelete) {
      this.document
        .removeSubFolder({
          folderName: this.DirectoryName,
          subFolderName: this.SubFolderNameForDelete,
          folderId: this.subFolderId,
        })
        .subscribe(
          (res) => {
            console.log(res);
            this.loadAllDocuments();
            this.SubFolderNameForDelete = "";
          },
          (error) => {
            alert(error.error.message);
          }
        );
    } else {
      this.document
        .removeFolder({ folderName: this.DirectoryName, caseId: this.CaseID })
        .subscribe(
          (res) => {
            console.log(res);
            this.loadAllDocuments();
          },
          (error) => {
            alert(error.error.message);
          }
        );
    }
  }
  createSubFolder() {
    console.log(this.newSubFolder);
    if (this.newSubFolder) {
      let obj = {
        rootFolderName: this.DirectoryName,
        newFolderName: this.newSubFolder,
        caseId: this.CaseID,
      };
      if (this.newSubFolder.trim() != "") {
        this.document.createDirManual(obj).subscribe(
          (res) => {
            console.log(res);
            this.newSubFolder = "";
            this.isFolderNameError = false;
            $("#modal-view-event").modal("hide");
            this.loadAllDocuments();
          },
          (error) => {
            this.newSubFolder = "";
            this.isFolderNameError = false;
            alert(error.error.message);
          }
        );
      } else {
        this.isFolderNameError = true;
      }
    } else {
      this.isFolderNameError = true;
    }
  }
  cancelToCreateFolder() {
    this.newSubFolder = "";
    this.isFolderNameError = false;
  }
  selectFile(dirName, folderName, id) {
    console.log(dirName, folderName, id);
    this.DirectoryName = dirName;
    this.CaseID = id;
    this.SubFolderNameForAddFile = folderName;
    document.getElementById("fileInput").click();
  }
  fileSelected(files) {
    const formData: FormData = new FormData();
    formData.append("caseId", this.CaseID);
    if (files.length != 0) {
      for (let i = 0; i < files.length; i++) {
        let ext = files[i].name.split(".").pop();
        if (
          ext == "pdf" ||
          ext == "docx" ||
          ext == "jpeg" ||
          ext == "doc" ||
          ext == "jpg" ||
          ext == "flv" ||
          ext == "png" ||
          ext == "odt" ||
          ext == "xls" ||
          ext == "xlsx" ||
          ext == "ods" ||
          ext == "txt" ||
          ext == "ppt" ||
          ext == "pptx"
        ) {
          formData.append("file", files[i]);
        } else {
          swal("Error!", "not valid extension", "error");
          break;
        }

        if (files.length - 1 == i) {
          this.document
            .uploadFileInDir(
              formData,
              this.DirectoryName,
              this.SubFolderNameForAddFile
            )
            .subscribe(
              (res) => {
                console.log(res);
                swal("File Uploaded Successfully", "", "success");
                this.loadAllDocuments();
              },
              (error) => {
                swal("Error!", error.error.message, "error");
                //  alert(error.error.message)
              }
            );
        }
      }
    }

    // if(this.fileToUpload){
    //   let ext = this.fileToUpload.name.split('.').pop();
    //   if(ext == 'pdf' || ext == 'docx'  ||ext == 'jpeg'||ext == 'doc'||ext == 'jpg'
    //   ||ext == 'flv' ||ext == 'png' ||ext == 'odt' ||ext == 'xls'||ext == 'xlsx'||ext == 'ods'
    //   ||ext == 'txt' || ext == 'ppt' || ext == 'pptx'){
    //     const formData: FormData = new FormData
    //     formData.append("file",this.fileToUpload)
    //     formData.append("caseId",this.CaseID)
    //     this.document.uploadFileInDir(formData,this.DirectoryName,this.SubFolderNameForAddFile).subscribe(res => {
    //       console.log(res)
    //       swal("File Uploaded Successfully", "", "success");
    //       this.loadAllDocuments()
    //      },error => {
    //       swal("Error!", error.error.message, "error");
    //       //  alert(error.error.message)
    //      })
    //   }
    //   else{
    //     swal("Error!",'not valid extension', "error");
    //   }

    // }
  }

  // downloadFolder(folderName){
  //   let obj = {caseFolder:folderName,type:"rootDir"}
  //   this.document.downloadCaseFolder(obj).subscribe(res => {
  //     // console.log(res.data.data);
  //       var a: any = document.createElement("a");
  //       document.body.appendChild(a);
  //       // console.log(res.success);
  //       a.style = "display: none";
  //       let BufferData = res.data.data;
  //       var blob = new Blob([BufferData], { type: 'application/zip' });
  //       var url = window.URL.createObjectURL(blob);
  //       a.href = url;
  //       a.download = "procedure.zip";
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       // console.log(a);
  //       // console.log(url);
  //        a.remove();
  //       this.loadAllDocuments();
  //      },error =>{
  //        console.log(error);
  //      })
  // }

  downloadFolder(folderName) {
    let obj = { caseFolder: folderName, type: "rootDir" };
    this.document.downloadCaseFolder(obj).subscribe(
      (res) => {
        this.loadAllDocuments();
        console.log(res);
        console.log("hahaa", res.data);
        window.open((environment.baseUrl) + "/" + res.data);
        let zip = res.data;
        let zipname = zip.split("/");
        console.log(zipname[2])
        this.oldZipName = zipname[2];
        
        // this.deleteFolder();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // deleteFolder() {
  //   let obj = { zipName: this.oldZipName };
  //   this.document.deleteCaseFolder(obj).subscribe(
  //     (res) => {
  //       this.loadAllDocuments();
  //       console.log(res);
  //       console.log("deleted", res.data);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }
  downloadSubFolder(folderName, subfolder) {
    let obj = {
      caseFolder: folderName,
      type: "subDir",
      caseSubFolder: subfolder,
    };
    this.document.downloadCaseFolder(obj).subscribe(
      (res) => {
        this.loadAllDocuments();
        console.log(res);
        console.log("hahaa", res.data);
        window.open((environment.baseUrl) + "/" + res.data);
      },
      (error) => {
        swal("Error!", "Can't Download Empty Folder");
      }
    );
  }
  downloadFile(data, id, subId, k) {
    console.log(id, 'id');
   
    
    if (id) {
      this.document
        .updateFileTime({ fileId: id, subFolderId: subId })
        .subscribe(
          (res) => {
            this.loadAllDocuments();
            // console.log(res.fileData[0]["caseSubFolder"].files.url);
            

            console.log(this.isContactFile, 'contact file');
            console.log(this.isFile, 'file');
            

            if(this.isContactFile) {
              let documentUrl = this.Documentscontact[0].contactDocs[0].url[k].substring(18);
              console.log(documentUrl, 'document url');
              window.open(environment.baseUrl + '/public/' + documentUrl);  
              console.log('contact');
              
              }
              else if(this.isFile) { 
                let document = res.fileData[0]["caseSubFolder"].files.url;
                window.open((environment.baseUrl)  + document);
              }
            
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      window.open(data);
    }
  }

  filterDoc() {
    if (this.caseStatus) {
      this.document
        .searchFileStatus(
          { fileName: "", timezone: this.currentTimeZone },
          this.caseStatus
        )
        .subscribe((res) => {
          this.Documents = res.data;
          console.log(res);
        });
    } else if (this.caseType) {
      this.document
        .searchFileType(
          { fileName: "", timezone: this.currentTimeZone },
          this.caseType
        )
        .subscribe((res) => {
          this.Documents = res.data;
          console.log(res);
        });
    } else {
      this.document
        .searchFileDoubleFilter(
          { fileName: "", timezone: this.currentTimeZone },
          this.caseStatus,
          this.caseType
        )
        .subscribe((res) => {
          this.Documents = res.data;
          console.log(res);
        });
    }
  }

  searchDocument() {
    console.log("ll", this.searchKey);
    if (this.searchKey) {
      if (
        this.searchType == "fileCreated" &&
        this.caseType == "" &&
        this.caseStatus == ""
      ) {
        this.document
          .searchFile({
            fileCreated: this.searchKey,
            timezone: this.currentTimeZone,
          })
          .subscribe((res) => {
            this.Documents = res.data;
            console.log(res);
          });
      }
      if (
        this.searchType == "fileCreated" &&
        this.caseType != "" &&
        this.caseStatus == ""
      ) {
        this.document
          .searchFileType(
            { fileCreated: this.searchKey, timezone: this.currentTimeZone },
            this.caseType
          )
          .subscribe((res) => {
            this.Documents = res.data;
            console.log(res);
          });
      }
      if (
        this.searchType == "fileCreated" &&
        this.caseType == "" &&
        this.caseStatus != ""
      ) {
        this.document
          .searchFileStatus(
            { fileCreated: this.searchKey, timezone: this.currentTimeZone },
            this.caseStatus
          )
          .subscribe((res) => {
            this.Documents = res.data;
            console.log(res);
          });
      }
      if (
        this.searchType == "fileCreated" &&
        this.caseType != "" &&
        this.caseStatus != ""
      ) {
        this.document
          .searchFileDoubleFilter(
            { fileCreated: this.searchKey, timezone: this.currentTimeZone },
            this.caseStatus,
            this.caseType
          )
          .subscribe((res) => {
            this.Documents = res.data;
            console.log(res);
          });
      } else {
        if (
          this.searchType == "fileName" &&
          this.caseType == "" &&
          this.caseStatus == ""
        ) {
          this.document
            .searchFile({ fileName: this.searchKey })
            .subscribe((res) => {
              this.Documents = res.data;
              console.log(res);
            });
        }
        if (
          this.searchType == "fileName" &&
          this.caseType != "" &&
          this.caseStatus == ""
        ) {
          this.document
            .searchFileType({ fileName: this.searchKey }, this.caseType)
            .subscribe((res) => {
              this.Documents = res.data;
              console.log(res);
            });
        }
        if (
          this.searchType == "fileName" &&
          this.caseType == "" &&
          this.caseStatus != ""
        ) {
          this.document
            .searchFileStatus({ fileName: this.searchKey }, this.caseStatus)
            .subscribe((res) => {
              this.Documents = res.data;
              console.log(res);
            });
        }
        if (
          this.searchType == "fileName" &&
          this.caseType != "" &&
          this.caseStatus != ""
        ) {
          this.document
            .searchFileDoubleFilter(
              { fileName: this.searchKey },
              this.caseStatus,
              this.caseType
            )
            .subscribe((res) => {
              this.Documents = res.data;
              console.log(res);
            });
        }
      }
    } else {
      if (this.caseType || this.caseStatus) {
        this.filterDoc();
      } else {
        if (this.searchType) {
        } else {
          window.location.reload();
        }
      }
    }

    if (!this.searchType && (this.caseType || this.caseStatus)) {
      this.filterDoc();
    }
  }
  onChangeDocs(value) {
    this.searchType = value;
    this.searchKey = "";
    this.loadAllDocuments();
    if (this.searchType == "fileName") {
      this.isDateSearch = false;
    } else {
      this.isDateSearch = true;
    }

    console.log(value);
  }
  onChangeType(value) {
    this.caseType = value;
    this.searchDocument();
    console.log(value);
  }
  onChangeStatus(value) {
    this.caseStatus = value;
    this.searchDocument();
    console.log(value);
  }
}
