import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import {UserService} from '../../../services/user.service'
import { FormBuilder, Validators } from '@angular/forms';
import {PasswordValidation} from '../../../config/sharedServices/customeValidation';
import { SharedService } from '../../../config/sharedServices/shared.service';
import {AuthService} from '../../../services/auth.service'
import moment from 'moment-timezone';
import { ImageCroppedEvent,base64ToFile} from 'ngx-image-cropper';
import swal from 'sweetalert';
import { environment } from 'src/environments/environment';
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  passwordForm: any;
  isSubmitted: boolean;
  userdata: any;
  fileToUpload: any;
  tzNames: any;
  selectedTz: string;
  todaysDataTime = "";
  private num = new Date();
  public a: moment.Moment;
  private dateFormat = "L";
  private timeFormat = "LTS";
  public aDateFormat: string;
  public aTimeFormat: string;
  imageChangedEvent: any = "";
  isShow: boolean;
  url = environment.baseUrl;
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private _shared: SharedService,
    private authService: AuthService
  ) {
    this.isSubmitted = false;

    this.isShow = false;
  }

  ngOnInit() {
    // this.tzNames = moment.tz.names();

    this.loadProfile();
    this.timeZoneList();
    this.passwordFormBuilder();
    // setInterval(() => { this.getTIMEDATE()},1)
  }
  loadProfile() {
    this.authService.getUserDetail().subscribe((res) => {
      this.userdata = res.data;
      this.selectedTz = res.data.timeZone;
      // console.log( this.selectedTz)
    });
  }
  timeZoneList() {
    this.userService.getTimezonelist().subscribe((res) => {
      this.tzNames = res.data;
      // console.log( this.tzNames)
    });
  }
  // getTIMEDATE(){
  //   this.a = moment(this.num).tz( this.selectedTz );
  //   this.aDateFormat = this.a.format(this.dateFormat);
  //   this.aTimeFormat = this.a.format(this.timeFormat);
  // }

  public timeZoneChanged(timeZone: string): void {
    // console.log(timeZone);
    this.selectedTz = timeZone;
    // console.log( this.selectedTz)
    this.userService
      .updateTimezone({ userId: this.userdata._id, timezone: this.selectedTz })
      .subscribe((res) => {
        swal(res.data, "", "success");
        // console.log(res)
      });
  }

  convertTz(tz) {
    let tze = tz[2];
    tze = tze.split("/");
    if (tze) {
      if (tze.length > 1) {
        let valueT = "";
        tze.map((tz, i) => {
          if (i > 0) {
            valueT = valueT ? valueT + "/" + tz : tz;
          }
        });
        return `${valueT}`;
      } else {
        return `${tze[0]}`;
      }
    } else {
      return `${tz}`;
    }
    // return tze ? tze.length > 1 ? tze[1] : tze[0] : tz
  }

  passwordFormBuilder(): void {
    this.passwordForm = this.fb.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
            ),
          ],
        ],
        confpassword: ["", [Validators.required]],
      },
      { validators: PasswordValidation.MatchPasswordforChange }
    );
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    this.isShow = true;
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.fileToUpload = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name
    );
    console.log(this.fileToUpload);
    return this.fileToUpload;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.isShow = false;
    swal("Image is Invalid", "", "success");
    //  alert("Image is Invalid")
  }
  base64ToFile(data, filename) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  UploadFile() {
    this.isShow = false;
    // this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
    if (this.fileToUpload) {
      let ext = this.fileToUpload.name.split(".").pop();
      // if(ext == 'png' ||ext == 'jpg' || ext =='jpeg' ||ext == 'svg'){
      const formData: FormData = new FormData();
      formData.append("file", this.fileToUpload);
      formData.append("userId", this.userdata._id);
      this.userService.updateProfilePicture(formData).subscribe((res: any) => {
        console.log(res);
        swal(res.data, "", "success");
        this.ngOnInit();
        this.authService.setUser();
      });
      // }
      // else{
      //   alert("Image is Invalid")
      // }
    }
  }

  submitForm() {
    this.isSubmitted = true;
    console.log(this.passwordForm.value);
    let obj = {
      id: this.userdata._id,
      oldPassword: this.passwordForm.value.oldPassword,
      newPassword: this.passwordForm.value.newPassword,
    };
    if (this.passwordForm.status === "VALID") {
      this.userService.changePassword(obj).subscribe(
        (res) => {
          console.log(res);
          this.isSubmitted = false;
          this.passwordForm.reset();
          swal(res.data, "", "success");
        },
        (error) => {
          this.isSubmitted = false;
          swal("Error!", error.error.message, "error");
        }
      );
    }
  }
}
