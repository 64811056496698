import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import * as Chart from 'chart.js'
@Component({
  selector: 'app-collection-pie-chart',
  templateUrl: './collection-pie-chart.component.html',
  styleUrls: ['./collection-pie-chart.component.scss']
})
export class CollectionPieChartComponent implements OnInit {
  paidCollectionFee:any
  pendingCollectionFee:any
  totalCollectionFee:any
  paidPercentage:any 
  pendingPercentage:any
  CollectioncaseStutus:any = ''
  CollectioncaseSubStatus:any =''
  transactionType:any = ''
  constructor(private _dashboad:DashboardService) { }

  ngOnInit() {
    this.loadStatics()
  }
  loadStatics(){
    this._dashboad.listStatistics().subscribe(res => {
      console.log(res)
     if(res.receivedAmount.length != 0){
        this.totalCollectionFee = res.receivedAmount[0].totalCollectionFee
        this.paidCollectionFee = res.receivedAmount[0].totalReceivedFee
        this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
        this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
        this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
      }
      else{
        this.totalCollectionFee = 0
        this.paidCollectionFee = 0
        this.pendingCollectionFee = 0
        this.paidPercentage = 0
        this.pendingPercentage = 0
 
      }
  
     
    })
  }
  filterStatesbyTransactionType(value){
    this.transactionType = value
    if(this.CollectioncaseStutus && !this.CollectioncaseSubStatus && !this.transactionType){
      this._dashboad.filterStatistics({caseStatus:this.CollectioncaseStutus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })

    }
  
    if(this.CollectioncaseSubStatus && !this.CollectioncaseStutus && !this.transactionType){
      this._dashboad.filterStatistics({caseSubStatus:this.CollectioncaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(!this.CollectioncaseSubStatus && !this.CollectioncaseStutus && this.transactionType){
      console.log("jkljjm")
      this._dashboad.filterStatisticsPayment({},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          console.log(this.paidPercentage,this.pendingPercentage,this.paidCollectionFee,this.pendingCollectionFee)
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(this.CollectioncaseStutus && this.CollectioncaseSubStatus && !this.transactionType){
      this._dashboad.filterStatistics({caseStatus:this.CollectioncaseStutus,caseSubStatus:this.CollectioncaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.CollectioncaseStutus && this.CollectioncaseSubStatus && this.transactionType){

      this._dashboad.filterStatisticsPayment({caseSubStatus:this.CollectioncaseSubStatus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.CollectioncaseStutus && !this.CollectioncaseSubStatus && this.transactionType){
      this._dashboad.filterStatisticsPayment({caseStatus:this.CollectioncaseStutus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.CollectioncaseStutus && this.CollectioncaseSubStatus && this.transactionType){
      this._dashboad.filterStatisticsPayment({caseSubStatus:this.CollectioncaseSubStatus, caseStatus:this.CollectioncaseStutus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.CollectioncaseStutus && !this.CollectioncaseSubStatus && !this.transactionType){
      this.loadStatics()
      this.ngAfterViewInit()
    }
  }
  filterStatesbyStatus(value){
    this.CollectioncaseStutus = value
    if(this.CollectioncaseStutus && !this.CollectioncaseSubStatus && !this.transactionType){
      this._dashboad.filterStatistics({caseStatus:this.CollectioncaseStutus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })

    }
  
    if(this.CollectioncaseSubStatus && !this.CollectioncaseStutus && !this.transactionType){
      this._dashboad.filterStatistics({caseSubStatus:this.CollectioncaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(!this.CollectioncaseSubStatus && !this.CollectioncaseStutus && this.transactionType){
      console.log("jkj")
      this._dashboad.filterStatisticsPayment({},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(this.CollectioncaseStutus && this.CollectioncaseSubStatus && !this.transactionType){
      this._dashboad.filterStatistics({caseStatus:this.CollectioncaseStutus,caseSubStatus:this.CollectioncaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.CollectioncaseStutus && this.CollectioncaseSubStatus && this.transactionType){

      this._dashboad.filterStatisticsPayment({caseSubStatus:this.CollectioncaseSubStatus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.CollectioncaseStutus && !this.CollectioncaseSubStatus && this.transactionType){
      this._dashboad.filterStatisticsPayment({caseStatus:this.CollectioncaseStutus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.CollectioncaseStutus && this.CollectioncaseSubStatus && this.transactionType){
      this._dashboad.filterStatisticsPayment({caseSubStatus:this.CollectioncaseSubStatus, caseStatus:this.CollectioncaseStutus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.CollectioncaseStutus && !this.CollectioncaseSubStatus && !this.transactionType){
      this.loadStatics()
      this.ngAfterViewInit()
    }
   
  }

  filterStatesbySubStatus(value){
    this.CollectioncaseSubStatus = value
    if(this.CollectioncaseStutus && !this.CollectioncaseSubStatus && !this.transactionType){
      this._dashboad.filterStatistics({caseStatus:this.CollectioncaseStutus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })

    }
  
    if(this.CollectioncaseSubStatus && !this.CollectioncaseStutus && !this.transactionType){
      this._dashboad.filterStatistics({caseSubStatus:this.CollectioncaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(!this.CollectioncaseSubStatus && !this.CollectioncaseStutus && this.transactionType){
      this._dashboad.filterStatisticsPayment({},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
     
    }
    if(this.CollectioncaseStutus && this.CollectioncaseSubStatus && !this.transactionType){
      this._dashboad.filterStatistics({caseStatus:this.CollectioncaseStutus,caseSubStatus:this.CollectioncaseSubStatus}).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.CollectioncaseStutus && this.CollectioncaseSubStatus && this.transactionType){

      this._dashboad.filterStatisticsPayment({caseSubStatus:this.CollectioncaseSubStatus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.CollectioncaseStutus && !this.CollectioncaseSubStatus && this.transactionType){
      this._dashboad.filterStatisticsPayment({caseStatus:this.CollectioncaseStutus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(this.CollectioncaseStutus && this.CollectioncaseSubStatus && this.transactionType){
      this._dashboad.filterStatisticsPayment({caseSubStatus:this.CollectioncaseSubStatus, caseStatus:this.CollectioncaseStutus},this.transactionType).subscribe(res => {
        console.log(res)
        if(res.data.length != 0){
          this.totalCollectionFee = res.data[0].totalCollectionFee
          this.paidCollectionFee = res.data[0].totalReceivedFee
          this.pendingCollectionFee = this.totalCollectionFee - this.paidCollectionFee
          this.paidPercentage = ((this.paidCollectionFee / this.totalCollectionFee)*100).toFixed()
          this.pendingPercentage = ((this.pendingCollectionFee / this.totalCollectionFee)*100).toFixed()
          
        }
        else{
          this.totalCollectionFee = 0
          this.paidCollectionFee = 0
          this.pendingCollectionFee = 0
          this.paidPercentage = 0
          this.pendingPercentage = 0
        }
        this.ngAfterViewInit()
      })
   
    }
    if(!this.CollectioncaseStutus && !this.CollectioncaseSubStatus && !this.transactionType){
      this.loadStatics()
      this.ngAfterViewInit()
    }   
  }

  ngAfterViewInit(){
    setTimeout(() => {
      Chart.pluginService.register({
        beforeDraw: function(chart) {
            if (chart.config.options.elements.center) {
                // Get ctx from string
                var ctx = chart.chart.ctx;
    
                // Get options from the center object in options
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || "Arial";
                var txt = centerConfig.text;
                var color = centerConfig.color || "#000";
                var maxFontSize = centerConfig.maxFontSize || 75;
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;
    
                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                var stringWidth = ctx.measureText(txt).width;
                var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
    
                // Find out how much the font can grow in width.
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = chart.innerRadius * 2;
    
                // Pick a new font size so it will not be larger than the height of label.
                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                var minFontSize = centerConfig.minFontSize;
                var lineHeight = centerConfig.lineHeight || 25;
                var wrapText = false;
    
                if (minFontSize === undefined) {
                    minFontSize = 20;
                }
    
                if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                }
    
                // Set font settings to draw it correctly.
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                ctx.font = fontSizeToUse + "px " + fontStyle;
                ctx.fillStyle = color;
    
                if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                }
    
                var words = txt.split(" ");
                var line = "";
                var lines = [];
    
                // Break words up into multiple lines if necessary
                for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + " ";
                    var metrics = ctx.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                        lines.push(line);
                        line = words[n] + " ";
                    } else {
                        line = testLine;
                    }
                }
    
                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;
    
                for (var n = 0; n < lines.length; n++) {
                    ctx.fillText(lines[n], centerX, centerY);
                    centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
            }
        },
    });
    var ctx = document.getElementById("collectionDonutchart");
    
     var myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
            labels: [
                "Total Collection",
                "Pending Collection",
                "Paid Collection",
            ],
            datasets: [{
                label: "Total Collection",
                data: [100,this.pendingPercentage,this.paidPercentage],
                backgroundColor: ["#55D8FE", "#FF8373", "#FFDA83"],
                borderColor: ["#55D8FE", "#FF8373", "#FFDA83"],
                borderWidth: 1,
            }, ],
        },
        options: {
            cutoutPercentage: 60,
            elements: {
                center: {
                    text: this.totalCollectionFee + " Total",
                    color: "#4D4F5C",
                    fontStyle: "Roboto-Light",
                    FontSize: 19,
                    sidePadding: 50,
                },
            },
            rotation: 10.5,
            responsive: true,
            legend: {
                position: "right",
                labels: {
                    fontColor: "#43425D",
                    usePointStyle: true,
                    fontSize: 16,
                    padding: 25,
                },
            },
        },
    });
    },2000)
  }
}
