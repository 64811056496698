import {Injectable} from '@angular/core';
import { Observable, pipe, Subject, throwError } from 'rxjs';
import { ApiService } from '../config/sharedServices/api.service';
import { IapiBaseObject } from '../config/iModels/IApiBaseObject';
import { API_KEYS } from '../config/apiKeys/api-keys-constants';
import { SharedService } from '../config/sharedServices/shared.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { data } from 'jquery';
const MINUTES_UNITL_AUTO_LOGOUT = 25
const CHECK_INTERVAL = 60000 
const STORE_KEY =  'lastAction';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
  currentUser:any
  public $userSource = new BehaviorSubject<any>(null);


  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
constructor(private _api: ApiService, private _shared: SharedService,
      private router: Router,private http: HttpClient) {
      this.check();
      this.initListener();
      this.initInterval();
      localStorage.setItem(STORE_KEY,Date.now().toString());
   
    }
initInterval() {
      setInterval(() => {
        // console.log("check interval")
        this.check();
      }, CHECK_INTERVAL);
  }
initListener() {
      // console.log('Action')
      document.body.addEventListener('click', () => this.reset());
      // document.body.addEventListener('mouseover',()=> this.reset());
      // document.body.addEventListener('mouseout',() => this.reset());
      document.body.addEventListener('keydown',() => this.reset());
      document.body.addEventListener('keyup',() => this.reset());
      document.body.addEventListener('keypress',() => this.reset());
    }
    reset() {
      this.setLastAction(Date.now());
    }

check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout)  {
         this.signOut()
      }
  }

getIP(): Observable<any> {
    return this.http.get('https://api.ipify.org',{responseType: 'text'}).
    pipe(map((response:any) => {
      return response
   }),
     catchError((error) => {
       return throwError(error);
     })
   );
}
login(data): Observable <any> {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.LOGIN,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response:any) => {
         this._shared.setDataInStorage('SYSTEMID', response.currentSystemId);
         this._shared.setDataInStorage('lawyerIt', response.user);
         this._shared.setDataInStorage('token', 'Bearer ' + response.token );
        
                  //  this.setUser();
           return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
getUserDetail(){
    const systemId: any = this._shared.getDataFromStorage('SYSTEMID');
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.getDetail,
      apiData :{"currentSystemId":this._shared.getDataFromStorage('SYSTEMID')}
   };
    return this._api.postApi(apiObj).
      pipe(map((response:any) => {
          return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
   }
  
setUser(): void {
    const systemId: any = this._shared.getDataFromStorage('SYSTEMID');
      this.getUserDetail().subscribe(res =>{
         this._shared.setDataInStorage('lawyerIt', res.data);
           this.$userSource.next(res.data);
      },error =>{
          this.signOut();
      }) 
 }

getUser(): Observable<any> {
    return this.$userSource.asObservable();
  }

isRoleUser():any {
    const theUser: any = this._shared.getDataFromStorage('lawyerIt');
    return theUser.role.name
    }
isLoggedIn(): boolean {
    try {
      const theUser: any = this._shared.getDataFromStorage('lawyerIt');
      if (theUser || theUser != null) {
        this.currentUser = theUser;
      } else {
        this.currentUser = false;
      }
    } catch (e) {
      return false;
    }
  
    return !!this.currentUser;
  }
 
signOut(): void {
    if (this._shared.getDataFromStorage('lawyerIt')) {
      localStorage.setItem("isToggled",null)
      this._shared.setDataInStorage('lawyerIt', null);
      this._shared.setDataInStorage('token', null);
      this._shared.setDataInStorage('SYSTEMID', null);
      this._shared.setDataInStorage('lastAction', null);
      this._shared.deleteDataFromStorage('ContactDirName')
      this._shared.deleteDataFromStorage('DirName')
      this.$userSource.next(null);
      this.router.navigate(['']);
      location.reload();
    } else {
      this.router.navigate(['']);
    }
  }
}
