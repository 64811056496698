import { Injectable } from '@angular/core';
import {throwError,Observable,interval} from 'rxjs';
import { ApiService } from '../config/sharedServices/api.service';
import { IapiBaseObject } from '../config/iModels/IApiBaseObject';
import { API_KEYS } from '../config/apiKeys/api-keys-constants';
import { map, catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _api: ApiService) { }

  getFirmUserList(pageSize,pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FIRMUSERLIST.replace(":size", pageSize).replace(":pageNo", pageIndex),
    
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  logout() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LOGOUT,
       };
    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  getTimezonelist() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.TIMEZONELIST,
       };
    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  listAllPlan() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.PLANLIST,
       };
    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  updateTimezone(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATETIMEZONE,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  updateLoginStatus(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATELOGINSTATUS,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  helpCenter(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.HELPCENTER,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
 registerUser(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.REGISTERFIRMREQUEST,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  forgotPassword(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.FORGOTPASSWORD,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  resetPassword(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: false,
      endpoint: API_KEYS.RESETPASSWORD,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  userRoleList() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ROLELIST,
    
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  changeRole(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CHANGEROLE,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }

  updateFirmDetail(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEFIRMDETAIL,
      apiData: data,
      multipart:true
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  updateFirmName(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEFIRMNAME,
      apiData: data,
    
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
 updateProfilePicture(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEUSERPROFILE,
      apiData: data,
      multipart:true
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
  changePassword(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CHANGEPASSWORD,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
           return throwError(error);
        })
      );
  }
}
