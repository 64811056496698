import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
@Component({
  selector: 'app-highest-amount',
  templateUrl: './highest-amount.component.html',
  styleUrls: ['./highest-amount.component.scss']
})
export class HighestAmountComponent implements OnInit {
  highestAmountList:any =[]
  pageSize : number;
  pageIndex: number;
  length : number;
  constructor(private _dashboad:DashboardService) {
    this.pageSize= 4;
    this.pageIndex = 1;
   }

  ngOnInit() {
    this.loadHighestAmountAPi()
  }

  loadHighestAmountAPi(){
    this._dashboad.getHighestAmount(this.pageSize,this.pageIndex -1 ).subscribe(res => {
      this.highestAmountList = res.data.findListData
      // console.log(this.highestAmountList)
      this.length = res.data.coundData.coundData
     
  })
  }
  handlePageChangeForHighest(event) {
    console.log(event)
   this.pageIndex = event;
   this.loadHighestAmountAPi();       
}
getSNo(z) {
  return z + 1 + (this.pageSize * (this.pageIndex -1))
 }
}
